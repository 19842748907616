import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';

const getNestedValue = (obj, path) => {
	if (obj[path]) return obj[path];
	return path.split('.').slice(1).reduce((current, a) => current[a], obj[path.split('.')[0]])
}
const assignNestedValue = (obj, path, val) => { 
	const keys = path.split('.');
	const lastKey = keys.pop();
	const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
	lastObj[lastKey] = val;
};

export default {
	data: () => ({
		itemsNames: [],
	}),

	watch: {
		langsInputsFields(newVal) {
			const inputs = newVal.split('|');
			this.itemsNames.forEach((name, index) => {
				let value = getNestedValue(this, name);
				if (typeof value === 'object' && value) value = JSON.parse(inputs[index]);
				this.draft[name][this.lang] = value;
			});
		}
	},

	computed: {
		langsInputsFields() {
			let str = '';
			this.itemsNames.forEach((name, index) => {
				let value = getNestedValue(this, name) || '';
				if (typeof value === 'object' && value) value = JSON.stringify(value);
				
				str += value + (index !== this.itemsNames.length - 1 ? '|' : '');
			});
			return str;
		}
	},

	methods: {
		updateFieldsFromDraft() {
			this.itemsNames.forEach(name => {
				assignNestedValue(this, name, this.draft[name][this.lang])
			});
		},

		resetDraft() {
			this.draftNames.forEach(item => {
				if (item) {
					let name, value;
					if (typeof item === 'string') {
						name = item;
						value = null;
					} else if (typeof item === 'object') {
						name = item.name;
						value = item.value;
					}
					this.$set(this.draft, name, {}); // this.draft[name] = {};
					
					if (multiLangInputs) {
						langs.map(c => c.key).forEach(key => {
							this.$set(this.draft[name], key, value); // this.draft[name][key] = value;
						})
					} else {
						this.$set(this.draft[name], defaultLang, value); // this.draft[name][defaultLang] = value;
					}
				}
			});
		}
	},

	created() {
		if (!Array.isArray(this.draftNames)) throw new Error("by using `Draft builder` you must have  a 'draftNames' in your data and it must be an array from strings or objects each object has `name` and `value` keys");

		if (!this.draft) throw new Error("Define 'draft' in your data with empty object value");
		if (!this.lang) throw new Error("Define 'lang' in your data with 'defaultLang' value and bind it with 'v-lang-tabs'");
		
		this.resetDraft();

		this.itemsNames = this.draftNames.map(c => typeof c === 'object' ? c.name : c);
	}
}