<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="branches"
			:ids="deleteItemId"
			@on-delete="fetchBranches"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Branches.the-branch')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="branches"
			:ids="restoreItemId"
			@on-restore="fetchBranches"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Branches.the-branch')}}
		</bee-restore-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							color="darkGreen white--text"
							class="rounded-small"
							@click="dialog = true"
							:to="{ name: 'branch' }"
							:disabled="useOneBranch && Boolean(branches.length)"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('Branches.add-new-branch')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredBranches"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.branchesHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="@/assets/blurred.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- country code -->
			<template v-slot:item.countryCode="{ item }">
				{{item.countryCode | isAvailable | ellipsis}}
			</template>

			<!-- money code -->
			<template v-slot:item.moneyCode="{ item }">
				{{item.moneyCode | isAvailable | ellipsis}}
			</template>

			<!-- open hours -->
			<template v-slot:item.openHours="{ item }">
				{{item.openHours | isAvailable | ellipsis}}
			</template>

			<!-- is main -->
			<template v-slot:item.isMain="{ item }">
				<v-icon v-if="item.isMain">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<template v-if="!item.deletedAt">
						<tooltip :text="$t('tooltip.edit')">
							<v-btn
								color="blue darken-4"
								dark
								small
								class="ms-1 px-1 flex-shrink-1"
								min-width="20px"
								:to="{ name: 'branch', params: { slug: item.slug, id: item.id } }"
							><v-icon>mdi-pencil</v-icon></v-btn>
						</tooltip>
						
						<tooltip :text="$t('tooltip.delete')">
							<v-btn
								color="red darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="deleteItemId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</tooltip>
					</template>
					<tooltip v-else :text="$t('tooltip.restore')">
						<v-btn
							color="orange darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="restoreItemId = item.id; restoreDialog = true"
						>
							<v-icon>mdi-restore</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { contacts, branchInfo, useOneBranch, useUploadBranchImage } from '@/configs/global.config';
import { mapState } from 'vuex'
export default {
	name: 'Branches',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		
		restoreDialog: false,
		restoreItemId: null,

		// configs
		useOneBranch,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
	}),

	computed: {
		...mapState({
			branches: state => state.branches.branches
		}),

		allHeaders() {
			return [
				{
					text: this.$t('headers.image'),
					value: 'image',
					align: 'center',
					sortable: false,
					hide: !useUploadBranchImage
				},
				{
					text: this.$t('headers.name'),
					value: 'name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true
				},
				{
					text: this.$t('headers.country-code'),
					value: 'countryCode',
					align: 'center',
					hide: !branchInfo.countryCode
				},
				{
					text: this.$t('headers.money-code'),
					value: 'moneyCode',
					align: 'center',
					hide: !branchInfo.moneyCode
				},
				{
					text: this.$t('headers.opening-hours'),
					value: 'openHours',
					align: 'center',
					hide: !branchInfo.openingHours
				},
				{
					text: this.$t('headers.main-branch'),
					value: 'isMain',
					align: 'center'
				},
				{
					text: this.$t('headers.email'),
					value: 'email',
					align: 'center',
					hide: !contacts.email,
					notInDefaultView: true
				},
				{
					text: this.$t('headers.address'),
					value: 'address',
					align: 'center',
					hide: !branchInfo.address,
					notInDefaultView: true
				},
				{
					text: this.$t('headers.phone'),
					value: 'phone',
					align: 'center',
					hide: !contacts.phone,
					notInDefaultView: true
				},
				{
					text: this.$t('headers.mobile'),
					value: 'mobile',
					align: 'center',
					hide: !contacts.mobile,
					notInDefaultView: true
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					sortable: false,
					noRemove: true,
					fixed: true
				},
			]
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.branchesHeaders.length) {
				headers = this.$eStorage.l.branchesHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredBranches() {
			return this.filterByName(this.isFiltered, this.branches, this.search);
		}
	},

	methods: {
		fetchBranches() {
			this.loading = true;
			this.$store.dispatch('branches/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},
	},

	created() {
		this.fetchBranches();
	}
}
</script>

<style>

</style>