import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		orders: []
	},

	actions: {
		fetchAll({ commit }, { email, phone, orderId }) {
			return axios.get('/orders', { params: { email, phone, orderId } })
				.then(({ data }) => {
					const storeDate = data.filter(c => orderId ? c.id === orderId : true);
					commit(types.STORE_ORDERS, storeDate)
					return storeDate;
				})
		},
		
		fetchById({ commit }, { id }) {
			return axios.get(`/orders/${id}`).then(({ data }) => data)
		},

		changeState({ commit }, { id, status }) {
			return axios.post(`/orders/${id}/change-status`, { status, _method: 'PUT' });
		}
	},

	mutations: {
		[types.STORE_ORDERS](state, orders) {
			state.orders = orders;
		}
	},

	getters: {
		getOrderById: state => id => state.orders.find(c => c.id === id)
	}
}