import moment from 'moment';
import { fixNumber } from './functions';

export function divideStaticsByDateStep(arr, step, keys = { totalProp: 'total', dateProp: 'date' }) {
	const totalProp = keys.totalProp || 'total';
	const dateProp = keys.dateProp || 'date';
	const statistics = [];
	/*
	const { res: statistics } = arr.reduce(({ res, sum }, item) => {
		sum += item[totalProp];
		if (+moment(item[dateProp]).format('DD') % step === 0) {
			res.push({ date: item[dateProp], total: sum / step });
			sum = 0;
		}
		return { res, sum };
	}, { res: [], sum: 0 });
	*/
	arr.forEach(item => {
		if (+moment(item[dateProp]).format('DD') % step === 0) {
			statistics.push({ date: item[dateProp], total: item[totalProp] });
		}
	});
	return statistics.map(c => [c.date, fixNumber(c.total, 2)]);
}

export function getCartAbandonmentRate(arr, step, keys = { completeProp: 'completeCart', openedProp: 'openedCart', dateProp: 'date' }) {
	const completeProp = keys.completeProp || 'completeCart';
	const openedProp = keys.openedProp || 'openedCart';
	const dateProp = keys.dateProp || 'date';
	let totalAbandoned = 0;
	let totalCarts = 0;
	const statistics = []
	arr.forEach(item => {
		totalCarts += item[completeProp] + item[openedProp];
		totalAbandoned += item[openedProp];
		if (+moment(item[dateProp]).format('DD') % step === 0) {
			statistics.push({ date: item[dateProp], total: totalAbandoned / totalCarts });
			totalCarts = 0;
			totalAbandoned = 0;
		}
	});
	return statistics.map(c => [c.date, fixNumber(c.total * 100, 2)]);
}