import { systemUsers } from '@/configs/translates.config';

export default {
	'add-new-system-user': `add new ${systemUsers.en.singular}`,
	'the-system-user': `the ${systemUsers.en.singular}`,
	'system-user': systemUsers.en.singular,
	users: systemUsers.en.plural,
	'are-you-sure-you-want-to-$-this-user': `Are you sure you want to {state} this ${systemUsers.en.singular}?`,
	'user-state-is-changed-successfully': `${systemUsers.en.singular} state is changed successfully`,
	'not-matched': 'not matched',
	'new-value-replace-old': 'a new value will replace the old one',
}