export default {
	'add-new-video': '',
	video: '',
	'the-video': '',
	
	'attachment-type': {
		title: '',
		'uploading-video': '',
		link: ''
	}
}