/* NOTE: type value should be 2 or up */
import wizardConfigs from './wizard-configs';
const { customServices: customizedServices } = wizardConfigs;

// custom services
const customServices = [];
customizedServices.forEach((item, index) => {
	customServices.push({
		type: item.type || index + 2,
		typeName: item.typeName || 'custom service',
		mainPageRoute: {
			inPathName: item.mainPageRoute || 'custom-services',
			name: item.mainPageRoute || 'custom-services',
		},
		addEditPageRoute: {
			inPathName: item.addEditPageRoute || 'custom-service',
			name: item.addEditPageRoute || 'custom-service',
		},
		translates: {
			en: {
				singular: item.translates.singular || 'custom service',
				plural: item.translates.plural || 'custom services'
			},
			ar: {},
		},
		previewPath: item.previewPath, // || '/custom-services',
		icon: item.icon || 'mdi-dots-hexagon'
	})
});
export { customServices };

export const customCompanyValues = [];