import { headerAcceptLanguage } from '@/helpers/functions';
import { useRestore } from '@/configs/global.config';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		sellers: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sellers').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.sellers ? !c.deletedAt : true);
				commit(types.STORE_SELLERS, storeData);
				return storeData;
			})
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/sellers/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { fullName, email, residency, phone, type, companyNo, commercialNo, taxNo, landLine, country, city, address, postNo, contractDate, contractEndDate, deliveryCompanyNo, freeDeliveryLimit, userId, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/sellers', {
				fullName, email, residency, phone, type, companyNo, commercialNo, taxNo, landLine, country, city, address, postNo, contractDate, contractEndDate, deliveryCompanyNo, freeDeliveryLimit, userId
			}, configs);
		},

		update({ commit }, { id, fullName, email, residency, phone, type, companyNo, commercialNo, taxNo, landLine, country, city, address, postNo, contractDate, contractEndDate, deliveryCompanyNo, freeDeliveryLimit, userId, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post(`/sellers/${id}`, {
				fullName, email, residency, phone, type, companyNo, commercialNo, taxNo, landLine, country, city, address, postNo, contractDate, contractEndDate, deliveryCompanyNo, freeDeliveryLimit, userId, _method: 'PUT'
			}, configs);
		},

		changeBanned({ commit }, { id }) {
			return axios.post(`/sellers/${id}/banned`, { _method: 'PUT' });
		},

		changeVerify({ commit }, { id }) {
			return axios.post(`/sellers/${id}/verified`, { _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/sellers/${id}`, { _method: 'DELETE' });
		},

		restore({ commit }, { id }) {
			return axios.post(`/sellers/${id}`, { _method: 'PATCH' });
		},
	},

	mutations: {
		[types.STORE_SELLERS](state, sellers) {
			state.sellers = sellers;
		}
	},

	getters: {
		getSellerById: state => id => state.sellers.find(c => c.id === id),
		getSellerBySlug: state => slug => state.sellers.find(c => c.slug === slug),
	}
}