import wizardConfigs from './wizard-configs';
const { customFilters: customizedFilters } = wizardConfigs;

const customFilters = [];
customizedFilters.forEach((item, index) => {
	customFilters.push({
		type: item.type || index + 2,
		typeName: item.typeName || 'custom filter',
		route: {
			inPathName: item.route || 'custom-filters',
			name: item.route || 'custom-filters',
		},
		translates: {
			en: {
				singular: item.translates.singular || 'custom filter',
				plural: item.translates.plural || 'custom filters'
			},
			ar: {},
		},
		icon: item.icon || 'mdi-filter-cog'
	})
});
export { customFilters };