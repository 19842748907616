import { eStorage } from '@beetronix/estorage';
import moment from 'moment';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		authenticated: false,
		self: {
			id: null,
			name: null,
			expiresIn: null,
			hasPermission: null,
		}
	},

	actions: {
		login({ commit }, { username, password }) {
			return axios.post('/authentication', { userName: username, password }).then(({ data }) => {
				const auth = {
					id: data.id,
					token: data.access_token,
					name: data.fullname,
					username: username,
					expiresIn: data.expires_in,
					hasPermission: data.hasPremission
				};
				window.axios.defaults.headers.common.authorization = `Bearer ${auth.token}`;
				eStorage.l.set('auth', auth);
				eStorage.l.set('expiry-date', moment().add(auth.expiresIn, 'seconds').toISOString());
				commit(types.LOGIN, auth);
			})
		},

		logout({ commit }) {
			delete window.axios.defaults.headers.common.authorization;
			eStorage.l.remove('auth');
			eStorage.l.remove('expiry-date');
			commit(types.LOGOUT);
		}
	},

	mutations: {
		[types.LOGIN](state, auth) {
			state.authenticated = true;
			state.self.id = auth.id;
			state.self.name = auth.name;
			state.self.username = auth.username;
			state.self.expiresIn = auth.expiresIn;
			state.self.hasPermission = auth.hasPermission;
		},
		
		[types.LOGOUT](state) {
			state.authenticated = false;
			state.self = {};
		}
	}
}