<template>
	<!--
		@dragover.prevent
		@drop.prevent="!disabled ? (() => { updateVideos($event) })() : null"
	-->
	<div class="video-drop-zone">
		<div
			v-ripple="!videos.length && !disabled"
			class="video-container"
			@click="!videos.length && !disabled ? $emit('click') : null"
		>
			<div v-for="(video, index) in videos" :key="index">
				<div class="video-container__item d-flex justify-center align-center">
					<div
						class="d-flex justify-center align-center flex-grow-1"
						@click="previewVideoUrl = video.url"
					>
						<v-icon size="24" class="me-2">mdi-movie-open</v-icon>
						<span class="d-block text-body-1">
							{{video.name}}
						</span>
						<v-spacer/>
					</div>
					<v-btn
						v-if="!disabled"
						icon
						@click="
							$emit('remove-video', video.url);
							videos.splice(index, 1);
							$emit('input', videos);
							previewVideoUrl = null;
						"
					>
						<v-icon color="red lighten-1">mdi-delete</v-icon>
					</v-btn>
				</div>
			</div>
		
			<!-- labels and buttons -->
			<div v-if="!videos.length && !loading" class="d-flex justify-center align-center">
				<v-icon key="icon" size="36" class="me-1">mdi-video</v-icon>
				<span class="d-block text-body-1 font-weight-bold">{{$t('upload-videos')}}</span>
			</div>
			<v-btn
				v-else-if="videos.length && !disabled"
				height="24"
				width="100%"
				class="rounded-medium"
				text
				@click="$emit('click')"
			>
				<v-icon key="add" class="me-1">mdi-plus-circle-outline</v-icon>
				{{$t('upload-videos')}}
			</v-btn>
			<v-progress-linear v-if="loading" indeterminate/>
		</div>
		<span class="red--text text--lighten-1">{{error}}</span>
		
		<!-- player -->
		<video-player
			v-if="previewVideoUrl"
			ref="videoPlayer"
			:src="previewVideoUrl"
			class="mt-2"
		/>
	</div>
</template>

<script>
export default {
	name: 'BeeVideoZone',
	props: {
		disabled: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		'error-message': { type: String, default: 'only mp4 videos are allowed' },
		'no-drop-replace': { type: Boolean, default: false },
		value: { type: Array, default: () => [] }
	},

	data() {
		return {
			previewVideoUrl: null,
			error: null,
			videos: []
		}
	},

	watch: {
		value: {
			handler (val) {
				if (!val || val.length === 0) {
					this.previewVideoUrl = null;
					this.videos = [];
				}
				this.videos = val.map(c => {
					if (typeof c === 'string') {
						return { name: c, url: c }
					} else {
						return { name: c.name, url: c.url, ...c }
					}
				})
			},
			immediate: true
		}
	},

	methods: {
		updateVideos(event) {
			const files = event.dataTransfer.files;
			this.setImages(files);
		},

		setImages(files) {
			const arrayFiles = [];
			if (this.isAdd || this.noDropReplace) {
				arrayFiles.push(...this.videos);
			}
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (!/video\/mp4/g.test(file.type)) {
					this.error = this.errorMessage;
					return null;
				}
				arrayFiles.push({ name: file.name, url: URL.createObjectURL(file) });
			}
			this.videos = arrayFiles;
			this.$emit('input', arrayFiles);
		}
	}
}
</script>

<style lang="scss">
.video-drop-zone {
	.video-container {
		border: 2px dashed #ccc;
		border-radius: 11px;
		padding: 8px 12px;
		cursor: pointer;
	
		&__item {
			padding: 3px 6px;
		}
	}
}
</style>