import { sellers } from '@/configs/translates.config';

export default {
	'add-new-seller': 'add new seller',
	'the-seller': 'the seller',
	'are-you-sure-you-want-to-$-this-user': `Are you sure you want to {state} this ${sellers.en.singular}?`,
	seller: 'seller',
	banned: 'banned',
	unbanned: 'unbanned',
	verified: 'verified',
	unverified: 'unverified',
}