export default {
	'add-new-role': 'add new role',
	'the-role': 'the role?',

	role: 'role',
	'page-name': 'Page name',
	permission: 'Permission',

	permissionTypes: {
		sets: 'Sets',
		categories: 'Categories',
		filters: 'Filters',
		keywords: 'Keywords',
		products: 'Products',
		news: 'News',
		services: 'Services',
		orders: 'Orders',
		plans: 'Plans',
		albums: 'Albums',
		videos: 'Videos',
		sliders: 'Sliders',
		users: 'Users',
		systemUsers: 'System users',
		branches: 'Branches',
		subscriptions: 'Subscriptions',
		aboutUs: 'About us',
		companyValues: 'Company values',
		companyHistories: 'Company histories',
		SEO: 'SEO'
	}
}