export default {
	all: '',
	status: {
		approved: '',
		'in-preparation': '',
		shipping: '',
		completed: '',
		paid: '',
		unpaid: '',
		canceled: ''
	},
	'order-status': ''
}