<template>
	<v-card
		v-ripple
		class="file-card rounded-small"
	>
		<v-card-text
			class="d-flex align-center pa-2"
			@click="openFile"
		>
			<v-img :src="icon" max-width="32"/>
			<p class="text-caption ms-3 mb-0">
				{{fileName}}
			</p>
		</v-card-text>
		
		<div class="file-card__actions">
			<v-btn
				v-if="showSelect"
				small
				icon
				class="me-1 white"
				color="success"
				@click="$emit('select', src)"
			>
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>

			<v-btn
				small
				icon
				class="me-1 white"
				color="blue darken-2"
				@click="copyToClipboard"
			>
				<v-icon size="22">mdi-link-variant</v-icon>
			</v-btn>

			<v-btn
				small
				icon
				class="white"
				color="red lighten-1"
				@click="$emit('remove', src)"
			>
				<v-icon>mdi-delete</v-icon>
			</v-btn>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'FileCard',

	props: {
		src: { type: String },
		name: { type: String },
		uploadedBy: { type: String },
		showSelect: { type: Boolean, default: false }
	},
	
	data() {
		return {
			loading: false,
			fileName: null
		}
	},

	watch: {
		src(val) {
			this.setFileName(val);
		}	
	},

	computed: {
		icon() {
			let icon = 'other.png';
			if (this.src) {
				const splittedStr = this.src.split('/');
				const extension = splittedStr[splittedStr.length - 1].split('.')[1];
				switch (extension) {
				case 'pdf': icon = 'pdf.png'; break;
				case 'pptx':
				case 'ppt': icon = 'ppt.png'; break;
				case 'doc':
				case 'docx': icon = 'doc.png'; break;
				case 'xls':
				case 'xlsx': icon = 'xls.png'; break;
				case 'txt': icon = 'txt.png'; break;
				}
			}
			return require(`@/assets/icons/${icon}`)
		}
	},

	methods: {
		setFileName(val) {
			if (val) {
				const splittedStr = val.split('/')[val.split('/').length - 1].split('.');
				const name = this.name ? this.name : splittedStr[0];
				const extension = splittedStr[1];
				this.fileName = (name.length > 15 ? name.slice(0, 15) + '...' : name) + '.' + extension
			}
		},

		openFile() {
			window.open(this.src);
		},

		copyToClipboard() {
			navigator.clipboard.writeText(this.src);
			this.$eventBus.$emit('show-snackbar', false, this.$t('messages.copied'));
		}
	},

	created() {
		this.setFileName(this.src);
	}
}
</script>

<style lang="scss">
.file-card {
	cursor: pointer;
	
	&__actions {
		position: absolute;
		top: 50%;
		right: 8px;
		transform: translateY(-50%);
		
		opacity: 0;
		visibility: hidden;
		transition: all .3s;

		[dir=rtl] & {
			left: 8px;
		}
	}

	&:hover &__actions {
		opacity: 1;
		visibility: visible;
	}
}
</style>