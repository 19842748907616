import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		images: [],
	},

	actions: {
		fetchImages({ commit }) {
			return axios.get('/legacy-file-manager/all')
				.then(({ data }) => {
					commit(types.STORE_FILE_MANGER_IMAGE, data);
					return data
				})
		},

		uploadImage({ commit }, { image }) {
			const formData = new FormData();
			formData.append('image', image);
			return axios.post('/legacy-file-manager', formData).then(({ data }) => data);
		},

		deleteImage({ commit }, { imageUrl }) {
			const splittedUrl = imageUrl.split('/');
			const imageName = splittedUrl[splittedUrl.length - 1];
			return axios.post('/legacy-file-manager/delete', null, { params: { image: imageName } })
		}
	},

	mutations: {
		[types.STORE_FILE_MANGER_IMAGE](state, images) {
			state.images = images;
		}
	}
}