<template>
	<v-tabs
		v-model="localValue"
		:grow="grow"
		:height="height"
		center-active
		class="v-lang-tabs rounded-medium"
		:background-color="backgroundColor"
		@change="$emit('change', $event)"
	>
		<v-tab
			v-for="(lang, index) in langs"
			:key="lang.key"
			:disabled="disableNonSelected ? localValue !== index : false"
		>
			{{lang.key}}
			<v-icon v-if="checkedLangs[lang.key]" size="18" class="ms-1">mdi-check</v-icon>
		</v-tab>
	</v-tabs>
</template>

<script>
import { langs } from '@/configs/translates.config'

export default {
	name: 'VLangTabs',
	props: {
		backgroundColor: { type: String },
		checkedLangs: { type: Object, default: () => ({}) },
		grow: { type: Boolean, default: true },
		height: { type: [String, Number], default: 38 },
		value: { type: String },
		disableNonSelected: { type: Boolean, default: false }
	},

	data() {
		return {
			langs,
			langsKeys: langs.map(c => c.key)
		}
	},

	computed: {
		localValue: {
			get() {
				return this.langsKeys.indexOf(this.value);
			},
			set(newVal) {
				this.$emit('input', this.langsKeys[newVal]);
			}
		}
	},

	created() {
		if (this.value) {
			this.localValue = this.langsKeys.indexOf(this.value);
		}
	}
}
</script>

<style lang="scss">
.v-lang-tabs {
	overflow: hidden;
}
</style>