<template>
	<div>
		<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.basic-info')}}</p>
		<v-row align="center" class="ma-0 mb-5">
			<!-- fullname -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.fullname')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-account"
					persistent-placeholder
					readonly
					:value="fullname"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- username -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.username')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-badge-account-outline"
					persistent-placeholder
					readonly
					:value="username"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- email -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.email')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-email-outline"
					persistent-placeholder
					readonly
					:value="email"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- address -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.address')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-map-marker-outline"
					persistent-placeholder
					readonly
					:value="address"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- phone -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.phone')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-phone"
					persistent-placeholder
					readonly
					:value="phone"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- banned -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-switch
					v-model="isBanned"
					:label="isBanned ? $t('UserInfo.inactive') : $t('UserInfo.active')"
					:true-value="0"
					:false-value="1"
					:ripple="false"
					readonly
					hide-details
					color="darkGreen"
					class="ma-0"
				>
					<template v-slot:prepend>
						<v-icon v-if="isBanned" size="25" color="error lighten-2">
							mdi-account-cancel
						</v-icon>
						<v-icon v-else size="25" color="darkGreen">
							mdi-account-check
						</v-icon>
					</template>
				</v-switch>
			</v-col>
		
			<!-- user type -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.user-type')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-account-tag-outline"
					persistent-placeholder
					readonly
					:value="getUserType()"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- user country -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.user-country')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-city-variant"
					persistent-placeholder
					readonly
					:value="userData.country"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		</v-row>

		<!-- additional info -->
		<template v-if="showAdditionalInfo">
			<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.additional-info')}}</p>
			<v-row class="ma-0 mb-5">
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" v-if="additionalUserInfo.latestWebsiteVisit">
					<v-text-field
						:label="$t('UserInfo.latest-visit')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-web-clock"
						persistent-placeholder
						readonly
						:loading="loading"
						class="rounded-medium"
						:value="additionalInfo.latestVisit"
					></v-text-field>
				</v-col>
			</v-row>
		</template>
		
		<!-- user plan -->
		<template v-if="showPlans">
			<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.user-plan')}}</p>
			<v-row class="ma-0 mb-5">
				<template v-if="loading || hasPlan">
					<!-- plan name -->
					<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
						<v-text-field
							v-model="planData.name"
							:label="$t('UserInfo.plan-name')"
							hide-details
							outlined
							dense
							prepend-icon="mdi-card-bulleted-outline"
							persistent-placeholder
							readonly
							:value="userData.name"
							:loading="loading"
							class="rounded-medium"
						></v-text-field>
					</v-col>
			
					<!-- plan date -->
					<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
						<v-text-field
							v-model="planData.date"
							:label="$t('UserInfo.subscription-in-plan-date')"
							hide-details
							outlined
							dense
							prepend-icon="mdi-calendar"
							persistent-placeholder
							readonly
							:value="userData.date"
							:loading="loading"
							class="rounded-medium"
						></v-text-field>
					</v-col>
			
					<!-- subscription end in -->
					<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
						<v-text-field
							:label="$t('UserInfo.subscription-end-in')"
							hide-details
							outlined
							dense
							prepend-icon="mdi-calendar-clock"
							persistent-placeholder
							readonly
							:value="planData.date ? moment(planData.date).add(planData.duration, 'months').format('YYYY-MM-DD') : null"
							:loading="loading"
							class="rounded-medium"
						></v-text-field>
					</v-col>
			
					<!-- plan price -->
					<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
						<v-text-field
							v-model="planData.price"
							:label="$t('UserInfo.plan-price')"
							hide-details
							outlined
							dense
							prepend-icon="mdi-cash"
							persistent-placeholder
							readonly
							:loading="loading"
							class="rounded-medium"
						></v-text-field>
					</v-col>
				</template>
			
				<v-col v-else md="4" sm="6" cols="12" class="text-body-1">
					<div class="d-flex align-center text-body-1 text-uppercase font-weight-medium me-2">
						<v-icon size="22" color="grey darken-2" class="me-2">mdi-information-outline</v-icon>
						{{$t('UserInfo.user-has-no-plan')}}
					</div>
				</v-col>
			</v-row>
		</template>

		<!-- statistics -->
		<div v-if="statistics.averageOrderValue || statistics.cartAbandonmentRate" class="mb-5">
			<p class="text-h6 text-capitalize primary--text mb-0">
				{{$t('statistics.title')}}
			</p>

			<!-- filter -->
			<v-row dense>
				<!-- fromDate -->
				<v-col lg="2" md="3" sm="4" cols="6">
					<v-menu
						v-model="fromDateMenu"
						transition="scale-transition"
						offset-y
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="fromDate"
								:label="$t('inputs.from-date')"
								prepend-inner-icon="mdi-calendar"
								hide-details
								outlined
								dense
								class="rounded-medium"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="fromDate"
							@input="fromDateMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>

				<!-- toDate -->
				<v-col lg="2" md="3" sm="4" cols="6">
					<v-menu
						v-model="toDateMenu"
						transition="scale-transition"
						offset-y
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="toDate"
								:label="$t('inputs.to-date')"
								prepend-inner-icon="mdi-calendar"
								readonly
								hide-details
								outlined
								dense
								class="rounded-medium"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="toDate"
							@input="toDateMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>

				<v-spacer/>
				
				<!-- search -->
				<v-col cols="auto">
					<v-btn
						color="primary"
						class="rounded-medium"
						:loading="filterLoading"
						@click="fetchStatics"
					>
						{{$t('search')}}
					</v-btn>
				</v-col>
			</v-row>
		
			<!-- charts -->
			<v-row dense align="center" class="ma-0">
				<v-col cols="auto">
					<v-chip-group
						v-model="chartType"
						mandatory
						dense
						show-arrows
						active-class="primary"
					>
						<v-chip v-if="statistics.averageOrderValue" small :value="0">
							{{$t('statistics.average-order-value')}}
						</v-chip>
						<v-chip v-if="statistics.cartAbandonmentRate" small :value="1">
							{{$t('statistics.cart-abandonment-rate')}}
						</v-chip>
					</v-chip-group>
				</v-col>
				
				<v-spacer/>

				<!-- view by -->
				<v-col lg="1" md="3" cols="6">
					<v-autocomplete
						v-model="selectedViewBy"
						:label="$t('inputs.view-type')"
						:items="viewBy"
						item-text="name"
						item-value="id"
						hide-details
						outlined
						dense
						append-icon=""
						class="mini-input rounded-medium"
						@change="customViewBy = null; buildAdvanceStaticsData()"
						@blur="
							selectedViewBy === null
							? (() => { selectedViewBy = 2 ; buildAdvanceStaticsData(); })()
							: null
						"
					></v-autocomplete>
				</v-col>
				
				<!-- custom view by -->
				<v-col v-if="selectedViewBy === 0" lg="1" md="3" cols="6">
					<v-text-field
						v-model.number="customViewBy"
						:label="$t('inputs.custom-period')"
						:items="viewBy"
						item-text="name"
						item-value="id"
						type="number"
						min="0"
						max="365"
						hide-spin-buttons
						hide-details
						outlined
						dense
						class="mini-input rounded-medium"
						@change="buildAdvanceStaticsData()"
					></v-text-field>
				</v-col>

				<!-- year -->
				<v-col md="2" sm="3" cols="12" class="d-flex align-center" dir="ltr">
					<v-btn
						icon
						@click="chartYear = moment(chartYear).subtract(1, 'years').format('YYYY')"
					>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-text-field
						v-model="chartYear"
						dir="ltr"
						dense
						outlined
						hide-details
						readonly
						class="mini-input mini-input--centered rounded-medium"
					></v-text-field>
					<v-btn
						icon
						:disabled="chartYear === moment().format('YYYY')"
						@click="chartYear = moment(chartYear).add(1, 'years').format('YYYY')"
					>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<!-- chart -->
			<div class="ma-0 mb-5">
				<v-card class="rounded-medium">
					<v-card-text class="px-0 py-2">
						<apex-chart
							type="line"
							datetime
							no-markers
							:show-data-labels="false"
							show-tooltip
							:year="chartYear"
							:series="chartSeries"
						/>
					</v-card-text>
				</v-card>
			</div>
		</div>

		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: 'users' }"
		>
			{{$t('back')}}
		</v-btn>
	</div>
</template>

<script>
import moment from 'moment';
import { additionalUserInfo, statistics } from '@/configs/global.config';
import { plans } from '@/configs/routes.config';
import { divideStaticsByDateStep, getCartAbandonmentRate } from '@/helpers/statistics';

export default {
	name: 'UserInfo',

	data: (vm) => ({
		loading: false,
		filterLoading: false,

		viewBy: [
			{ id: 1, name: vm.$t('view-by.daily'), value: 1 },
			{ id: 2, name: vm.$t('view-by.weekly'), value: 7 },
			{ id: 3, name: vm.$t('view-by.monthly'), value: 30 },
			{ id: 4, name: vm.$t('view-by.yearly'), value: 365 },
			{ id: 0, name: vm.$t('view-by.custom'), value: null },
		],

		// configs
		showPlans: plans.show,
		additionalUserInfo,
		showAdditionalInfo: Object.keys(additionalUserInfo).some(key => additionalUserInfo[key]),
		statistics,

		// data
		fullname: null,
		username: null,
		email: null,
		address: null,
		phone: null,
		isBanned: false,

		userData: {
			type: null,
			country: null,
		},

		additionalInfo: {
			latestVisit: null,
		},
		
		hasPlan: false,
		planData: {
			name: null,
			date: null,
			price: null,
			duration: null,
		},

		// statistics
		fromDateMenu: false,
		toDateMenu: false,

		fromDate: moment().startOf('year').format('YYYY-MM-DD'),
		toDate: moment().format('YYYY-MM-DD'),

		selectedViewBy: 2,
		customViewBy: null,
		
		chartType: 0,
		chartYear: moment().format('YYYY'),
		advanceStatics: {},
		userStatics: {
			AOV: [],
			cartAbandonmentRate: []
		}
	}),

	computed: {
		chartSeries() {
			const series = [];
			if (this.chartType === 0) {
				series.push({
					name: this.$t('statistics.average-order-value'),
					data: this.userStatics.AOV
				})
			} else if (this.chartType === 1) {
				series.push({
					name: this.$t('statistics.cart-abandonment-rate'),
					data: this.userStatics.cartAbandonmentRate
				})
			}
			return series;
		},
	},

	methods: {
		fetchUserInfo(isFistLoad = false) {
			this.loading = true;
			return this.$store.dispatch('users/fetchById', {
				id: this.$route.params.id
			}).then((data) => {
				this.fullname = data.fullname;
				this.username = data.username;
				this.email = data.email;
				this.address = data.address;
				this.phone = data.phone;
				this.isBanned = data.isBanned;

				if (data.data) {
					this.userData.type = data.data.type;
					this.userData.country = data.data.country;
				}
				
				this.hasPlan = data.hasPlan;
				this.planData.name = data.planData.name;
				this.planData.date = data.planData.date ? moment(data.planData.date).format('YYYY-MM-DD') : null;
				this.planData.price = data.planData.price;
				this.planData.duration = data.planData.duration;
			}).finally(() => {
				if (!isFistLoad) this.loading = false;
			})
		},

		fetchStatics(isFistLoad = false) {
			this.filterLoading = true;
			return this.$store.dispatch('users/fetchUserStatics', {
				id: this.$route.params.id,
				fromDate: this.fromDate,
				toDate: this.toDate,
			}).then((data) => {
				this.advanceStatics = data;
				this.buildAdvanceStaticsData();
			}).finally(() => {
				if (!isFistLoad) this.filterLoading = false;
			})
		},

		getUserType() {
			switch (this.userData.type) {
			case '1': return this.$t('UserInfo.types.student');
			case '2': return this.$t('UserInfo.types.teacher');
			case '3': return this.$t('UserInfo.types.parent');
			default: return this.$t('UserInfo.types.undefined')
			}
		},

		buildAdvanceStaticsData() {
			const buildStatics = (arr, calculateFunc = divideStaticsByDateStep) => {
				const step =
				this.selectedViewBy
					? this.viewBy.find(c => c.id === this.selectedViewBy).value
					: this.customViewBy;
				if (!step) return [];

				return calculateFunc(arr, step);
			}
			const { AOV, getCartRate: cartAbandonmentRate } = this.advanceStatics;
			this.userStatics.AOV =
				statistics.averageOrderValue ? buildStatics(AOV) : [];
			this.userStatics.cartAbandonmentRate =
				statistics.cartAbandonmentRate ? buildStatics(cartAbandonmentRate, getCartAbandonmentRate) : [];
		},

		moment
	},

	created() {
		this.loading = true;
		this.filterLoading = true;
		Promise.all([
			this.fetchUserInfo(true),
			statistics.averageOrderValue || statistics.cartAbandonmentRate ? this.fetchStatics(true) : null
		]).finally(() => {
			this.loading = false;
			this.filterLoading = false;
		})
	}
}
</script>

<style>

</style>