<template>
	<v-form id="SEOpage" ref="form" @submit.prevent="submit">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="loading || submitLoading"
			background-color="white"
			class="mb-4"
			@change="updateFieldsFromDraft"
		/>

		<!-- home page -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.home-page')}}</div>
		<v-row dense>
			<!-- SEO keywords -->
			<v-col lg="6" cols="12">
				<v-chips-input
					v-model="homePage.keywords"
					:label="$t('inputs.seo-keywords')"
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
				></v-chips-input>
			</v-col>

			<!-- SEO description -->
			<v-col lg="6" cols="12">
				<v-textarea
					v-model="homePage.description"
					:label="$t('inputs.seo-description')"
					outlined
					dense
					auto-grow
					rows="1"
					no-resize
					counter
					:hint="inputsHint(homePage.description)"
					persistent-hint
					:color="inputsHint(homePage.description).length ? 'warning' : null"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					@keypress.enter.prevent
					@change="homePage.description = homePage.description.replace(/\n/g, ' ')"
				></v-textarea>
			</v-col>
		</v-row>

		<!-- products -->
		<div v-if="showProducts">
			<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.products-page')}}</div>
			<v-row dense>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-chips-input
						v-model="products.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-chips-input>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="products.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(products.description)"
						persistent-hint
						:color="inputsHint(products.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@change="products.description = products.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>
		
		<!-- news -->
		<div v-if="showNews">
			<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.news-page')}}</div>
			<v-row dense>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-chips-input
						v-model="news.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-chips-input>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="news.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(news.description)"
						persistent-hint
						:color="inputsHint(news.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@change="news.description = news.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<!-- services -->
		<div v-if="showServices">
			<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.services-page')}}</div>
			<v-row dense>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-chips-input
						v-model="services.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-chips-input>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="services.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(services.description)"
						persistent-hint
						:color="inputsHint(services.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@change="services.description = services.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>
		
		<!-- albums -->
		<div v-if="showAlbums">
			<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.albums-page')}}</div>
			<v-row dense>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-chips-input
						v-model="albums.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-chips-input>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="albums.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(albums.description)"
						persistent-hint
						:color="inputsHint(albums.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@change="albums.description = albums.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<!-- aboutUs -->
		<div v-if="showAboutUs">
			<div class="text-body-1 font-weight-bold text-capitalize mb-1">{{$t('SEOpage.about-us-page')}}</div>
			<v-row dense>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-chips-input
						v-model="aboutUs.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-chips-input>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="aboutUs.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(aboutUs.description)"
						persistent-hint
						:color="inputsHint(aboutUs.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@change="aboutUs.description = aboutUs.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import { news, products, services, albums, aboutUs } from '@/configs/routes.config';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import langsDraft from '@/mixins/langs-draft';

export default {
	name: 'SEOpage',
	mixins: [langsDraft],
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		lang: defaultLang,

		// configs
		showNews: news.show,
		showProducts: products.show,
		showServices: services.show,
		showAlbums: albums.show,
		showAboutUs: aboutUs.show,

		multiLangInputs,

		// draft for languages 
		draft: {},
		draftNames: [
			'homePage',
			'products',
			'news',
			'services',
			'albums',
			'aboutUs',
		],

		// data
		homePage: {
			description: null,
			keywords: null,
		},
		products: {
			description: null,
			keywords: null,
		},
		news: {
			description: null,
			keywords: null,
		},
		services: {
			description: null,
			keywords: null,
		},
		albums: {
			description: null,
			keywords: null,
		},
		aboutUs: {
			description: null,
			keywords: null,
		}
	}),

	methods: {
		inputsHint(val) {
			return val && val.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : '';
		},

		fetchSEOConfigs() {
			this.loading = true;
			const promises = [];

			let currentLangData;
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('SEO/fetchAll', { acceptLanguage: c.key })
						.then((data) => {
							if (this.lang === c.key) currentLangData = data;
							this.fillInDraft(data, c.key);
						})
				)
			});

			Promise.all(promises).then(() => {
				this.fillToEdit(currentLangData);
			}).finally(() => {
				this.loading = false;
			});
		},

		submit() {
			this.submitLoading = true;

			const promises = [];
			const submitRequest = ({ 
				homeDescription, homeKeywords, productsDescription, productsKeywords, newsDescription, newsKeywords, servicesDescription, servicesKeywords, albumsDescription, albumsKeywords, aboutUsDescription, aboutUsKeywords, lang
			}) => {
				return this.$store.dispatch('SEO/create', { 
					homeDescription,
					homeKeywords,
					
					productsDescription,
					productsKeywords,
					
					newsDescription,
					newsKeywords,
					
					servicesDescription,
					servicesKeywords,
					
					albumsDescription,
					albumsKeywords,
					
					aboutUsDescription,
					aboutUsKeywords,
					
					acceptLanguage: lang
				})
			};

			const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
			for (let index = 0; index < filteredLangs.length; index++) {
				const lang = filteredLangs[index].key;

				const homeDescription = this.draft.homePage[lang].description;
				const homeKeywords = this.draft.homePage[lang].keywords;
				
				const productsDescription = this.draft.products[lang].description;
				const productsKeywords = this.draft.products[lang].keywords;
				
				const newsDescription = this.draft.news[lang].description;
				const newsKeywords = this.draft.news[lang].keywords;
				
				const servicesDescription = this.draft.services[lang].description;
				const servicesKeywords = this.draft.services[lang].keywords;
				
				const albumsDescription = this.draft.albums[lang].description;
				const albumsKeywords = this.draft.albums[lang].keywords;
				
				const aboutUsDescription = this.draft.aboutUs[lang].description;
				const aboutUsKeywords = this.draft.aboutUs[lang].keywords;

				const dataDueLang = { homeDescription, homeKeywords, productsDescription, productsKeywords, newsDescription, newsKeywords, servicesDescription, servicesKeywords, albumsDescription, albumsKeywords, aboutUsDescription, aboutUsKeywords, lang };

				promises.push(submitRequest(dataDueLang));
			}

			Promise.all(promises).then(() => {
				const message = this.$t('$-is-updated-successfully', { name: this.$t('SEOpage.seo-fields') })
				this.$eventBus.$emit('show-snackbar', false, message);
				this.fetchSEOConfigs();
			}).finally(() => {
				this.submitLoading = false;
			})
		},

		fillInDraft(data, lang) {
			if (!this.draft.homePage[lang]) this.draft.homePage[lang] = {};
			if (!this.draft.products[lang]) this.draft.products[lang] = {};
			if (!this.draft.news[lang]) this.draft.news[lang] = {};
			if (!this.draft.services[lang]) this.draft.services[lang] = {};
			if (!this.draft.albums[lang]) this.draft.albums[lang] = {};
			if (!this.draft.aboutUs[lang]) this.draft.aboutUs[lang] = {};

			this.draft.homePage[lang].description = data.find(c => c.key === 'home_description')?.value;
			this.draft.homePage[lang].keywords = data.find(c => c.key === 'home_keyword')?.value;
			
			this.draft.products[lang].description = data.find(c => c.key === 'products_description')?.value;
			this.draft.products[lang].keywords = data.find(c => c.key === 'products_keyword')?.value;
			
			this.draft.news[lang].description = data.find(c => c.key === 'news_description')?.value;
			this.draft.news[lang].keywords = data.find(c => c.key === 'news_keyword')?.value;
			
			this.draft.services[lang].description = data.find(c => c.key === 'services_description')?.value;
			this.draft.services[lang].keywords = data.find(c => c.key === 'services_keyword')?.value;
			
			this.draft.albums[lang].description = data.find(c => c.key === 'albums_description')?.value;
			this.draft.albums[lang].keywords = data.find(c => c.key === 'albums_keyword')?.value;
			
			this.draft.aboutUs[lang].description = data.find(c => c.key === 'aboutus_description')?.value;
			this.draft.aboutUs[lang].keywords = data.find(c => c.key === 'aboutus_keyword')?.value;
		},
		
		fillToEdit(data) {
			this.homePage.description = data.find(c => c.key === 'home_description')?.value;
			this.homePage.keywords = data.find(c => c.key === 'home_keyword')?.value;
			
			this.products.description = data.find(c => c.key === 'products_description')?.value;
			this.products.keywords = data.find(c => c.key === 'products_keyword')?.value;
			
			this.news.description = data.find(c => c.key === 'news_description')?.value;
			this.news.keywords = data.find(c => c.key === 'news_keyword')?.value;
			
			this.services.description = data.find(c => c.key === 'services_description')?.value;
			this.services.keywords = data.find(c => c.key === 'services_keyword')?.value;
			
			this.albums.description = data.find(c => c.key === 'albums_description')?.value;
			this.albums.keywords = data.find(c => c.key === 'albums_keyword')?.value;
			
			this.aboutUs.description = data.find(c => c.key === 'aboutus_description')?.value;
			this.aboutUs.keywords = data.find(c => c.key === 'aboutus_keyword')?.value;
		}
	},

	created() {
		this.fetchSEOConfigs();
	}
}
</script>

<style lang="scss">
#SEOpage {
	.v-input__slot, .v-text-field__details {
		margin-bottom: 0;
	}
}
</style>