import { users } from '@/configs/translates.config';

export default {
	'add-new-user': `add new ${users.en.singular}`,
	'the-user': `the ${users.en.singular}`,
	users: users.en.plural,
	confirm: 'Confirm',
	activate: 'activate',
	deactivate: 'deactivate',
	'are-you-sure-you-want-to-$-this-user': `Are you sure you want to {state} this ${users.en.singular}?`,
	'user-state-is-changed-successfully': `${users.en.singular} state is changed successfully`,
	'send-email': 'Send email'
}