import { affiliateMarketers } from '@/configs/translates.config'
export default {
	'add-new-affiliate-marketer': `add new ${affiliateMarketers.en.singular}`,
	'the-affiliate-marketer': `the ${affiliateMarketers.en.singular}?`,
	'affiliate-marketer': affiliateMarketers.en.singular,

	'total-views': 'Total views',
	'total-purchases': 'Total purchases',
	'total-commission': 'Total commission',
	'marketer-name': 'Marketer name'
}