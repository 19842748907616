<template>
	<div class="image-card">
		<div
			class="image-card__image-container rounded-small"
			:class="{ 'image-card__image-container--large': large }"
		>
			<v-img
				:src="src"
				lazy-src="@/assets/blurred.png"
				:max-width="large ? '100%' : 250"
				:max-height="large ? 300 : 120"
				class="rounded-small"
			></v-img>
			
			<div class="image-card__actions">
				<v-btn
					height="30"
					width="30"
					min-width="20"
					color="primary"
					class="image-card__action rounded-xl px-2"
					@click="$emit('preview', src)"
				>
					<v-icon size="22">mdi-eye-outline</v-icon>
				</v-btn>
				<v-btn
					v-if="showAlt"
					height="30"
					width="30"
					dark
					min-width="20"
					color="deep-purple"
					class="image-card__action rounded-xl px-2"
					@click="$emit('alt-clicked', src)"
				>
					<v-icon size="22">mdi-image-text</v-icon>
				</v-btn>
				<v-btn
					v-if="showSelect && !showCancel"
					height="30"
					width="30"
					min-width="20"
					color="success"
					class="image-card__action rounded-xl px-2"
					@click="$emit('select', src)"
				>
					<v-icon size="22">mdi-check</v-icon>
				</v-btn>
				<v-btn
					v-if="showCancel"
					height="30"
					width="30"
					min-width="20"
					color="grey darken-3 white--text"
					class="image-card__action rounded-xl px-2"
					@click="$emit('cancel', src)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
				<v-btn
					height="30"
					width="30"
					min-width="20"
					color="red lighten-1"
					class="image-card__action rounded-xl px-2"
					@click="$emit('remove', src)"
				>
					<v-icon size="22" color="white">mdi-delete</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageCard',
	props: {
		src: { type: String },
		uploadedBy: { type: String },
		showSelect: { type: Boolean, default: false },
		showCancel: { type: Boolean, default: false },
		showAlt: { type: Boolean, default: false },
		large: { type: Boolean, default: false },
	}
}
</script>

<style lang="scss">
.image-card {
	&__image-container {
		position: relative;
		max-width: 250px;

		border: 1px solid #ddd;

		&--large {
			max-width: 100%;
		}
	}
	&__action {
		box-shadow: 0 0 3px white;
	}
	&__actions {
		opacity: 0;
		visibility: hidden;
		
		transition: all .3s;
		
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	&:hover &__actions {
		opacity: 1;
		visibility: visible;
	}
}
</style>