import wizardConfigs from './wizard-configs';
const { languages, pages } = wizardConfigs;

export const defaultLang = languages.defaultLang || 'en';
export const multiLangUI = false;
export const multiLangInputs = languages.multiLangInputs;
export const langs = [
	{ key: 'en', name: 'English', show: languages.langs.en || defaultLang === 'en' },
	{ key: 'ar', name: 'العربية', show: languages.langs.ar || defaultLang === 'ar' },
	{ key: 'es', name: 'Español', show: languages.langs.es || defaultLang === 'es' },
	{ key: 'fr', name: 'France', show: languages.langs.fr || defaultLang === 'fr' },
	{ key: 'fa', name: 'فارسی', show: languages.langs.fa || defaultLang === 'fa' },
	{ key: 'de', name: 'Deutsch', show: languages.langs.de || defaultLang === 'de' },
	{ key: 'zh', name: '中國人', show: languages.langs.zh || defaultLang === 'zh' },
	{ key: 'ru', name: 'Русский', show: languages.langs.ru || defaultLang === 'ru' },
	{ key: 'tr', name: 'Türkçe', show: languages.langs.tr || defaultLang === 'tr' },
].filter(c => c.show).sort(c => c.key === defaultLang ? -1 : 1);

export const login = {
	en: {
		singular: pages.login.singular || 'login',
		plural: pages.login.plural || 'login'
	},
	ar: ''
}

export const preview = {
	en: {
		singular: pages.preview.singular || 'preview',
		plural: pages.preview.plural || 'preview'
	},
	ar: ''
}

export const dashboard = {
	en: {
		singular: pages.dashboard.singular || 'dashboard',
		plural: pages.dashboard.plural || 'dashboard'
	},
	ar: ''
}

export const sets = {
	en: {
		singular: pages.sets.singular || 'set',
		plural: pages.sets.plural || 'sets'
	},
	ar: ''
}

export const categories = {
	en: {
		singular: pages.categories.singular || 'category',
		plural: pages.categories.plural || 'categories'
	},
	ar: ''
}

export const category = {
	en: {
		singular: pages.category.singular || 'category',
		plural: pages.category.plural || 'categories'
	},
	ar: ''
}
export const filters = {
	en: {
		singular: pages.filters.singular || 'filter',
		plural: pages.filters.plural || 'filters'
	},
	ar: ''
}
export const keywords = {
	en: {
		singular: pages.keywords.singular || 'keyword',
		plural: pages.keywords.plural || 'keywords'
	},
	ar: ''
}

export const publishers = {
	en: {
		singular: pages.publishers.singular || 'publisher',
		plural: pages.publishers.plural || 'publishers'
	},
	ar: ''
}

export const sellers = {
	en: {
		singular: pages.sellers.singular || 'publisher',
		plural: pages.sellers.plural || 'sellers'
	},
	ar: ''
}

export const products = {
	en: {
		singular: pages.products.singular || 'product',
		plural: pages.products.plural || 'products'
	},
	ar: ''
}

export const product = {
	en: {
		singular: pages.product.singular || 'product',
		plural: pages.product.plural || 'products'
	},
	ar: ''
}
export const news = {
	en: {
		singular: pages.news.singular || 'news',
		plural: pages.news.plural || 'news'
	},
	ar: ''
}

export const newsIndex = {
	en: {
		singular: pages.newsIndex.singular || 'news',
		plural: pages.newsIndex.plural || 'news'
	},
	ar: ''
}

export const services = {
	en: {
		singular: pages.services.singular || 'service',
		plural: pages.services.plural || 'services'
	},
	ar: ''
}

export const service = {
	en: {
		singular: pages.service.singular || 'service',
		plural: pages.service.plural || 'services'
	},
	ar: ''
}

export const orders = {
	en: {
		singular: pages.orders.singular || 'order',
		plural: pages.orders.plural || 'orders'
	},
	ar: ''
}

export const order = {
	en: {
		singular: pages.order.singular || 'order',
		plural: pages.order.plural || 'orders'
	},
	ar: ''
}

export const loyaltyPoints = {
	en: {
		singular: pages.loyaltyPoints.singular || 'loyalty point',
		plural: pages.loyaltyPoints.plural || 'loyalty points'
	},
	ar: ''
}

export const plans = {
	en: {
		singular: pages.plans.singular || 'plan',
		plural: pages.plans.plural || 'plans'
	},
	ar: ''
}

export const plan = {
	en: {
		singular: pages.plan.singular || 'plan',
		plural: pages.plan.plural || 'plans'
	},
	ar: ''
}

export const coupons = {
	en: {
		singular: pages.coupons.singular || 'coupon',
		plural: pages.coupons.plural || 'coupons'
	},
	ar: ''
}

export const offers = {
	en: {
		singular: pages.offers.singular || 'offer',
		plural: pages.offers.plural || 'offers'
	},
	ar: ''
}

export const albums = {
	en: {
		singular: pages.albums.singular || 'album',
		plural: pages.albums.plural || 'albums'
	},
	ar: ''
}

export const videos = {
	en: {
		singular: pages.videos.singular || 'video',
		plural: pages.videos.plural || 'videos'
	},
	ar: ''
}

export const sliders = {
	en: {
		singular: pages.sliders.singular || 'slider',
		plural: pages.sliders.plural || 'sliders'
	},
	ar: ''
}

export const users = {
	en: {
		singular: pages.users.singular || 'customer',
		plural: pages.users.plural || 'customers'
	},
	ar: ''
}

export const userInfo = {
	en: {
		singular: pages.userInfo.singular || 'user info',
		plural: pages.userInfo.plural || 'users info'
	},
	ar: ''
}

export const affiliateMarketers = {
	en: {
		singular: pages.affiliateMarketers.singular || 'affiliate marketers',
		plural: pages.affiliateMarketers.plural || 'affiliate marketers'
	},
	ar: ''
}

export const affiliateMarketer = {
	en: {
		singular: pages.affiliateMarketer.singular || 'affiliate marketers',
		plural: pages.affiliateMarketer.plural || 'affiliate marketers'
	},
	ar: ''
}

export const systemUsers = {
	en: {
		singular: pages.systemUsers.singular || 'system user',
		plural: pages.systemUsers.plural || 'system users'
	},
	ar: ''
}

export const systemUsersRoles = {
	en: {
		singular: pages.systemUsersRoles.singular || 'system users role',
		plural: pages.systemUsersRoles.plural || 'system users roles'
	},
	ar: ''
}

export const branches = {
	en: {
		singular: pages.branches.singular || 'branch',
		plural: pages.branches.plural || 'branches'
	},
	ar: ''
}

export const branch = {
	en: {
		singular: pages.branch.singular || 'branch',
		plural: pages.branch.plural || 'branches'
	},
	ar: ''
}

export const subscriptions = {
	en: {
		singular: pages.subscriptions.singular || 'subscription',
		plural: pages.subscriptions.plural || 'subscriptions'
	},
	ar: ''
}

export const aboutUs = {
	en: {
		singular: pages.aboutUs.singular || 'about us',
		plural: pages.aboutUs.plural || 'about us',
	},
	ar: ''
}

export const companyValues = {
	en: {
		singular: pages.companyValues.singular || 'Company value',
		plural: pages.companyValues.plural || 'Company values'
	},
	ar: ''
}

export const companyValue = {
	en: {
		singular: pages.companyValue.singular || 'Company value',
		plural: pages.companyValue.plural || 'Company values'
	},
	ar: ''
}

export const companyHistories = {
	en: {
		singular: pages.companyHistories.singular || 'Company history',
		plural: pages.companyHistories.plural || 'Company histories'
	},
	ar: ''
}

export const companyHistory = {
	en: {
		singular: pages.companyHistory.singular || 'Company history',
		plural: pages.companyHistory.plural || 'Company histories'
	},
	ar: ''
}

export const fileManager = {
	en: {
		singular: pages.fileManager.singular || 'File manager',
		plural: pages.fileManager.plural || 'File manager',
	},
	ar: ''
}

export const SEO = {
	en: {
		singular: pages.SEO.singular || 'SEO',
		plural: pages.SEO.plural || 'SEO',
	},
	ar: ''
}