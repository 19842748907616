import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		albums: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/albums').then(({ data }) => {
				commit(types.STORE_ALBUMS, data);
				return data;
			})
		},

		fetchById({ commit }, { id, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			return axios.get(`/albums/${id}`, configs).then(({ data }) => data);
		},

		create({ commit }, { title, content, image, imagePath, imageName, inHomePage, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { title, content, image, imageName };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('inHomePage', inHomePage ? 1 : 0);
			
			return axios.post('/albums', formData, configs).then(({ data }) => data)
		},

		update({ commit }, { id, title, content, image, imagePath, imageName, inHomePage, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { title, content, image, imageName };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('inHomePage', inHomePage ? 1 : 0);
			formData.append('_method', 'PUT');

			return axios.post(`/albums/${id}`, formData, configs).then(({ data }) => data)
		},

		uploadImages({ commit }, { id, images, imagesPaths, imagesNames }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/albums/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.post(`/albums/${id}/images/${imageId}`, { _method: 'DELETE' })
		},

		delete({ commit }, { id }) {
			return axios.post(`/albums/${id}`, { _method: 'DELETE' })
		},
		
		restore({ commit }, { id }) {
			return axios.post(`/albums/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_ALBUMS](state, albums) {
			state.albums = albums;
		}
	},

	getters: {
		getAlbumById: state => id => state.albums.find(c => c.id === id),
	}
}