import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		sliders: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sliders').then(({ data }) => {
				commit(types.STORE_SLIDERS, data);
				return data;
			})
		},

		fetchById({ commit }, { id, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/sliders/${id}`, configs).then(({ data }) => data)
		},

		create({ commit }, { description, url, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { description, url, image, imageName };
			
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			return axios.post('/sliders', formData, configs).then(({ data }) => data);
		},
		update({ commit }, { id, description, url, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { description, url, image, imageName };
			
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('_method', 'PUT');

			return axios.post(`/sliders/${id}`, formData, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.post(`/sliders/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/sliders/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_SLIDERS](state, sliders) {
			state.sliders = sliders;
		}
	},

	getters: {
		getSlideById: state => id => state.sliders.find(c => c.id === id),
	}
}