import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		SEO: {},
	},

	actions: {
		fetchAll({ commit }, { acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage)
			return axios.get('/seo-config', configs).then(({ data }) => {
				commit(types.STORE_SEO, data);
				return data;
			})
		},
		
		create({ commit }, {
			homeDescription,
			homeKeywords,
			newsDescription,
			newsKeywords,
			productsDescription,
			productsKeywords,
			servicesDescription,
			servicesKeywords,
			albumsDescription,
			albumsKeywords,
			aboutUsDescription,
			aboutUsKeywords,
			acceptLanguage
		}) {
			const configs = headerAcceptLanguage(acceptLanguage)
			return axios.post('/seo-config', {
				homeDescription,
				homeKeywords,
				newsDescription,
				newsKeywords,
				productsDescription,
				productsKeywords,
				servicesDescription,
				servicesKeywords,
				albumsDescription,
				albumsKeywords,
				aboutUsDescription,
				aboutUsKeywords,
			}, configs)
		}
	},

	mutations: {
		[types.STORE_SEO](state, SEO) {
			state.SEO = SEO;
		}
	}
}