import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		news: []
	},

	actions: {
		fetchAll({ commit }, { useOrder }) {
			return axios.get('/news', {
				params: { useOrder }
			}).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.news ? !c.deletedAt : true);
				commit(types.STORE_NEWS, storeData);
				return storeData;
			})
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/news/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { title, summary, content, descriptionSeo, keywordsSeo, date, isFeatured, inHomePage, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();

			const requestData = { title, summary, content, descriptionSeo, keywordsSeo, date, image, imageName, };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			return axios.post('/news', formData, configs).then(({ data }) => data);
		},
		
		update({ commit }, { id, title, summary, content, descriptionSeo, keywordsSeo, date, isFeatured, inHomePage, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { title, summary, content, descriptionSeo, keywordsSeo, date, image, imageName, };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/news/${id}`, formData, configs).then(({ data }) => data);
		},

		uploadImages({ commit }, { id, images, imagesPaths, imagesNames }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/news/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.post(`/news/${id}/images/${imageId}`, { _method: 'DELETE' })
		},

		changeOrder({ commit }, { id, order }) {
			return axios.post(`/news/${id}/change-order`, { num: order, _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/news/${id}`, { _method: 'DELETE' })
		},

		restore({ commit }, { id }) {
			return axios.post(`/news/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_NEWS](state, news) {
			state.news = news;
		}
	},

	getters: {
		getNewsById: state => id => state.news.find(c => c.id === id),
		getNewsBySlug: state => slug => state.news.find(c => c.slug === slug),
	}
}