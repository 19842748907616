<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="customFilters"
			:ids="deleteItemId"
			@on-delete="fetchCustomFilters"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{currentTypeTranslate}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="customFilters"
			:ids="restoreItemId"
			@on-restore="fetchCustomFilters"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{currentTypeTranslate}}
		</bee-restore-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="500"
			:retain-focus="false"
			:persistent="dialogLoading"
			attach
		>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{currentTypeTranslate}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:disable-non-selected="loading || dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row class="flex-column mt-0">
							<v-col class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- image -->
							<v-col v-if="useUploadCustomFilterImage" class="pt-0">
								<bee-drop-img
									v-model="image"
									dashed
									border-width="2"
									:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
									radius="11"
									height="180px"
									blurable
									clearable
									disable-file-picker
									:disabled="dialogLoading"
									@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
										image = url;
										fileManagerFiles.mainImage = file;
									})"
								>
									<template v-slot:drag-text>
										<span class="text-body-1 font-weight-medium">
											{{$t('drag-text')}}
										</span>
										<span class="text-caption mb-0">
											{{$t('supported-text')}}
										</span>
									</template>
								</bee-drop-img>
							</v-col>

							<v-col class="py-0">
								{{$t('inputs.description')}}
								<text-editor
									v-model="description"
									:disabled="dialogLoading"
									:height="300"
									mini-tools
									floating-toolbar
									class="rounded-small"
								></text-editor>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submiy"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('add') + ' ' + currentTypeTranslate}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredCustomFilters"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image ? item.image.url : require('@/assets/placeholder-image.png')"
					:height="45"
					:width="45"
					lazy-src="@/assets/blurred.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="deleteDialog = true; deleteItemId = item.id"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</template>
				<tooltip v-else :text="$t('tooltip.restore')">
					<v-btn
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreItemId = item.id; restoreDialog = true"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</tooltip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { useUploadCustomFilterImage } from '@/configs/global.config';
import langsDraft from '@/mixins/langs-draft';
import { customFilters } from '@/configs/custom-filters.config';

export default {
	name: 'CustomFilters',
	mixins: [langsDraft],

	data: () => ({
		dialog: false,
		deleteDialog: false,

		editItemId: null,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		// filters
		search: null,
		isFiltered: null,

		// configs
		multiLangInputs,
		useUploadCustomFilterImage,

		// helpers
		loading: false,
		dialogLoading: false,
		isSubmitted: false,
		lang: defaultLang,
		rules,

		// file manager
		fileManagerFiles: {
			mainImage: {}
		},

		// draft for languages 
		draft: {},
		draftNames: ['name', 'description'],

		// data
		name: null,
		description: null,
		image: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchCustomFilter();
					} else {
						const item = this.getFilterById(this.editItemId);
						this.name = item.name;
						this.description = item.description;
						this.image = item.image?.url ?? null;
					}
				}
			} else {
				this.$refs.form.reset();
				this.resetDraft();
				this.image = null;
				this.isSubmitted = false;
				this.fileManagerFiles.mainImage = {};
				this.editItemId = null;
				this.lang = defaultLang;
			}
		}
	},

	computed: {
		...mapState({
			customFilters: state => state.customFilters.customFilters	
		}),

		...mapGetters({
			fetchCustomFilterById: 'customFilters/fetchCustomFilterById'
		}),

		currentType() {
			return Number(this.$route.params.type);
		},
		currentTypeName() {
			return customFilters.find(c => c.type === this.currentType).typeName;
		},
		currentTypeTranslate() {
			return customFilters.find(c => c.type === this.currentType).translates[this.$i18n.locale].singular;
		},

		filteredCustomFilters() {
			return this.filterByName(this.isFiltered, this.filters, this.search);
		},

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.description'), value: 'description', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (useUploadCustomFilterImage) {
				headers.splice(0, 0, { text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },)
			}

			return headers;
		}
	},

	methods: {
		async submit() {
			this.isSubmitted = true;
			const image = useUploadCustomFilterImage ? this.image : true;
			if (this.$refs.form.validate() && image) {
				this.dialogLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ name, description, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';

					return this.$store.dispatch(`customFilters/${actionName}`, {
						id: id,
						name: name,
						description: description,
						type: this.currentType,
						typeName: this.currentTypeName,
						image:
							isDefaultOne && typeof this.image !== 'string'
								? this.image
								: null,
						imagePath:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.name
								: null,
						acceptLanguage: lang
					});
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];
					const description = this.draft.description[lang];

					const dataDueLang = { name, description, lang };

					if (name) {
						if (index === 0) {
							await submitRequest(index === 0, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.currentTypeTranslate })
							: this.$t('$-is-created-successfully', { name: this.currentTypeTranslate });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.fetchCustomFilters();
					this.dialog = false;
				}).catch(() => {
					this.editItemId = itemData.id;
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		fetchCustomFilters() {
			this.loading = true;
			this.$store.dispatch('customFilters/fetchAll', { type: this.currentType })
				.finally(() => {
					this.loading = false;
				})
		},

		fetchCustomFilter() {
			this.dialogLoading = true;
			const slug = this.getFilterById(this.editItemId).slug;
			
			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('customFilters/fetchBySlug', { slug, acceptLanguage: c.key })
						.then(data => {
							if (index === 0) this.image = data.image?.url ?? null;
							this.draft.name[c.key] = data.name;
							this.draft.description[c.key] = data.description;
						})
				);
			});
			
			Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang];
				this.description = this.draft.description[this.lang];
			}).finally(() => {
				this.dialogLoading = false;
			})
		}
	},

	created() {
		this.fetchCustomFilters();
	}
}
</script>

<style>

</style>