<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="videos"
			:ids="deleteItemId"
			@on-delete="fetchVideos"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Videos.the-video')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="450"
			:persistent="dialogLoading || isUploading"
		>
			<v-form ref="form" @submit.prevent="submit" :disabled="isUploading || dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Videos.video')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row dense class="flex-column">
							<v-col class="d-flex">
								<!-- title -->
								<v-text-field
									v-model="title"
									:label="$t('inputs.title')"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.required"
								></v-text-field>

								<!-- in home -->
								<v-switch
									v-if="useInHomePage"
									v-model="inHomePage"
									:label="$t('inputs.is-in-home-page')"
									hide-details
									dense
									class="mt-2 ms-3 pt-0"
								></v-switch>
							</v-col>
							
							<!-- description -->
							<v-col>
								<v-textarea
									v-model="description"
									:label="$t('inputs.description')"
									outlined
									dense
									rows="2"
									auto-grow
									no-resize
									hide-details
									class="rounded-small"
									:rules="rules.required"
								></v-textarea>
							</v-col>

							<!-- attachment type -->
							<v-col v-if="!isUploading" cols="12">
								<v-radio-group
									v-model="attachmentType"
									mandatory
									row
									dense
									hide-details
									class="ms-1 mt-0 pt-0"
									@change="previewUrl = null; video = null; videoUrl = null"
								>
									<template v-slot:label>
										<span class="text-body-1">
											{{$t('Videos.attachment-type.title')}}
										</span>
									</template>

									<v-radio
										:value="0"
										:label="$t('Videos.attachment-type.uploading-video')"
										class="my-0"
									/>
									<v-radio
										:value="1"
										:label="$t('Videos.attachment-type.link')"
										class="my-0"
									/>
								</v-radio-group>
							</v-col>

							<!-- video -->
							<v-col>
								<!-- video url -->
								<v-text-field
									v-if="attachmentType === 1"
									v-model="videoUrl"
									:label="$t('inputs.link')"
									outlined
									dense
									class="rounded-small"
									hide-details
									@change="previewUrl = videoUrl"
									@paste="previewUrl = $event.clipboardData.getData('text')"
									:rules="rules.required"
								></v-text-field>

								<!-- upload video -->
								<div
									v-else-if="!isUploading && attachmentType === 0"
									@dragover.prevent
									@drop.prevent="setVideo"
								>
									<v-file-input
										v-model="video"
										:label="$t('inputs.video')"
										outlined
										dense
										accept=".mp4"
										class="rounded-small"
										truncate-length="35"
										hide-details
										:clearable="false"
										@change="changeVideo()"
										:suffix="totalSize + ' MB'"
										:rules="!editItemId ? rules.video : []"
									></v-file-input>
								</div>
								
								<!-- progress -->
								<div v-else class="d-flex align-center">
									<v-progress-circular
										:value="uploadedPercent"
										size="55"
										color="primary"
										class="ms-2"
										:class="{ 'loop-progress': isUploading }"
									>
										{{uploadedPercent}}%
									</v-progress-circular>
									<p class="ms-4 mb-0">
										<span class="d-block">
											{{videoName}}
										</span>
										<span class="d-block">
											{{`${uploadedSize} / ${totalSize} MB`}}
										</span>
									</p>
								</div>

								<!-- video preview -->
								<template v-if="!isUploading && !dialogLoading && previewUrl">
									<video-player :src="previewUrl" class="mb-3"/>
								</template>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="!isUploading && dialogLoading"
							:disabled="isUploading || dialogLoading"
						>{{$t('save')}}</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="isUploading || dialogLoading"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		
		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Videos.add-new-video')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredVideos"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- video -->
			<template v-slot:item.video="{ item }">
				<video-player
					:src="item.video"
					class="my-2"
				/>
			</template>

			<!-- description -->
			<template v-slot:item.description="{ item }">
				{{item.content | isAvailable | ellipsis(130)}}
			</template>

			<!-- in home -->
			<template v-slot:item.inHomePage="{ item }">
				<v-icon v-if="item.inHomePage">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{item}" >
				<div class="d-flex justify-center">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>

					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { useInHomePage } from '@/configs/global.config';
import langsDraft from '@/mixins/langs-draft';

export default {
	name: 'Videos',
	mixins: [langsDraft],

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		dialog: false,
		editItemId: null,

		// configs
		multiLangInputs,
		useInHomePage,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		dialogLoading: false,
		isUploading: false,
		isSubmitted: false,
		lang: defaultLang,
		rules,
		
		// video uploading
		attachmentType: 0,
		uploadedSize: 0,
		totalSize: 0,
		uploadedPercent: 0,
		previewUrl: null,
		// 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'

		// draft for languages 
		draft: {},
		draftNames: ['title', 'description'],

		// data
		title: null,
		inHomePage: null,
		description: null,
		videoUrl: null,
		video: null
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchVideo();
					} else {
						const item = this.getVideoById(this.editItemId);
						this.title = item.title;
						this.inHomePage = item.inHomePage;
						this.description = item.content;
					}
				}
			} else {
				this.$refs.form.reset();
				this.editItemId = null;
				this.lang = defaultLang;
				this.attachmentType = 0;

				this.uploadedSize = 0;
				this.totalSize = 0;
				this.uploadedPercent = 0;
				this.previewUrl = null;
			}
		}
	},

	computed: {
		...mapState({
			videos: state => state.videos.videos.filter(c => c.video)
		}),
		...mapGetters({
			getVideoById: 'videos/getVideoById'
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.video'), value: 'video', align: 'center', sortable: false, width: 420 },
				{ text: this.$t('headers.title'), value: 'title', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.description'), value: 'description', align: 'center', width: useInHomePage ? 440 : 500 },
				{ text: this.$t('headers.in-home'), value: 'inHomePage', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (!useInHomePage) {
				headers.splice(headers.length - 2, 1);
			}

			return headers;
		},

		filteredVideos() {
			return this.filterByName(this.isFiltered, this.videos, this.search);
		},

		videoName() {
			const splittedStr = this.video.name.split('.');
			const videoExtension = splittedStr[splittedStr.length - 1].toLowerCase();
			splittedStr.splice(splittedStr.length - 1, 1);
			const name = splittedStr.join();
			return (name.length > 65 ? name.slice(0, 65) + '...' : name) + videoExtension
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.title[lang.key] && this.draft.description[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},
	methods: {
		async submit() {
			if (this.$refs.form.validate()) {
				if (this.video) {
					this.isUploading = true;
				} else {
					this.dialogLoading = true;
				}
				const progressHandler = (progressEvent) => {
					const uploadedBytes = progressEvent.loaded;
					const totalBytes = progressEvent.total;
					this.uploadedSize = (progressEvent.loaded / 1024 ** 2).toFixed(2);
					this.uploadedPercent = Math.trunc((uploadedBytes / totalBytes) * 100);
				}

				const promises = [];
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ title, description, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`videos/${actionName}`, {
						id: id,
						title: title,
						inHomePage: this.inHomePage,
						content: description,
						video: isDefaultOne ? this.video : null,
						progressHandler: isDefaultOne ? progressHandler : () => {},
						acceptLanguage: lang
					})
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const title = this.draft.title[lang];
					const description = this.draft.description[lang];

					const dataDueLang = { title, description, lang };

					if (title && description) {
						if (!itemData) {
							await submitRequest(!itemData, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Videos.video') })
							: this.$t('$-is-created-successfully', { name: this.$t('Videos.video') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.fetchVideos();
					this.dialog = false;
				}).catch(() => {
					this.editItemId = itemData.id;
				}).finally(() => {
					this.isUploading = false;
					this.dialogLoading = false;
				})
			}
		},

		fetchVideo() {
			this.dialogLoading = true;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('videos/fetchById', { id: this.editItemId, acceptLanguage: c.key })
						.then((data) => {
							if (index === 0) {
								this.inHomePage = data.inHomePage;
							}
							this.draft.title[c.key] = data.title;
							this.draft.description[c.key] = data.content;
							this.attachmentType = 1;
							this.videoUrl = data.video;
							this.previewUrl = data.video;
						})
				);
			});

			Promise.all(promises).then(() => {
				this.title = this.draft.title[this.lang];
				this.description = this.draft.description[this.lang];
			}).finally(() => {
				this.dialogLoading = false;
			})
		},

		fetchVideos() {
			this.loading = true;
			this.$store.dispatch('videos/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},
		
		setVideo(e) {
			this.video = e.dataTransfer.files[0];
			this.changeVideo();
		},

		changeVideo() {
			if (this.video) {
				this.totalSize = (this.video.size / 1024 ** 2).toFixed(2);
				this.previewUrl = URL.createObjectURL(this.video);
			} else {
				this.totalSize = 0;
			}
		}
	},

	created() {
		this.fetchVideos();
	},

	mounted() {
		window.onbeforeunload = (event) => {
			if (this.isUploading) {
				event.preventDefault();
				event.returnValue = '';
				return this.$t('messages.uploading')
			}
		}
	},

	beforeDestroy() {
		window.onbeforeunload = null;
	}
};
</script>

<style lang="scss"></style>