import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		loyaltyPoints: [],
		loyaltyPointsSummery: {},
	},

	actions: {
		fetchUserPoints({ commit }, { userId, acceptLanguage }) {
			return axios.get(`/users/get-transaction/${userId}`).then(({ data }) => {
				commit(types.STORE_LOYALTY_POINTS, data);
				return data;
			})
		},

		fetchUserPointsCount({ commit }, { userId, acceptLanguage }) {
			return axios.get(`/users/count-transaction/${userId}`).then(({ data }) => {
				commit(types.STORE_LOYALTY_POINTS_SUMMARY, data);
				return data;
			})
		},

		reset({ commit }) {
			commit(types.STORE_LOYALTY_POINTS_RESET);
		}
	},

	mutations: {
		[types.STORE_LOYALTY_POINTS](state, loyaltyPoints) {
			state.loyaltyPoints = loyaltyPoints;
		},
		[types.STORE_LOYALTY_POINTS_SUMMARY](state, loyaltyPointsSummery) {
			state.loyaltyPointsSummery.pointsBalance = loyaltyPointsSummery.pointBalance;
			state.loyaltyPointsSummery.expireIn = loyaltyPointsSummery.expireIn;
			state.loyaltyPointsSummery.totalEarnedPoints = loyaltyPointsSummery.totalEarnedPoint;
			state.loyaltyPointsSummery.totalSpentPoints = loyaltyPointsSummery.totalSpentPoint;
		},
		[types.STORE_LOYALTY_POINTS_RESET](state) {
			state.loyaltyPoints = [];
			state.loyaltyPointsSummery = {};
		}
	}
}