import { videos } from '@/configs/translates.config'

export default {
	'add-new-video': `add new ${videos.en.singular}`,
	video: videos.en.singular,
	'the-video': `the ${videos.en.singular}`,

	'attachment-type': {
		title: `Set ${videos.en.singular} by:`,
		'uploading-video': 'Uploading video',
		link: 'link'
	}
}