export const useFileUpload = true;
export const useProductCode = true;
export const useInHomePage = true;
export const useCategoryTree = false;
export const useCategoryAttributes = true;
export const useUploadCategoryImage = true;
export const useUploadFilterImage = true;
export const useOrderingItems = true;
export const useOneBranch = true;
export const useUploadBranchImage = true;
export const useUploadCompanyValuesImage = true;
export const useUploadCompanyHistoriesImage = true;
export const useUploadMoreImages = {
	products: true,
	news: true,
	services: true,
	customServices: true,
	branches: true,
	companyValues: true,
	companyHistories: true
};
export const useFeatured = {
	sets: true,
	categories: true
};
export const useRestore = {
	sets: true,
	categories: true,
	filters: true,
	keywords: true,
	products: true,
	news: true,
	services: true,
	customServices: true,
	plans: true,
	branches: true
};
export const branchInfo = {
	countryCode: true,
	moneyCode: true,
	address: true,
	openingHours: true,
	location: true
};
export const contacts = {
	email: true,
	phone: true,
	mobile: true,
	whatsapp: true,
	telegram: true,
	telegramChannel: true,
	facebook: true,
	instagram: true,
	twitter: true,
	youtube: true,
	linkedIn: true,
	pinterest: true
};
export const aboutUs = {
	showAbout: true,
	showMission: true,
	showVision: true,
	showPolicy: true,
	showQualityPolicy: true
};
