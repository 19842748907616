<template>
	<div id="gjs" :class="{ 'gjs-simple': simple }"></div>
</template>

<script>
import grapesjsBlocksBasic from 'grapesjs-blocks-basic'
import grapesjsPresetWebpage from 'grapesjs-preset-webpage'
// import grapesjsPresetNewsletter from 'grapesjs-preset-newsletter'
import grapesjsStyleGradient from 'grapesjs-style-gradient'
import grapesjsCustomCode from 'grapesjs-custom-code'
import grapesjsTabs from 'grapesjs-tabs'
import grapesjsBlocksFlexbox from 'grapesjs-blocks-flexbox'
import grapesjsTouch from 'grapesjs-touch'
import grapesjsStyleFilter from 'grapesjs-style-filter'
import grapesjsStyleBg from 'grapesjs-style-bg'
import 'grapesjs/dist/css/grapes.min.css'
const grapesjs = require('grapesjs');

export default {
	name: 'TemplateBuilder',

	props: {
		initValue: { type: String, default: '' },
		simple: { type: Boolean, default: false },
		height: { type: String, default: '400px' },
		panelWidth: { type: String, default: '20%' },
		useCustomFileManager: { type: Boolean, default: false }
	},
	
	data () {
		return {
			editor: {}
		}
	},

	methods: {
		importCode(val) {
			this.editor.DomComponents.clear();
			this.editor.setComponents(val);
		},
		
		getCodes() {
			const css = this.editor.getCss({ onlyMatched: true });
			const finalCss = css.replace(/\s*(body|\*)\s*\{.*?\}/g, '').trim();

			let code = this.editor.getWrapper().getInnerHTML();
			code += finalCss ? `<style>${finalCss}</style>` : '';
			
			return code;
		},

		getHtmlCss(code) {
			const html = code.replace(/<style>.*?<\/style>/g, '');
			const css = [];
			if (code) {
				const matched = (code || '').match(/<style>.*?<\/style>/g);
				if (matched) {
					matched.forEach(style => {
						css.push(style.slice(7, -8));
					});
				}
			}
			return { html, css: css.join(' ') };
		},

		// assets manager
		closeAssetManager() {
			this.editor.AssetManager.close();
		}
	},

	mounted() {
		// this.editor
		this.editor = grapesjs.init({
			container: '#gjs',
			height: this.height,
			width: '100%',
			fromElement: false,
			components:
				this.initValue && this.initValue !== 'null'
					? this.initValue
					: `<h1 style="text-align: center">
						Start building <br>
						Try to drag and drop some blocks <br>
						from the sidebar here 👉🏻
					</h1>`,
			colorPicker: {
				showAlpha: true,
				showPalette: true,
				preferredFormat: 'hex',
				// appendTo: '#gjs',
				palette: ['#f44336', '#e91e63', '#9c27b0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B', '#9E9E9E', '#000000', '#ffffff'],
				// offset: { top: '-215px', left: '-51px' }
			},
			plugins: [
				editor => {
					grapesjsBlocksBasic(editor, {
						blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image']
					});
					/* grapesjsPresetNewsletter(editor, {
						blocks: ['divider', 'grid-items', 'list-items']
					}); */
					grapesjsPresetWebpage(editor, {
						blocks: ['link-block', 'quote', 'text-basic'],
					});
				},
				grapesjsStyleGradient,
				grapesjsCustomCode,
				editor => {
					grapesjsTabs(editor, { tabsBlock: { category: 'Extra' } })
					
					if (!this.simple) grapesjsBlocksFlexbox(editor, { flexboxBlock: { category: 'Extra' } })
				},
				grapesjsTouch,
				grapesjsStyleFilter,
				grapesjsStyleBg,
			],
			storageManager: false,
			assetManager: {
				custom:
					this.useCustomFileManager
						? {
							open: options => {
								this.$eventBus.$emit('file-manager', { images: true }, (url, file) => {
									options.select(url);
									options.close();
								})
							},
							close: options => {}
						}
						: false
			}
		});
		
		/* inits */
		// init visibility
		this.editor.runCommand('sw-visibility');
		this.editor.Panels.getButton('options', 'sw-visibility').active = true;

		// save and load saved
		this.editor.Panels.addButton('options', {
			id: 'save',
			className: 'gjs-custom-icon fa fa-cloud-upload',
			attributes: { title: 'Save page' },
			command: 'save'
		});
		this.editor.Panels.addButton('options', {
			id: 'load-save',
			className: 'gjs-custom-icon fa fa-cloud-download',
			attributes: { title: 'Load saved page' },
			command: 'load-save'
		});

		this.editor.Commands.add('save', () => {
			localStorage.setItem('gjs-comps', this.getCodes());
			this.$eventBus.$emit('show-snackbar', false, this.$t('messages.your-design-is-saved'))
		});
		this.editor.Commands.add('load-save', () => { this.importCode(localStorage.getItem('gjs-comps')) });

		// set simple styles
		if (this.simple) {
			this.editor.Panels.removePanel('views');
			this.editor.Panels.removeButton('options', 'gjs-open-import-webpage');
		}

		// remove unneeded buttons
		this.editor.Panels.removeButton('options', 'export-template');

		// plugins inits
		this.editor.StyleManager.addProperty('extra', { extend: 'filter' });
		this.editor.StyleManager.addProperty('extra', { extend: 'filter', property: 'backdrop-filter' });

		this.editor.StyleManager.addProperty('decorations', {
			name: 'Gradient',
			property: 'background-image',
			type: 'gradient',
			defaults: 'none'
		});

		/* custom spacing spot */
		const customSpacingEl = document.createElement('div');
		customSpacingEl.style.position = 'absolute';
		const resetCustomSpacingStyles = () => {
			customSpacingEl.style.top = null;
			customSpacingEl.style.right = null;
			customSpacingEl.style.bottom = null;
			customSpacingEl.style.left = null;
			customSpacingEl.style.width = null;
			customSpacingEl.style.height = null;
			customSpacingEl.style.boxShadow = null;
		}
		
		this.editor.on('component:hover', (component) => {
			// Get the component coordinates
			const { top, right, bottom, left, width, height } = component.getEl().getBoundingClientRect();
			
			// Remove all spots related to our custom type
			this.editor.Canvas.removeSpots({ type: 'custom-spacing' });

			if (component !== this.editor.getSelected()) {
				this.editor.Canvas.addSpot({ type: 'custom-spacing', component });
				const el = component.getEl();
				const styles = window.getComputedStyle(el);

				customSpacingEl.style.top = `${top}px`;
				customSpacingEl.style.right = `${right}px`;
				customSpacingEl.style.bottom = `${bottom}px`;
				customSpacingEl.style.left = `${left}px`;
				customSpacingEl.style.width = `${width}px`;
				customSpacingEl.style.height = `${height}px`;

				// setting margin and padding layers
				customSpacingEl.style.boxShadow = `
					0px -${styles.marginTop} 0px 0px #E57E2430,
					${styles.marginRight} 0px 0px 0px #E57E2430, 
					0px ${styles.marginBottom} 0px 0px #E57E2430,
					-${styles.marginLeft} 0px 0px 0px #E57E2430,
					inset 0px ${styles.paddingTop} 0px 0px #74BF7C30,
					inset -${styles.paddingRight} 0px 0px 0px #74BF7C30, 
					inset 0px -${styles.paddingBottom} 0px 0px #74BF7C30,
					inset ${styles.paddingLeft} 0px 0px 0px #74BF7C30
				`;
			} else {
				resetCustomSpacingStyles();
			}
		});
		this.editor.Canvas.getElement().addEventListener('mouseleave', resetCustomSpacingStyles)
		this.editor.onReady(() => {
			this.editor.Canvas.getSpotsEl().appendChild(customSpacingEl);
		});

		/* set styles */
		document.body.style.setProperty('--gjs-left-width', this.panelWidth);

		/* // TODO uncomment for translates
		this.editor.I18n.addMessages({
			en: {
				styleManager: {
					properties: {
						'background-repeat': 'Repeat',
						'background-position': 'Position',
						'background-attachment': 'Attachment',
						'background-size': 'Size',
					}
				},
			}
		});
		*/
	}
}
</script>

<style lang="scss">
/* Theming */
#gjs {
	/* border: 1px solid #444;
	border-radius: 11px;
	overflow: hidden; */

	/* Primary color for the background */
	.gjs-one-bg {
		background-color: #084566;
	}
	/* Secondary color for the text color */
	.gjs-two-color {
		color: rgba(255, 255, 255, 0.7);
	}
	/* Tertiary color for the background */
	.gjs-three-bg {
		background-color: #1389c9aa;
		color: white;
	}
	/* Quaternary color for the text color */
	.gjs-four-color,
	.gjs-four-color-h:hover {
		color: white;
	}

	// customize
	.gjs-sm-sector .gjs-sm-gradient {
		width: 100%;
	}
	.grp-wrapper {
		background-repeat: repeat;
	}

	.gjs-custom-icon {
		font-size: 20px;
	}

	&.gjs-simple .gjs-pn-views-container {
		padding-top: 0;
	}
}
</style>