import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		offers: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/offers').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.offers ? !c.deletedAt : true);
				commit(types.STORE_OFFERS, storeData);
				return storeData;
			});
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/offers/${slug}`, configs).then(({ data }) => data);
		},
		
		create({ commit }, { name, fromDate, toDate, productIds, fixedValue, percentValue, withUpSelling, isActive, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, fromDate, toDate, fixedValue, percentValue };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			});

			productIds.forEach(id => { formData.append('productIds[]', id) })
			formData.append('withUpSelling', withUpSelling ? 1 : 0);
			formData.append('isActive', isActive ? 1 : 0);

			return axios.post('/offers', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, name, fromDate, toDate, productIds, fixedValue, percentValue, withUpSelling, isActive, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, fromDate, toDate, fixedValue, percentValue };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			});

			productIds.forEach(id => {
				formData.append('productIds[]', id);
			})
			formData.append('withUpSelling', withUpSelling ? 1 : 0);
			formData.append('isActive', isActive ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/offers/${id}`, formData, configs).then(({ data }) => data);
		},

		changeState({ commit }, { id }) {
			return axios.post(`/offers/${id}/change-status`, { _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/offers/${id}`, { _method: 'DELETE' });
		},

		restore({ commit }, { id }) {
			return axios.post(`/offers/${id}`, { _method: 'PATCH' });
		},
	},

	mutations: {
		[types.STORE_OFFERS](state, offers) {
			state.offers = offers;
		}
	},

	getters: {
		getOfferById: state => id => state.offers.find(c => c.id === id),
		getOfferBySlug: state => slug => state.offers.find(c => c.slug === slug),
	}
}