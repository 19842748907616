import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		categories: [],
		hierarchyCategories: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/categories').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.categories ? !c.deletedAt : true);
				commit(types.STORE_CATEGORIES, storeData);
				return storeData;
			})
		},
		fetchAllAsHierarchy({ commit }) {
			return axios.get('/categories/hierarchy', {
				params: { withDeleted: useRestore.categories ? 'True' : 'False' }
			}).then(({ data }) => {
				commit(types.STORE_HIERARCHY_CATEGORIES, data);
				return data;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/categories/${slug}`, configs).then(({ data }) => data)
		},

		create({ commit }, { name, summary, isFeatured, description, setIds, categoryId, attributes, descriptionSeo, keywordsSeo, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, description, categoryId, descriptionSeo, keywordsSeo, image, imageName };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			})
			setIds.forEach(id => { formData.append('setIds[]', id); });

			if (Array.isArray(attributes)) {
				attributes.forEach(({ name, image, imagePath, imageName }, index) => {
					const data = { name, image, imageName };
					Object.keys(data).forEach(key => {
						formData.append(`attributes[${index}][${key}]`, data[key] || '');
					});
					formData.append(
						`attributes[${index}][imagePath]`,
						imagePath ? imagePath.slice(imagePath.indexOf('/', 1)) : ''
					);
				});
			}

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);

			return axios.post('/categories', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, name, summary, isFeatured, description, setIds, categoryId, attributes, descriptionSeo, keywordsSeo, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, description, categoryId, descriptionSeo, keywordsSeo, image, imageName };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			})
			setIds.forEach(id => { formData.append('setIds[]', id); });
			
			if (Array.isArray(attributes)) {
				attributes.forEach(({ name, image, imagePath, imageName }, index) => {
					const data = { name, image, imageName };
					Object.keys(data).forEach(key => {
						formData.append(`attributes[${index}][${key}]`, data[key] || '');
					});
					formData.append(
						`attributes[${index}][imagePath]`,
						imagePath ? imagePath.slice(imagePath.indexOf('/', 1)) : ''
					);
				});
			}
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('_method', 'PUT');
			return axios.post(`/categories/${id}`, formData, configs).then(({ data }) => data);
		},

		delete({ commit }, { id }) {
			return axios.post(`/categories/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/categories/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_CATEGORIES](state, categories) {
			state.categories = categories;
		},
		[types.STORE_HIERARCHY_CATEGORIES](state, hierarchyCategories) {
			state.hierarchyCategories = hierarchyCategories;
		}
	},

	getters: {
		getCategoryById: state => id => state.categories.find(c => c.id === id),
		getCategoryBySlug: state => slug => state.categories.find(c => c.slug === slug),
	}
}