import { loyaltyPoints, products } from '@/configs/translates.config'
export default {
	statistics: {
		visitors: 'visitors',
		users: 'users',
		'disabled-products': 'disabled products',
		revenue: 'revenue',
		'loyalty-points': loyaltyPoints.en.plural
	},

	descriptions: {
		visitors: 'Visitors right now',
		today: 'Login today',
		'out-of-$-products': `Out of {number} ${products.en.plural}`,
		orders: 'Orders'
	},

	views: 'views',
	count: 'count',

	'top-products-views': `top {count} ${products.en.plural} views`,

	'order-status': {
		title: 'Order status',
		approved: 'Approved',
		'in-preparation': 'In preparation',
		shipping: 'Shipping',
		completed: 'Completed',
		unpaid: 'Unpaid'
	},

	'recently-added-products': `Recently added ${products.en.plural}`,
	'popular-products': `Popular ${products.en.plural}`,
	'top-sold-products': `Top sold ${products.en.plural}`
}