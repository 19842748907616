<template>
	<v-combobox
		v-model="selectedItems"
		:search-input.sync="searchInput"
		:items="selectedItems"
		multiple
		chips
		:small-chips="smallChips"
		:deletable-chips="deletableChips"
		:hide-details="hideDetails"
		hide-no-data
		hide-selected
		append-icon
		:label="label"
		:hint="hint"
		:outlined="outlined"
		:dense="dense"
		:loading="loading"
		:disabled="disabled"
		@update:search-input="searchInput = (searchInput || '').trim()"
		@keydown.backspace.self="!searchInput ? selectedItems.splice(selectedItems.length - 1, 1) : null"
		@keydown.ctrl.c="copyToClipboard"
		@keydown="$emit('keydown', $event)"
		@keypress.space="buildChips"
		@keypress="$emit('keypress', $event)"
		@change="$emit('change', $event);"
		@blur="buildChips"
	></v-combobox>
</template>

<script>
export default {
	name: 'VChipsInput',
	
	props: {
		hint: { type: String },
		rules: { type: Array, default: () => [] },
		'hide-details': { type: [Boolean, String], default: true },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		dense: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false },
		'return-array': { type: Boolean, default: false },
		'prevent-repeat': { type: Boolean, default: true },
		'small-chips': { type: Boolean, default: true },
		'deletable-chips': { type: Boolean, default: true },
		label: { type: String },
		value: { type: [String, Array] },
	},

	data() {
		return {
			searchInput: '',
			selectedItems: [],
		}
	},

	watch: {
		value: {
			handler(newVal) {
				if (newVal) {
					this.selectedItems = !this.returnArray ? newVal.split(', ') : newVal;
				} else {
					this.selectedItems = [];
				};
			},
			immediate: true,
		},
		selectedItems(newVal) {
			this.$emit('input', !this.returnArray ? newVal.join(', ') : newVal);
			this.$emit('change');
		}
	},

	methods: {
		copyToClipboard() {
			window.navigator.clipboard.writeText(this.selectedItems.join(', '));
		},

		buildChips() {
			if (this.searchInput && (!this.preventRepeat || !this.selectedItems.includes(this.searchInput))) {
				this.selectedItems.push(this.searchInput);
			}
			this.searchInput = null;
		}
	}
}
</script>