<template>
	<div></div>
</template>

<script>
export default {
	name: 'Category'
};
</script>

<style>
</style>