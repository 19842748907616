export default {
	'user-info': '',
	name: '',
	address: '',
	phone: '',
	email: '',
	date: '',
	'payment-info': '',
	total: '',
	'payment-type': '',
	cart: '',
}