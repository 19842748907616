<template>
	<v-menu
		v-model="menu"
		offset-y
		:disabled="disabled"
		:close-on-content-click="false"
		:max-height="300"
		@input="!$event ? searchInput = null : null"
	>
		<template v-slot:activator="{ attr, on }">
			<v-autocomplete
				v-model="localValue"
				:items="items"
				:item-text="itemText"
				:item-value="itemValue"
				:label="label"
				:search-input.sync="searchInput"
				multiple
				:menu-props="{ disabled: true }"
				:dense="dense"
				:outlined="outlined"
				:chips="chips"
				:smallChips="smallChips"
				:deletableChips="deletableChips"
				:hideDetails="hideDetails"
				:loading="loading"
				:disabled="disabled"
				:rules="rules"
				:class="inputClass"
				@click="menu = true; $emit('click')"
				@focus="menu = true; $emit('focus')"
				@blur="$emit('blur')"
				v-bind="attr"
				v-on="on"
			/>
		</template>

		<v-card class="py-2">
			<v-treeview
				v-model="localValue"
				selectable
				selected-color="primary"
				:selection-type="selectionType"
				expand-icon="mdi-chevron-down"
				open-all
				open-on-click
				:items="treeItems"
				:item-text="itemText"
				:item-key="itemValue"
				:search="searchInput"
				:filter="(item, search, textKey) => item[textKey].indexOf(search) > -1"
				:dense="dense"
				@input="searchInput = null; $emit('change', $event)"
			/>
			<p
				v-if="searchInput && items.findIndex(c => c[itemText].includes(searchInput)) === -1"
				class="my-2 px-4"
			>
				{{$vuetify.lang.locales[$vuetify.lang.current].noDataText}}
			</p>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	name: 'VTreeAutocomplete',

	props: {
		label: { type: String },
		items: { type: Array, default: () => [] },
		treeItems: { type: Array, default: () => [] },
		itemText: { type: String, default: 'name' },
		itemValue: { type: String, default: 'id' },
		selectionType: { type: String, default: 'independent' },
		chips: { type: Boolean, default: false },
		smallChips: { type: Boolean, default: false },
		deletableChips: { type: Boolean, default: false },
		dense: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		rules: { type: Array, default: () => [] },
		value: { type: Array, default: () => [] },
		inputClass: { type: String },
	},

	data: () => ({
		menu: false,
		searchInput: null,
	}),

	computed: {
		localValue: {
			get() { return this.value },
			set(value) { return this.$emit('input', value) },
		},

		shownText() {
			return this.localValue.map(e => {
				const item = this.items.find(c => e === c.id);
				return {
					[this.itemValue]: item[this.itemValue],
					[this.itemText]: item[this.itemText],
				}
			});
		}
	}
}
</script>

<style>

</style>