import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		companyHistories: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/company-history')
				.then(({ data }) => {
					commit(types.STORE_COMPANY_HISTORIES, data);
					return	data;
				});
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/company-history/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { title, content, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { title, content, image, imageName }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			return axios.post('/company-history', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, title, content, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { title, content, image, imageName }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('_method', 'PUT');

			return axios.post(`/company-history/${id}`, formData, configs).then(({ data }) => data);
		},

		uploadImages({ commit }, { id, images, imagesPaths, imagesNames }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/company-history/${id}/add-images`, formData);
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.post(`/company-history/${id}/images/${imageId}`, { _method: 'DELETE' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/company-history/${id}`, { _method: 'DELETE' });
		},

		restore({ commit }, { id }) {
			return axios.post(`/company-history/${id}`, { _method: 'PATCH' });
		},
	},

	mutations: {
		[types.STORE_COMPANY_HISTORIES](state, companyHistories) {
			state.companyHistories = companyHistories;
		}
	},

	getters: {
		getCompanyHistoryById: state => id => state.companyHistories.find(c => c.id === id)
	}
}