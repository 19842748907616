import { loyaltyPoints, users } from '@/configs/translates.config';
export default {
	'change-settings': 'change settings',
	'points-settings': loyaltyPoints.en.plural + ' settings',
	'points-balance': loyaltyPoints.en.plural.charAt(0).toUpperCase() + loyaltyPoints.en.plural.slice(1) + ' balance',
	'expire-in': 'Expire in',
	'total-earned-points': 'Total earned ' + loyaltyPoints.en.plural,
	'total-spent-points': 'Total spent ' + loyaltyPoints.en.plural,
	'lost-points': 'Lost ' + loyaltyPoints.en.plural,

	'please-select-user': 'Please select a ' + users.en.singular,

	spent: 'Spent',
	earned: 'Earned',
}