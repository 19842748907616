import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		publishers: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/publishers').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.publishers ? !c.deletedAt : true);
				commit(types.STORE_PUBLISHERS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/publishers/${slug}`, configs).then(({ data }) => data);
		},
		create({ commit }, { name, description, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, description, image, imageName }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			return axios.post('/publishers', formData, configs).then(({ data }) => data);
		},
		update({ commit }, { id, name, description, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, description, image, imageName }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			formData.append('_method', 'PUT');

			return axios.post(`/publishers/${id}`, formData, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.post(`/publishers/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/publishers/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_PUBLISHERS](state, publishers) {
			state.publishers = publishers;
		},
	},

	getters: {
		getPublisherById: state => id => state.publishers.find(c => c.id === id),
		getPublisherBySlug: state => slug => state.publishers.find(c => c.slug === slug),
	}
}