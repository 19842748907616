import axios from 'axios';

// const sanad = window.sanad;
const sanad = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: {
		common: {
			'X-ElasticEmail-ApiKey': ''
		}
	}
});

function getPreheader(str) {
	return str ? str + '&emsp;&zwnj;'.repeat(150) : '';
}

export function sendCustomizedEmail(emails, { subject, preheader, body }) {
	return sanad.post('/emails', {
		Recipients: (emails || []).map(c => ({ Email: c })),
		Content: {
			TemplateName: 'customized_content',
			Merge: {
				preheader: getPreheader(preheader),
				content: body,
				year: new Date().getFullYear(),
			},
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: subject,
		}
	})
}