<template>
	<div>
		<!-- add/edit dialog -->
		<v-dialog v-model="dialog" max-width="350" content-class="rounded-medium">
			<v-form ref="form" @submit.prevent="submit">
				<v-card>
					<v-card-title class="justify-center text-uppercase text-h6">
						{{itemId ? $t('edit') : $t('add')}} {{$t('AffiliateMarketers.affiliate-marketer')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row dense class="ma-0">
							<!-- name -->
							<v-col cols="12">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									hide-details
									class="rounded-medium"
									:rules="rules.required"
								/>
							</v-col>
							
							<!-- discount -->
							<v-col cols="12">
								<v-text-field
									v-model.number="discount"
									:label="$t('inputs.discount')"
									outlined
									dense
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
									:append-icon="isDiscountPercent ? '%' : 'mdi-cash-multiple'"
									@click:append="isDiscountPercent = !isDiscountPercent"
								/>
							</v-col>
							
							<!-- commission-per-sale -->
							<v-col cols="12">
								<v-text-field
									v-model.number="commissionPerSale"
									:label="$t('inputs.commission-per-sale')"
									outlined
									dense
									hide-details
									class="rounded-medium"
									:rules="rules.requiredAsNumber"
									:append-icon="isCommissionPerSalePercent ? '%' : 'mdi-cash-multiple'"
									@click:append="isCommissionPerSalePercent = !isCommissionPerSalePercent"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							:loading="dialogLoading"
							:disabled="dialogLoading"
							class="rounded-small"
						>{{$t('save')}}</v-btn>
						<v-btn
							:disabled="dialogLoading"
							class="rounded-small"
							@click="dialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType=""
			:ids="deleteItemId"
			@on-delete="''"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('AffiliateMarketers.the-affiliate-marketer')}}
		</bee-delete-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('are-you-sure-you-want-to')}} {{titleState}} {{$t('AffiliateMarketers.the-affiliate-marketer')}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('AffiliateMarketers.add-new-affiliate-marketer')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredMarketers"
			:loading="loading"
			:items-per-page="15"
			dense
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.affiliateMarketersHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<template v-slot:item.actions="{ item }">
				<div class="d-flex align-center">
					<tooltip :text="$t('tooltip.preview-marketer-sales')">
						<v-btn
							color="deep-purple darken-2 white--text"
							small
							class="px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'affiliate-marketer', params: { id: item.id } }"
						><v-icon>mdi-eye</v-icon></v-btn>
					</tooltip>

					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="itemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>

					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>

					<tooltip :text="$t('tooltip.active-state')">
						<v-switch
							v-model="item.isActive"
							:ripple="false"
							color="darkGreen"
							hide-details
							class="ms-2 mt-0 pt-0"
							readonly
							@click="activeConfirmDialog(item)"
						></v-switch>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules';
export default {
	name: 'AffiliateMarketers',

	data() {
		return {
			// helpers
			loading: false,
			dialogLoading: false,
			
			dialog: false,
			itemId: null,
			
			deleteDialog: false,
			deleteItemId: null,
			
			titleState: null,
			confirmDialog: false,
			confirmId: null,
			changeStateLoading: false,

			rules,

			// filter
			search: null,
			isFiltered: false,

			// data
			name: null,
			discount: null,
			isDiscountPercent: true,
			commissionPerSale: null,
			isCommissionPerSalePercent: true,
		}
	},

	watch: {
		dialog(val) {
			if (val) {
				if (this.itemId) {
					const marketer = this.filteredMarketers.find(c => c.id === this.itemId);
					this.name = marketer.name;
					this.discount = marketer.discount;
					this.commissionPerSale = marketer.commissionPerSale;
				}
			} else {
				this.$refs.form.reset();
				this.itemId = null;
				this.isDiscountPercent = true;
				this.isCommissionPerSalePercent = true;
			}
		}
	},

	computed: {
		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.name'),
					value: 'name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					width: 140,
					noRemove: true,
				},
				{
					text: this.$t('headers.purchases'),
					value: 'purchasesCount',
					align: 'center',
				},
				{
					text: this.$t('headers.users'),
					value: 'usersCount',
					align: 'center',
				},
				{
					text: this.$t('headers.revenue'),
					value: 'totalRevenue',
					align: 'center',
				},
				{
					text: this.$t('headers.transferred-money'),
					value: 'totalTransferredMoney',
					align: 'center',
				},
				{
					text: this.$t('headers.commission-per-sale'),
					value: 'commissionPerSale',
					align: 'center',
				},
				{
					text: this.$t('headers.discount'),
					value: 'discount',
					align: 'center',
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					width: 100,
					sortable: false,
					noRemove: true,
					fixed: true,
				},
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
			}

			return headers;
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.affiliateMarketersHeaders.length) {
				headers = this.$eStorage.l.affiliateMarketersHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredMarketers() {
			return [
				{
					id: 20,
					name: 'Nehad Hamed',
					purchasesCount: 10,
					usersCount: 20,
					totalRevenue: 510,
					totalTransferredMoney: 510 * 0.5,
					commissionPerSale: 50,
					discount: 10,
					isActive: false
				}
			];
		}
	},

	methods: {
		activeConfirmDialog(item) {
			this.item = item.id;
			this.titleState = item.isActive ? this.$t('deactivate') : this.$t('activate');
			this.confirmDialog = true;
		},
	}
}
</script>

<style>

</style>