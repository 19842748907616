export default {
	pages: {
		dashboard: '',
		'file-manager': '',
		sets: '',
		categories: '',
		news: '',
		filters: '',
		keywords: '',
		products: '',
		orders: '',
		plans: '',
		albums: '',
		sliders: '',
		users: '',
		branches: '',
		subscriptions: '',
		'about-us': '',
		'company-values': '',
		seo: '',
	}
}