<template>
	<div>
		<!-- filters -->
		<v-row dense class="pb-3">
			<!-- dates -->
			<v-col md="4" sm="6" cols="12">
				<v-row dense>
					<!-- from date -->
					<v-col sm="6" cols="12">
						<v-menu
							v-model="fromDateMenu"
							transition="scale-transition"
							offset-y
							:close-on-content-click="false"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="fromDate"
									:label="$t('inputs.from-date')"
									prepend-inner-icon="mdi-calendar"
									hide-details
									outlined
									dense
									class="rounded-medium"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="fromDate"
								@input="fromDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>

					<!-- to date -->
					<v-col sm="6" cols="12">
						<v-menu
							v-model="toDateMenu"
							transition="scale-transition"
							offset-y
							:close-on-content-click="false"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="toDate"
									:label="$t('inputs.to-date')"
									prepend-inner-icon="mdi-calendar"
									readonly
									hide-details
									outlined
									dense
									class="rounded-medium"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="toDate"
								:max="new Date().toISOString().slice(0, 10)"
								@input="toDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>
			</v-col>

			<v-spacer/>

			<!-- search -->
			<v-col cols="auto">
				<v-btn
					color="primary"
					class="rounded-medium"
					:loading="loading"
					@click="fetchStatics"
				>
					{{$t('search')}}
				</v-btn>
			</v-col>
		</v-row>

		<!-- statistics -->
		<v-card :loading="loading" flat class="rounded-medium mb-2">
			<v-card-text class="py-6">
				<v-row>
					<!-- visitors -->
					<v-col :lg="!showLoyaltyPoints ? 3 : 2" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.visitors')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">{{statics.visitors || 0}}</span>
								<v-chip v-if="false" pill small color="red lighten-5 error--text mt-1">
									<span class="me-1">5%</span>
									<v-icon small>mdi-trending-down</v-icon>
								</v-chip>
							</div>
							<p v-if="false" class="text-caption mb-0">
								5 {{$t('Dashboard.descriptions.visitors')}}
							</p>
						</div>
					</v-col>
					
					<v-divider v-if="$vuetify.breakpoint.smAndUp" vertical/>
					
					<!-- users -->
					<v-col :lg="!showLoyaltyPoints ? 3 : 2" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.users')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">{{statics.usersCount || 0}}</span>
								<v-chip v-if="false" pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">26%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p v-if="false" class="text-caption mb-0">
								12 {{$t('Dashboard.descriptions.today')}}
							</p>
						</div>
					</v-col>
					
					<v-divider v-if="$vuetify.breakpoint.lgAndUp" vertical/>
					
					<!-- disabled -->
					<v-col :lg="!showLoyaltyPoints ? 3 : 2" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.disabled-products')}}
							</p>
							<div class="d-flex mt-2 mb-3">
								<span class="number-large main--text me-3">{{statics.disabledProduct || 0}}</span>
							</div>
							<p class="text-caption mb-0">
								{{$t('Dashboard.descriptions.out-of-$-products', { number: statics.productCount || 0 })}}
							</p>
						</div>
					</v-col>

					<v-divider v-if="$vuetify.breakpoint.smAndUp" vertical/>
					
					<!-- revenue -->
					<v-col lg="3" sm="6" cols="12">
						<div class="d-flex flex-column statistic-card">
							<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
								{{$t('Dashboard.statistics.revenue')}}
							</p>
							<div class="d-flex my-2">
								<span class="number-large main--text me-3">
									{{fixNumber(statics.revenue) || 0}}
								</span>
								<v-chip v-if="false" pill small color="green lighten-5 success--text mt-1">
									<span class="me-1">16%</span>
									<v-icon small>mdi-trending-up</v-icon>
								</v-chip>
							</div>
							<p v-if="false" class="text-caption mb-0">
								236 {{$t('Dashboard.descriptions.orders')}}
							</p>
						</div>
					</v-col>

					<!-- loyalty points -->
					<template v-if="showLoyaltyPoints">
						<v-divider v-if="$vuetify.breakpoint.lgAndUp" vertical/>
						<v-col lg="3" sm="6" cols="12">
							<div class="d-flex flex-column statistic-card">
								<p class="main--text text-capitalize text-body-1 font-weight-bold mb-2">
									{{$t('Dashboard.statistics.loyalty-points')}}
								</p>
								<div class="d-flex my-2">
									<span class="number-large main--text me-3">
										{{getLoyaltyPointsValue(statics.pointCount) || 0}}
									</span>
								</div>
							</div>
						</v-col>
						<v-divider v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.smOnly" vertical/>
					</template>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- order & products -->
		<v-row class="mt-0 mb-0">
			<!-- order status -->
			<v-col v-if="showOrders" md="4" cols="12">
				<v-card :loading="loading" flat class="rounded-medium">
					<v-card-title class="text-capitalize font-weight-bold main--text ps-5 pb-0">
						{{$t('Dashboard.order-status.title')}}
					</v-card-title>
					<v-card-text class="px-3 py-0">
						<apex-chart
							type="bar"
							:labels="ordersLabels.filter(c => c.active).map(c => c.name)"
							:series="ordersData"
							:max-columns-count="ordersLabels.filter(c => c.active).map(c => c.name).length"
							:height="300"
							distributed-labels
							:data-labels-offset-y="barDataLabelsOffset"
							:colors="[
								{ color: '#03a9f4', active: activeStates.approved },
								{ color: '#ff5722', active: activeStates.inPreparation },
								{ color: '#1a237e', active: activeStates.shipping },
								{ color: '#13a689', active: activeStates.completed },
								{ color: '#EF5350', active: activeStates.canceled }
							].filter(c => c.active).map(c => c.color)"
						/>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- products views -->
			<v-col :md="showOrders ? 8 : null" cols="12">
				<v-card :min-height="300" :loading="loading" flat class="rounded-medium">
					<v-card-title class="text-capitalize font-weight-bold main--text ps-5 pb-0">
						{{$t('Dashboard.top-products-views', { count: productsLabels.length })}}
					</v-card-title>
					<v-card-text class="px-1 py-0">
						<apex-chart
							type="bar"
							show-tooltip
							trim-labels
							:max-columns-count="
								showOrders && productsLabels.length > 13
									? 13
									: productsLabels.length
							"
							:labels="productsLabels"
							:series="productsViewsData"
							:data-labels-offset-y="barDataLabelsOffset"
							:height="300"
						/>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- advance statistics -->
			<v-col v-if="showAdvanceStatistics" cols="12">
				<!-- charts -->
				<v-card :min-height="300" :loading="loading" flat class="rounded-medium">
					<v-card-title class="ps-6 pb-0">
						<v-row
							dense
							align="center"
							class="justify-lg-space-between justify-center ma-0"
							style="width: 100%"
						>
							<!-- title -->
							<v-col
								lg="auto"
								cols="12"
								class="text-h6 text-capitalize font-weight-bold main--text mb-0"
							>
								{{$t('statistics.title')}}
							</v-col>

							<!-- charts types -->
							<v-col lg="7" md="12" sm="9" cols="12">
								<v-chip-group
									v-model="chartType"
									mandatory
									dense
									show-arrows
									active-class="primary"
								>
									<v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
									<v-chip v-if="statistics.averageOrderValue" small :value="0">
										{{$t('statistics.average-order-value')}}
									</v-chip>
									<v-chip v-if="statistics.cartAbandonmentRate" small :value="1">
										{{$t('statistics.cart-abandonment-rate')}}
									</v-chip>
									<v-chip v-if="statistics.conversionRate" small :value="2">
										{{$t('statistics.conversion-rate')}}
									</v-chip>
									<v-chip v-if="statistics.retentionRate" small :value="3">
										{{$t('statistics.retention-rate')}}
									</v-chip>
									<v-spacer v-if="$vuetify.breakpoint.mdAndDown"/>
								</v-chip-group>
							</v-col>

							<!-- view by -->
							<v-col lg="1" md="3" cols="6">
								<v-autocomplete
									v-model="selectedViewBy"
									:label="$t('inputs.view-type')"
									:items="viewBy"
									item-text="name"
									item-value="id"
									hide-details
									outlined
									dense
									append-icon=""
									class="mini-input rounded-medium"
									@change="customViewBy = null; buildAdvanceStaticsData()"
									@blur="
										selectedViewBy === null
										? (() => { selectedViewBy = 2 ; buildAdvanceStaticsData(); })()
										: null
									"
								></v-autocomplete>
							</v-col>
							
							<!-- custom view by -->
							<v-col v-if="selectedViewBy === 0" lg="1" md="3" cols="6">
								<v-text-field
									v-model.number="customViewBy"
									:label="$t('inputs.custom-period')"
									:items="viewBy"
									item-text="name"
									item-value="id"
									type="number"
									min="0"
									max="365"
									hide-spin-buttons
									hide-details
									outlined
									dense
									class="mini-input rounded-medium"
									@change="buildAdvanceStaticsData()"
								></v-text-field>
							</v-col>

							<!-- year -->
							<v-col lg="2" sm="3" cols="12" class="d-flex align-center">
								<v-btn
									icon
									@click="chartYear = moment(chartYear).subtract(1, 'years').format('YYYY')"
								>
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-text-field
									v-model="chartYear"
									dir="ltr"
									dense
									outlined
									hide-details
									readonly
									class="mini-input mini-input--centered rounded-medium"
								></v-text-field>
								<v-btn
									icon
									:disabled="chartYear === moment().format('YYYY')"
									@click="chartYear = moment(chartYear).add(1, 'years').format('YYYY')"
								>
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text class="px-3 py-0">
						<apex-chart
							type="line"
							:series="chartSeries"
							:max-columns-count="5"
							:height="350"
							:year="chartYear"
							datetime
							distributed-labels
							no-markers
							:show-data-labels="false"
							show-tooltip
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- recently added && popular products -->
		<v-row class="mt-0">
			<!-- recently added -->
			<v-col md="5" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.recently-added-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="recentHeaders"
							:items="loading ? [] : statics.recentProducts.slice(0, 10)"
							:loading="loading"
							:items-per-page="15"
							dense
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>
							
							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/blurred.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- popular products -->
			<v-col md="7" cols="12">
				<v-card flat class="rounded-medium">
					<v-card-title class="font-weight-bold main--text pb-3">
						{{$t('Dashboard.top-sold-products')}}
					</v-card-title>
					<v-card-text class="pb-6">
						<v-data-table
							:headers="popularHeaders"
							:items="loading ? [] : popularProducts"
							:loading="loading"
							:items-per-page="15"
							dense
							disable-sort
							hide-default-footer
						>
							<!-- no -->
							<template v-slot:item.no="{ index }">
								{{index+1}}
							</template>

							<!-- image -->
							<template v-slot:item.image="{ item }">
								<v-img
									:src="item.image.url"
									:height="45"
									:width="45"
									lazy-src="@/assets/blurred.png"
									class="rounded-medium mx-auto"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { loyaltyPoints, orders } from '@/configs/routes.config';
import { statistics } from '@/configs/global.config';
import moment from 'moment';
import { defaultLang } from '@/configs/translates.config';
import { fixNumber, getLoyaltyPointsValue } from '@/helpers/functions';
import { divideStaticsByDateStep, getCartAbandonmentRate } from '@/helpers/statistics';
import { orderState, orderStates } from '@/helpers/enums';

export default {
	name: 'Dashboard',

	data: (vm) => ({
		// helpers
		loading: false,
		moment,

		viewBy: [
			{ id: 1, name: vm.$t('view-by.daily'), value: 1 },
			{ id: 2, name: vm.$t('view-by.weekly'), value: 7 },
			{ id: 3, name: vm.$t('view-by.monthly'), value: 30 },
			{ id: 4, name: vm.$t('view-by.yearly'), value: 365 },
			{ id: 0, name: vm.$t('view-by.custom'), value: null },
		],
		
		// configs
		showOrders: orders.show,
		showAdvanceStatistics: Object.keys(statistics).some(key => statistics[key]),
		showLoyaltyPoints: loyaltyPoints.show,
		statistics,

		// filters
		fromDateMenu: false,
		toDateMenu: false,
		fromDate: moment().startOf('year').format('YYYY-MM-DD'),
		toDate: moment().format('YYYY-MM-DD'),
		selectedViewBy: 2,
		customViewBy: null,

		// data
		ordersData: [],

		productsLabels: [],
		productsViewsData: [{ data: [] }],
		
		// statistics
		chartType: 0,
		chartYear: moment().format('YYYY'),

		advanceStaticsData: {
			AOV: [],
			cartAbandonmentRate: [],
			conversionRate: [],
			retentionRate: [],
		}
	}),

	computed: {
		...mapState({
			statics: state => state.dashboard.statics,
			advanceStatics: state => state.dashboard.advanceStatics,
		}),

		activeStates() {
			return {
				approved: orderStates.findIndex(c => c.id === orderState.approved) !== -1,
				inPreparation: orderStates.findIndex(c => c.id === orderState.inPreparation) !== -1,
				shipping: orderStates.findIndex(c => c.id === orderState.shipping) !== -1,
				completed: orderStates.findIndex(c => c.id === orderState.completed) !== -1,
				canceled: orderStates.findIndex(c => c.id === orderState.canceled) !== -1,
			}
		},

		ordersLabels() {
			return [
				{ name: this.$t('orderStates.approved'), active: this.activeStates.approved },
				{ name: this.$t('orderStates.in-progress'), active: this.activeStates.inPreparation },
				{ name: this.$t('orderStates.shipping'), active: this.activeStates.shipping },
				{ name: this.$t('orderStates.completed'), active: this.activeStates.completed },
				{ name: this.$t('orderStates.canceled'), active: this.activeStates.canceled },
			]
		},

		chartSeries() {
			const series = [];

			if (this.chartType === 0) {
				series.push({
					name: this.$t('statistics.average-order-value'),
					data: this.advanceStaticsData.AOV
				})
			} else if (this.chartType === 1) {
				series.push({
					name: this.$t('statistics.cart-abandonment-rate'),
					data: this.advanceStaticsData.cartAbandonmentRate
				})
			} else if (this.chartType === 2) {
				series.push({
					name: this.$t('statistics.conversion-rate'),
					data: this.advanceStaticsData.conversionRate
				})
			} else if (this.chartType === 3) {
				series.push({
					name: this.$t('statistics.retention-rate'),
					data: this.advanceStaticsData.retentionRate
				})
			}
			return series;
		},

		recentHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			return headers
		},
		
		popularHeaders() {
			const headers = [
				{ text: this.$t('headers.no'), value: 'no', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center' },
				{ text: this.$t('headers.visits'), value: 'view', align: 'center' },
			]
			if (this.showOrders) {
				headers.splice(
					headers.length,
					0,
					{ text: this.$t('headers.revenue'), value: 'revenue', align: 'center' },
					{ text: this.$t('headers.orders'), value: 'order', align: 'center' }
				);
			}
			return headers
		},

		popularProducts() {
			const products = this.statics.popularProduct || [];
			return products.sort((a, b) => b.order - a.order).slice(0, 10);
		},

		barDataLabelsOffset() {
			return [defaultLang, this.$i18n.locale].includes('ar') ? -30 : null;
		}
	},

	methods: {
		fetchStatics() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('dashboard/fetchAll', {
					fromDate: this.fromDate,
					toDate: this.toDate,
				}).then((data) => {
					const labels = [];
					const staticsData = [];
					data.productsView.forEach(product => {
						labels.push(product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name);
						staticsData.push(product.view);
					});
					
					this.productsLabels = labels;
					this.productsViewsData = [{
						name: this.$t('Dashboard.views'),
						data: staticsData.sort((a, b) => b - a)
					}];

					const { new: newState, inProgress, inUpdate, done, canceled } = data.orderStatus;
					const orderStatics = [newState, inProgress, inUpdate, done, canceled]
					const activeOrderStatics = [
						this.activeStates.approved,
						this.activeStates.inPreparation,
						this.activeStates.shipping,
						this.activeStates.completed,
						this.activeStates.canceled
					]
					const availableOrderStatics = [];
					activeOrderStatics.forEach((itemState, index) => {
						if (itemState) availableOrderStatics.push(orderStatics[index])
					})

					this.ordersData = [{
						name: this.$t('Dashboard.count'),
						data: availableOrderStatics
					}];
				}),
				this.showLoyaltyPoints
					? this.$store.dispatch('dashboard/fetchCashBack', {
						fromDate: this.fromDate,
						toDate: this.toDate,
					})
					: null,
				this.showAdvanceStatistics
					? this.$store.dispatch('dashboard/fetchAllAdvance', {
						fromDate: this.fromDate,
						toDate: this.toDate,
					}).then(() => {
						this.buildAdvanceStaticsData();
					})
					: null,
			]).finally(() => {
				this.loading = false;
			})
		},

		buildAdvanceStaticsData() {
			const buildStatics = (arr, calculateFunc = divideStaticsByDateStep) => {
				const step =
					this.selectedViewBy
						? this.viewBy.find(c => c.id === this.selectedViewBy).value
						: this.customViewBy;
				if (!step) return [];
				
				return calculateFunc(arr, step)
			}

			const { AOV, getCartRate: cartAbandonmentRate, conversionRate, retentionRate } = this.advanceStatics;
			this.advanceStaticsData.AOV =
				statistics.averageOrderValue ? buildStatics(AOV) : [];
			this.advanceStaticsData.cartAbandonmentRate =
				statistics.cartAbandonmentRate ? buildStatics(cartAbandonmentRate, getCartAbandonmentRate) : [];
			this.advanceStaticsData.conversionRate =
				statistics.conversionRate ? buildStatics(conversionRate) : [];
			this.advanceStaticsData.retentionRate =
				statistics.retentionRate ? buildStatics(retentionRate) : [];
		},

		// helpers
		fixNumber,
		getLoyaltyPointsValue
	},

	created() {
		this.fetchStatics();
	}
};
</script>

<style lang="scss" scoped>
.statistic-card {
	width: 85%;
	margin: 0 auto;
}
</style>
