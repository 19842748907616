<template>
	<v-dialog
		v-model="localeValue"
		max-width="1200"
		:persistent="isUploading"
		style="z-index: 1300"
	>
		<v-card class="fileManager rounded-medium">
			<v-card-title class="primary--text text-h5 pb-0">
				{{$t('components.file-manager')}}
				
				<v-spacer/>

				<v-btn
					icon
					:disabled="isUploading"
					@click="localeValue = false; $emit('close')"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="px-6" style="overflow: hidden">
				<FileManager
					v-model="file"
					portable
					:multipleSelect="multipleSelect"
					:tabs="tabs"
					@multiple-select-confirm="rebuildFilesUrl"
					@start-upload="isUploading = true"
					@end-upload="isUploading = false"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { BASE_URL } from '@/helpers/constants';
import FileManager from './index.vue';
export default {
	name: 'VFileManager',
	
	props: {
		tabs: {
			type: Object,
			default: () => ({
				images: true,
				videos: true,
				files: true,
			}) 
		},
		value: { type: Boolean, default: false },
		multipleSelect: { type: Boolean, default: false }
	},

	components: {
		FileManager
	},

	data() {
		return {
			// helpers
			isUploading: false,

			// data
			file: null,
		}
	},

	watch: {
		file(val) {
			if (val && !Array.isArray(val)) {
				this.$emit('file-selected', BASE_URL + val.publicPath, val);
				this.file = null;
				this.localeValue = false;
			}
		},

		value(newVal) {
			if (!newVal) {
				this.file = null;
			}
		}
	},

	computed: {
		localeValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		}
	},

	methods: {
		rebuildFilesUrl() {
			const files = [];
			this.file.forEach(item => {
				files.push(BASE_URL + item.publicPath);
			})
			this.$emit('file-selected', files, this.file);
			this.localeValue = false;
		}
	}
}
</script>

<style></style>