<template>
	<div
		class="bee-dropzone"
	>
		<div
			v-ripple="!disabled"
			class="bee-dropzone__container"
			:style="{ borderRadius: Number(radius) ? radius + 'px' : radius }"
		>
			<div
				class="dropzone"
				:style="{
					width: Number(width) ? width + 'px' : width,
					height: Number(height) ? height + 'px' : height,
					maxHeight: Number(maxHeight) ? maxHeight + 'px' : maxHeight,
					borderWidth: Number(borderWidth) ? borderWidth + 'px' : borderWidth,
					borderColor: borderColor,
					borderRadius: Number(radius) ? radius + 'px' : radius,
					cursor: disabled ? 'default' : null,
					gap:  Number(gapSize) ? gapSize + 'px' : gapSize
				}"
				:class="[{ 'dropzone--dashed': dashed }, dropZoneClasses]"
				@click.self="activateUploader"
				@drop.prevent="!disabled ? (() => {updateImages($event); isDroped = true})() : null"
				@dragover.prevent="icon = 'mdi-image'; dropMenu = true"
				@dragleave="icon = 'mdi-tray-arrow-down'"
			>
				<!-- items -->
				<bee-drop-img
					v-for="(file, index) in files"
					:key="index"
					:disabled="disabled"
					:width="dropImageProps.width ? dropImageProps.width : 70"
					:height="dropImageProps.height ? dropImageProps.height : 70"
					radius="5"
					blurable
					clearable
					:clear-color="clearColor"
					:clear-icon="clearIcon"
					hide-details
					hide-image-name
					v-bind="{...dropImageProps}"
					:value="file"
					@remove-image="$emit('remove-image', file, index); files.splice(index, 1)"
				></bee-drop-img>
			
				<!-- add button -->
				<div v-if="showAddBtn && !disabled && (isDroped || value.length !== 0)">
					<v-btn
						:color="borderColor ? borderColor : '#ccc'"
						depressed
						:width="dropImageProps.width ? dropImageProps.width : 70"
						:height="dropImageProps.height ? dropImageProps.height : 70"
						@click="isAdd = true; activateUploader()"
					>
						<v-icon :size="(dropImageProps.height ? dropImageProps.height : 70)/2.2">mdi-plus</v-icon>
					</v-btn>
				</div>
			
				<!-- icon & text -->
				<div
					v-if="!isDroped && value.length === 0"
					class="d-flex justify-center align-center flex-grow-1"
					@click="activateUploader"
				>
					<template v-if="!$slots.defualt && !loading">
						<v-icon color="grey" large class="me-2">{{icon}}</v-icon>
						<span
							v-if="icon === 'mdi-image' ? !$slots['drop-text'] : !$slots['drag-text']"
							class="text-center"
						>
							{{icon === 'mdi-image' ? dropText : dragText}}
						</span>
						<template v-else>
							<slot :name="icon === 'mdi-image' ? 'drop-text' : 'drag-text'"></slot>
						</template>
					</template>
			
					<template v-else>
						<slot></slot>
					</template>
				</div>
			
				<!-- input -->
				<input
					type="file"
					ref="fileInput"
					accept="image/*"
					class="dropzone__input"
					multiple
					@change="setImages($refs.fileInput.files)"
				>
				
				<!-- loading -->
				<v-progress-linear v-if="loading" indeterminate />
			</div>
			
		</div>
		<div v-if="!hideDetails" class="error__container">
			<div :class="{'error__msg--show': error}" class="error__msg text-caption error--text mb-0">
				{{error}}
			</div>
		</div>

		<v-fade-transition>
			<v-card
				v-if="dropMenu && !disabled && showDropMenu"
				class="bee-dropzone__drop-menu"
				:width="dropMenuWidth"
				:max-width="dropMenuMaxWidth"
				:height="dropMenuHeight"
				:min-height="dropMenuMaxHeight"
				:style="{
					borderRadius: Number(radius) ? radius + 'px' : radius,
					top: Number(dropMenuTop) ? dropMenuTop + 'px' : dropMenuTop,
					bottom: Number(dropMenuBottom) ? dropMenuBottom + 'px' : dropMenuBottom,
					left: Number(dropMenuLeft) ? dropMenuLeft + 'px' : dropMenuLeft,
					right: Number(dropMenuRight) ? dropMenuRight + 'px' : dropMenuRight,
				}"
			>
				<v-card-text
					class="bee-dropzone__drop-menu-content"
					:class="{'bee-dropzone__drop-menu-content--dashed': dashed}"
					:style="{
						outlineColor: borderColor,
						outlineWidth: Number(borderWidth) ? borderWidth + 'px' : borderWidth,
					}"
				>
					<div class="d-flex flex-column justify-center align-center flex-grow-1">
						<template v-if="!$slots.defualt && !disabled">
							<v-icon x-large class="mb-3">mdi-image-multiple</v-icon>
							<span
								v-if="!$slots['drop-text']"
								class="text-center text-h5"
							>
								{{dropText}}
							</span>
							<template v-else>
								<slot name="drop-text"></slot>
							</template>
						</template>
				
						<template v-else>
							<slot></slot>
						</template>
					</div>
				</v-card-text>
				
				<div
					class="bee-dropzone__drop-area"
					@drop.prevent="!disabled ? (() => {updateImages($event); isDroped = true; dropMenu = false})() : null"
					@dragleave="dropMenu = false"
					@dragover.prevent
				></div>
			</v-card>
		</v-fade-transition>
	</div>
</template>
<script>
import BeeDropImg from './BeeDropImg.vue'
export default {
	name: 'BeeDropZone',

	props: {
		width: { type: String, default: '100%' },
		height: { type: [String, Number], default: 'auto' },
		radius: { type: [String, Number] },
		'max-height': { type: [String, Number], default: 200 },
		'gap-size': { type: [String, Number], default: 5 },
		'clear-icon': { type: String, default: 'mdi-close' },
		'clear-color': { type: String },
		'drag-text': { type: String, default: 'Drap and drop images here' },
		'drop-text': { type: String, default: 'Drop images here' },
		dashed: { type: Boolean, default: false },
		'border-width': { type: [String, Number] },
		'border-color': { type: String },
		'drop-zone-classes': { type: String },
		'hide-details': { type: Boolean, default: false },
		'show-add-btn': { type: Boolean, default: false },
		'show-drop-menu': { type: Boolean, default: false },
		'drop-menu-top': { type: [Number, String], default: 0 },
		'drop-menu-bottom': { type: [Number, String], default: 0 },
		'drop-menu-left': { type: [Number, String], default: 0 },
		'drop-menu-right': { type: [Number, String], default: 0 },
		'drop-menu-width': { type: [Number, String], default: 400 },
		'drop-menu-max-width': { type: [Number, String], default: null },
		'drop-menu-height': { type: [Number, String], default: 200 },
		'drop-menu-max-height': { type: [Number, String], default: null },
		'no-drop-replace': { type: Boolean, default: false },
		'error-message': { type: String, default: 'only images are allowed' },
		'drop-image-props': { type: Object, default: () => ({}) },
		disabled: { type: Boolean, default: false },
		contain: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		'disable-file-picker': { type: Boolean, default: false },
		value: { type: [Array, FileList], default: () => [] }
	},

	components: {
		BeeDropImg
	},

	data: () => ({
		icon: 'mdi-tray-arrow-down',
		dropMenu: false,
		isDroped: false,
		isAdd: false,
		files: [],
		error: null,
	}),

	watch: {
		value(val) {
			if (!val || val.length === 0) {
				this.icon = 'mdi-tray-arrow-down';
				this.isDroped = false;
				this.files = [];
			} else {
				this.files = val;
			}
		}
	},

	methods: {
		activateUploader() {
			if (!this.disabled) {
				if (!this.disableFilePicker) {
					this.$refs.fileInput.click();
				} else {
					this.$emit('click')
				}
			}
		},

		updateImages(event) {
			const files = event.dataTransfer.files;
			this.setImages(files);
		},

		setImages(files) {
			const arrayFiles = [];
			if (this.isAdd || this.noDropReplace) {
				arrayFiles.push(...this.files);
				this.isAdd = false;
			}
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (!/^image\/.*/g.test(file.type)) {
					this.error = this.errorMessage;
					return null;
				}
				arrayFiles.push(file);
			}
			this.files = arrayFiles;
			this.$emit('input', arrayFiles);
		}
	},

	created() {
		if (this.value.length > 0) {
			this.files = this.value;
		}
	}
};
</script>

<style lang="scss">
$color-light-gray: #ccc;
.bee-dropzone {
	position: relative;
	
	&__container {
		.dropzone {
			display: flex;
			flex-wrap: wrap;

			border: 1px solid $color-light-gray;
			border-radius: 5px;
			padding: 10px;
			overflow-y: auto;
			cursor: pointer;

			transition: all .3s;

			&--dashed{
				border-style: dashed;
			}

			&__input {
				display: none;
			}
		}
		.error {
			&__container {
				height: 20px;
			}

			&__msg {
				transform: translateY(-10px);
				transition: transform .4s;
				
				&--show {
					transform: translateY(0);
				}
			}
		}
	}

	&__drop-menu {
		position: absolute;
		z-index: 20;
	}

	&__drop-menu-content {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;
		padding: 1rem !important;
		outline: 1px solid $color-light-gray;
		outline-offset: -1rem;
		border-radius: 11px;
		
		&--dashed{
			outline-style: dashed;
		}
	}

	&__drop-area {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 30;

		width: 100%;
		height: 100%;
	}
}
</style>