import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		filters: [],
		hierarchyFilters: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/filters').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.filters ? !c.deletedAt : true);
				commit(types.STORE_FILTERS, storeData);
				return storeData;
			})
		},
		fetchAllAsHierarchy({ commit }) {
			return axios.get('/filters/hierarchy', {
				// params: { withDeleted: useRestore.filters ? 'True' : 'False' }
			}).then(({ data }) => {
				commit(types.STORE_HIERARCHY_FILTERS, data);
				return data;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/filters/${slug}`, configs).then(({ data }) => data);
		},
		create({ commit }, { name, description, image, imagePath, filterId, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, description, image, imageName, filterId }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			return axios.post('/filters', formData, configs).then(({ data }) => data);
		},
		update({ commit }, { id, name, description, image, imagePath, filterId, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, description, image, imageName, filterId }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			formData.append('_method', 'PUT');

			return axios.post(`/filters/${id}`, formData, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.post(`/filters/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/filters/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_FILTERS](state, filters) {
			state.filters = filters;
		},
		[types.STORE_HIERARCHY_FILTERS](state, hierarchyFilters) {
			state.hierarchyFilters = hierarchyFilters;
		},
	},

	getters: {
		getFilterById: state => id => state.filters.find(c => c.id === id),
		getFilterBySlug: state => slug => state.filters.find(c => c.slug === slug),
	}
}