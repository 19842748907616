<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType=''
			:ids="deleteItemId"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('SystemUsersRoles.the-role')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="600"
			:persistent="submitLoading"
		>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit" :disabled="submitLoading">
					<v-card-title class="justify-center text-uppercase text-h6 pb-0">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('SystemUsersRoles.role')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-simple-table
							dense
							fixed-header
							:height="
								permissionTypes.filter(c => c.show).length > 13
									? $vuetify.breakpoint.height - 250
									: null
							"
							class="rounded-large elevation-2"
						>
							<thead>
								<tr>
									<th class="text-left">{{$t('SystemUsersRoles.page-name')}}</th>
									<th class="text-left">{{$t('SystemUsersRoles.permission')}}</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(key, index) in Object.keys(permissions)">
									<tr v-if="permissionTypes[index].show" :key="index">
										<td class="font-weight-medium primary--text">{{permissionTypes[index].name}}</td>
										<td>
											<v-radio-group
												v-model="permissions[key]"
												row
												dense
												hide-details
												class="text-body-2 ma-0 pa-0"
											>
												<v-radio
													v-for="type in types"
													:ripple="false"
													:key="type.id"
													:label="type.name"
													:value="type.id"
												>
													<template v-slot:label>
														
													</template>
												</v-radio>
											</v-radio-group>
										</td>
									</tr>
								</template>
							</tbody>
						</v-simple-table>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="submitLoading"
						>{{$t('save')}}</v-btn>
						<v-btn @click="dialog = false" class="rounded-small" :disabled="submitLoading">
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text v-if="true" class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('SystemUsersRoles.add-new-role')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							:label="$t('search')"
							hide-details="auto"
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
						>
							{{$t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-text v-else class="pa-3">
				<v-row class="justify-center align-center">
					<v-col lg="3" md="4" sm="6" cols="12">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details="auto"
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-spacer/>
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredRoles"
			:loading="loading"
			dense
			:items-per-page="15"
			disable-sort
			class="elevation-3 rounded-medium"
		>
			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>

					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState } from 'vuex';
import { permissionTypes } from '@/helpers/enums'
import * as routesConfig from '@/configs/routes.config';

export default {
	name: 'SystemUsersRoles',

	data: () => ({
		dialog: false,		
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,

		roles: [],

		// filter
		search: null,
		isFiltered: null,

		// helpers
		loading: false,
		submitLoading: false,
		rules,

		// permissions
		types: permissionTypes,

		// data
		permissions: {
			sets: null,
			categories: null,
			filters: null,
			keywords: null,
			products: null,
			news: null,
			services: null,
			orders: null,
			plans: null,
			albums: null,
			videos: null,
			sliders: null,
			users: null,
			systemUsersRoles: null,
			branches: null,
			subscriptions: null,
			aboutUs: null,
			companyValues: null,
			companyHistories: null,
			SEO: null,
		},
	}),

	watch: {
		dialog(newVal) {
			if (newVal) {
				if (this.editItemId) {
					// 
				}
			} else {
				this.$refs.form.reset();
				this.editItemId = null;
			}
		}
	},

	computed: {
		...mapState({
			self: state => state.auth.self,
			categories: state => state.categories.categories
		}),

		headers() {
			return [
				{ text: this.$t('headers.name'), value: 'fullName', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', width: 200 },
			]
		},

		filteredRoles() {
			return this.filterByName(this.isFiltered, this.roles, this.search, 'name');
		},

		permissionTypes() {
			return [
				{
					name: this.$t('SystemUsersRoles.permissionTypes.sets'),
					show: routesConfig.sets
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.categories'),
					show: routesConfig.categories
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.filters'),
					show: routesConfig.filters
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.keywords'),
					show: routesConfig.keywords
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.products'),
					show: routesConfig.products
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.news'),
					show: routesConfig.news
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.services'),
					show: routesConfig.services
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.orders'),
					show: routesConfig.orders
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.plans'),
					show: routesConfig.plans
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.albums'),
					show: routesConfig.albums
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.videos'),
					show: routesConfig.videos
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.sliders'),
					show: routesConfig.sliders
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.users'),
					show: routesConfig.users
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.systemUsers'),
					show: routesConfig.systemUsers
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.branches'),
					show: routesConfig.branches
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.subscriptions'),
					show: routesConfig.subscriptions
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.aboutUs'),
					show: routesConfig.aboutUs
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.companyValues'),
					show: routesConfig.companyValues
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.companyHistories'),
					show: routesConfig.companyHistories
				},
				{
					name: this.$t('SystemUsersRoles.permissionTypes.SEO'),
					show: routesConfig.SEO
				},
			]
		},
	},

	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				// 
			}
		},

		fetchRoles(isFirstLoad = false) {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		}
	},

	created() {
		this.loading = true;
		Promise.all([
			!this.categories.length ? this.$store.dispatch('categories/fetchAll') : null,
			this.fetchRoles()
		]).finally(() => {
			this.loading = false;
		})
	}
};
</script>

<style>
</style>
