<template>
	<div>
		<bee-delete-dialog
			v-if="false"
			v-model="deleteDialog"
			moduleType=''
			:ids="deleteItemId"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Users.the-user')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-if="false"
			v-model="dialog"
			width="400"
			persistent
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6 mb-3">
					{{ editItemId ? $t('edit') : $t('add')}} {{$t('Users.users')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row class="flex-column mt-0">
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.name')"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.username')"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.password')"
								type="password"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.confirm-password')"
								type="password"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" class="rounded-small">{{$t('save')}}</v-btn>
					<v-btn @click="dialog = false; editItemId = null" class="rounded-small">{{$t('cancel')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- email dialog -->
		<v-dialog v-model="emailDialog" width="500" persistent eager attach :retain-focus="false" >
			<v-form ref="emailForm" :disabled="sendLoading" @submit.prevent="sendEmails">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6">
						{{$t('Users.send-email')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row dense>
							<!-- users emails -->
							<v-col cols="12">
								<v-autocomplete
									v-model="emailData.usersEmails"
									:label="$t('inputs.email')"
									:items="usersWithEmails"
									:item-text="item => item.fullName + ` <${item.email}>`"
									item-value="email"
									multiple
									hide-details
									outlined
									dense
									class="rounded-small"
									:rules="rules.requiredAsArray"
								>
									<template v-slot:prepend-item>
										<v-list-item
											dense
											ripple
											@click="
												!emailData.usersEmails.length
													? emailData.usersEmails = usersWithEmails
													: emailData.usersEmails = []
											"
										>
											<v-list-item-action>
												<v-icon :color="emailData.usersEmails.length > 0 ? 'primary' : null">
													{{
														emailData.usersEmails.length === usersWithEmails.length
															? 'mdi-close-box'
															: emailData.usersEmails.length
																? 'mdi-minus-box'
																: 'mdi-checkbox-blank-outline'
													}}
												</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>Select All</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-divider/>
									</template>
								</v-autocomplete>
							</v-col>

							<!-- subject -->
							<v-col cols="12">
								<v-text-field
									v-model="emailData.subject"
									:label="$t('inputs.subject')"
									hide-details
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- preheader -->
							<v-col cols="12">
								<v-text-field
									v-model="emailData.preheader"
									:label="$t('inputs.preheader')"
									hide-details
									outlined
									dense
									class="rounded-small"
								></v-text-field>
							</v-col>
							
							<!-- body -->
							<v-col cols="12">
								<text-editor
									v-model="emailData.body"
									hide-menubar
									mini-tools
									:disabled="sendLoading"
									:height="300"
									:error="isSubmitted && !emailData.body"
								></text-editor>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							:loading="sendLoading"
							:disabled="sendLoading"
							class="rounded-small"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							:disabled="sendLoading"
							class="rounded-small"
							@click="emailDialog = false;"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Users.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text v-if="false" class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Users.add-new-user')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
						>
							{{$t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-text v-else class="pa-2">
				<v-row class="justify-center align-center">
					<v-col lg="3" md="4" sm="6" cols="12">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-spacer v-if="!useEmailSender"/>
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
					<v-spacer v-if="useEmailSender"/>
					<v-col v-if="useEmailSender" cols="auto">
						<v-btn
							color="success white--text"
							class="rounded-small ms-2"
							@click="emailDialog = true"
						>
							{{$t('Users.send-email')}}
							<v-icon class="ms-1">mdi-email-fast</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredUsers"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.usersHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>
			
			<!-- address -->
			<template v-slot:item.address="{ item }">
				{{ item.address | isAvailable }}
			</template>

			<!-- email -->
			<template v-slot:item.email="{ item }">
				{{ item.email | isAvailable }}
			</template>

			<!-- phone -->
			<template v-slot:item.phone="{ item }">
				{{ item.phone | isAvailable }}
			</template>

			<!-- is home page -->
			<template v-slot:item.hasPlan="{ item }">
				<v-icon v-if="item.hasPlan">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center">
					<tooltip :text="$t('tooltip.preview-user-info')">
						<v-btn
							v-if="showUserInfo"
							color="deep-purple darken-2 white--text"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'user-info', params: { id: item.id } }"
						><v-icon>mdi-eye</v-icon></v-btn>
					</tooltip>

					<tooltip v-if="useEmailSender" :text="$t('tooltip.send-email')">
						<v-btn
							color="success white--text"
							small
							:disabled="!item.email"
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="emailDialog = true; emailData.usersEmails = [item.email]"
						><v-icon>mdi-email-fast</v-icon></v-btn>
					</tooltip>
					
					<v-btn
						v-if="false"
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="fillToEdit(item.id)"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						v-if="false"
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>

					<tooltip :text="$t('tooltip.active-state')">
						<v-switch
							v-model="item.isBanned"
							:ripple="false"
							:true-value="0"
							:false-value="1"
							color="darkGreen"
							hide-details
							readonly
							@click="activeConfirmDialog(item)"
							class="ms-2 mt-0 pt-0"
							:disabled="item.isAdmin"
						></v-switch>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState } from 'vuex';
import { plans, userInfo } from '@/configs/routes.config'
import { sendCustomizedEmail } from '@/helpers/emails';
import { useEmailSender } from '@/configs/global.config';

export default {
	name: 'Users',

	data: () => ({
		dialog: false,		
		editItemId: null,

		emailDialog: false,
		sendLoading: false,
		isSubmitted: false,
		
		deleteDialog: false,
		deleteItemId: null,
		
		confirmDialog: false,
		confirmId: null,
		titleState: null,
		changeStateLoading: false,

		// configs
		useEmailSender: useEmailSender,

		// filter
		search: null,
		isFiltered: null,

		// helpers
		loading: false,
		rules,

		// configs
		showUserInfo: userInfo.show,
		showPlans: plans.show,

		// data
		emailData: {
			usersEmails: [],
			subject: null,
			preheader: null,
			body: null,
		}
		
	}),

	watch: {
		emailDialog(val) {
			if (!val) {
				this.$refs.emailForm.reset();
				this.emailData.usersEmails = [];
				this.emailData.body = '';
				this.isSubmitted = false;
			}
		}
	},

	computed: {
		...mapState({
			users: state => [...state.users.users, { fullName: 'j', email: 'jourge.escalejyan@gmail.com' }]
		}),
		
		allHeaders() {
			return [
				{
					text: this.$t('headers.name'),
					value: 'fullName',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true,
				},
				{
					text: this.$t('headers.username'),
					value: 'userName',
					align: 'center',
				},
				{
					text: this.$t('headers.address'),
					value: 'address',
					align: 'center',
				},
				{
					text: this.$t('headers.email'),
					value: 'email',
					align: 'center',
				},
				{
					text: this.$t('headers.phone'),
					value: 'phone',
					align: 'center',
				},
				{
					text: this.$t('headers.has-plan'),
					value: 'hasPlan',
					align: 'center',
					hide: !this.showPlans,
					notInDefaultView: true
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					sortable: false,
					noRemove: true,
					fixed: true
				},
			]
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.usersHeaders.length) {
				headers = this.$eStorage.l.usersHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		usersWithEmails() {
			return this.users.filter(c => Boolean(c.email));
		},

		filteredUsers() {
			return this.filterByName(this.isFiltered, this.users, this.search, 'fullName').filter(c => !c.isAdmin);
		}
	},

	methods: {
		fillToEdit(id) {
			this.dialog = true;
			this.editItemId = id;
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.is_banned ? this.$t('activate') : this.$t('deactivate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('users/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('Users.user-state-is-changed-successfully'));
				this.fetchUsers();
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		fetchUsers() {
			this.loading = true;
			this.$store.dispatch('users/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},

		sendEmails() {
			this.isSubmitted = true;
			if (this.$refs.emailForm.validate()) {
				this.sendLoading = true;
				const { usersEmails: emails, subject, preheader, body } = this.emailData;
				sendCustomizedEmail(emails, {
					subject,
					preheader,
					body
				}).then(() => {
					this.emailDialog = false;
					this.$eventBus.$emit('show-snackbar', false, this.$t('messages.emails-is-sent'))
				}).finally(() => {
					this.sendLoading = false;
				})
			}
		}
	},

	created() {
		this.fetchUsers();
	}
};
</script>

<style>
</style>
