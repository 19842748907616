import { plans } from '@/configs/translates.config';

export default {
	'add-new-plan': `add new ${plans.en.singular}`,
	'the-plan': `the ${plans.en.singular}?`,
	plan: plans.en.singular,
	confirm: 'confirm',
	'are-you-sure-you-want-to-$-this-user': `Are you sure you want to {state} this ${plans.en.singular}?`,
	activate: 'activate',
	deactivate: 'deactivate',

	'plan-users': 'Plan users'
}