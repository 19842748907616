<template>
	<FileManager/>
</template>

<script>
import FileManager from '@/components/file-manager/index.vue'
export default {
	name: 'FileManger',

	components: {
		FileManager,
	},
}
</script>

<style>

</style>