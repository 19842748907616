<template>
	<div>
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:checked-langs="acceptedToSaveLangs"
			:disable-non-selected="loading || submitLoading"
			background-color="white"
			class="mb-2"
			@change="updateFieldsFromDraft"
		/>

		<!-- about -->
		<template v-if="showAbout">
			<p class="text-h6 font-weight-bold mb-3">{{$t('AboutUs.about')}}</p>
			<text-editor
				editorId="about"
				v-model="about"
				:loading="loading"
				:use-template-builder="allowTemplateBuilder"
			/>
		</template>

		<!-- mission -->
		<template v-if="showMission">
			<p class="text-h6 font-weight-bold my-3">{{$t('AboutUs.mission')}}</p>
			<text-editor
				editorId="mission"
				v-model="mission"
				:loading="loading"
				:use-template-builder="allowTemplateBuilder"
			/>
		</template>

		<!-- vision -->
		<template v-if="showVision">
			<p class="text-h6 font-weight-bold my-3">{{$t('AboutUs.vision')}}</p>
			<text-editor
				editorId="vision"
				v-model="vision"
				:loading="loading"
				:use-template-builder="allowTemplateBuilder"
			/>
		</template>

		<!-- policy -->
		<template v-if="showPolicy">
			<p class="text-h6 font-weight-bold my-3">{{$t('AboutUs.policy')}}</p>
			<text-editor
				editorId="policy"
				v-model="policy"
				:loading="loading"
				:use-template-builder="allowTemplateBuilder"
			/>
		</template>

		<!-- quality policy -->
		<template v-if="showQualityPolicy">
			<p class="text-h6 font-weight-bold my-3">{{$t('AboutUs.qualityPolicy')}}</p>
			<text-editor
				editorId="qualityPolicy"
				v-model="qualityPolicy"
				:loading="loading"
				:use-template-builder="allowTemplateBuilder"
			/>
		</template>

		<v-btn
			color="primary"
			class="rounded-medium mt-6"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
			@click="submit"
		>
			{{$t('save')}}
		</v-btn>
	</div>
</template>

<script>
import { aboutUs, allowTemplateBuilder } from '@/configs/global.config';
import { mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import langsDraft from '@/mixins/langs-draft';

export default {
	name: 'AboutUs',
	mixins: [langsDraft],

	data: () => ({
		// configs
		multiLangInputs,
		allowTemplateBuilder,
		showAbout: aboutUs.showAbout,
		showMission: aboutUs.showMission,
		showVision: aboutUs.showVision,
		showPolicy: aboutUs.showPolicy,
		showQualityPolicy: aboutUs.showQualityPolicy,
		
		// helpers
		loading: false,
		submitLoading: false,
		lang: defaultLang,
		isInputsChanged: false,

		// draft for languages 
		draft: {},
		draftNames: [
			{ name: 'about', value: '' },
			{ name: 'mission', value: '' },
			{ name: 'vision', value: '' },
			{ name: 'policy', value: '' },
			{ name: 'qualityPolicy', value: '' },
		],
		
		// data
		about: null,
		mission: null,
		vision: null,
		policy: null,
		qualityPolicy: null,
	}),

	computed: {
		...mapState({
			aboutUs: state => state.aboutUs.aboutUs
		}),
		
		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (
					this.draft.about[lang.key] ||
					this.draft.mission[lang.key] ||
					this.draft.vision[lang.key] ||
					this.draft.policy[lang.key] ||
					this.draft.qualityPolicy[lang.key]
				) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			if (this.about || this.mission || this.vision || this.policy || this.qualityPolicy) {
				this.submitLoading = true;

				const promises = [];

				const submitRequest = (
					{ about, mission, vision, policy, qualityPolicy, lang },
				) => {
					promises.push(
						this.$store.dispatch('aboutUs/create', {
							about: this.showAbout ? about : null,
							mission: this.showMission ? mission : null,
							vision: this.showVision ? vision : null,
							policy: this.showPolicy ? policy : null,
							qualityPolicy: this.showQualityPolicy ? qualityPolicy : null,
							acceptLanguage: lang
						})
					);
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const about = this.draft.about[lang];
					const mission = this.draft.mission[lang];
					const vision = this.draft.vision[lang];
					const policy = this.draft.policy[lang];
					const qualityPolicy = this.draft.qualityPolicy[lang];

					const dataDueLang = { about, mission, vision, policy, qualityPolicy, lang };

					if (about || mission || vision || policy || qualityPolicy) {
						promises.push(submitRequest(dataDueLang));
					}
				}
				
				Promise.all(promises).then(() => {
					this.isInputsChanged = false;
					
					const message = this.$t('$-is-updated-successfully', { name: this.$t('AboutUs.about') })
					this.$eventBus.$emit('show-snackbar', false, message);
					this.fetchAboutUs();
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		fillInDraft(data, lang) {
			if (this.showAbout) this.draft.about[lang] = data.find(c => c.key === 'about')?.value ?? '';
			if (this.showMission) this.draft.mission[lang] = data.find(c => c.key === 'mission')?.value ?? '';
			if (this.showVision) this.draft.vision[lang] = data.find(c => c.key === 'vision')?.value ?? '';
			if (this.showPolicy) this.draft.policy[lang] = data.find(c => c.key === 'policy')?.value ?? '';
			if (this.showQualityPolicy) this.draft.qualityPolicy[lang] = data.find(c => c.key === 'qualityPolicy')?.value ?? '';
		},

		fillToEdit(data) {
			if (this.showAbout) this.about = data.find(c => c.key === 'about')?.value;
			if (this.showMission) this.mission = data.find(c => c.key === 'mission')?.value;
			if (this.showVision) this.vision = data.find(c => c.key === 'vision')?.value;
			if (this.showPolicy) this.policy = data.find(c => c.key === 'policy')?.value;
			if (this.showQualityPolicy) this.qualityPolicy = data.find(c => c.key === 'qualityPolicy')?.value;
		},

		fetchAboutUs() {
			this.loading = true;
			const promises = [];

			let currentLangData = null;
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('aboutUs/fetchAll', { acceptLanguage: c.key }).then((data) => {
						if (this.lang === c.key) currentLangData = data;
						this.fillInDraft(data, c.key);
					})
				)
			});

			Promise.all(promises).then(() => {
				this.fillToEdit(currentLangData);
			}).finally(() => {
				this.loading = false;
			})
		}
	},

	created() {
		this.fetchAboutUs();
	}
}
</script>

<style>

</style>