import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		coupons: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/coupons').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.coupons ? !c.deletedAt : true);
				commit(types.STORE_COUPONS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/coupons/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { name, code, fromDate, toDate, maxUsers, userIds, fixedValue, percentValue, isActive, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, code, fromDate, toDate, maxUsers, fixedValue, percentValue };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			});
			
			userIds.forEach(id => { formData.append('userIds[]', id); });
			formData.append('isActive', isActive ? 1 : 0);

			return axios.post('/coupons', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, name, code, fromDate, toDate, maxUsers, userIds, fixedValue, percentValue, isActive, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, code, fromDate, toDate, maxUsers, fixedValue, percentValue };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) formData.append(key, requestData[key]);
			});
			
			userIds.forEach(id => { formData.append('userIds[]', id); });
			formData.append('isActive', isActive ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/coupons/${id}`, formData, configs).then(({ data }) => data);
		},
		
		changeState({ commit }, { id }) {
			return axios.post(`/coupons/${id}/change-status`, { _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/coupons/${id}`, { _method: 'DELETE' });
		},

		restore({ commit }, { id }) {
			return axios.post(`/coupons/${id}`, { _method: 'PATCH' });
		},
	},

	mutations: {
		[types.STORE_COUPONS](state, coupons) {
			state.coupons = coupons;
		}
	},

	getters: {
		getCouponById: state => id => state.coupons.find(c => c.id === id),
		getCouponBySlug: state => slug => state.coupons.find(c => c.slug === slug),
	}
}