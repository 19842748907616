import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		videos: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/albums').then(({ data }) => {
				commit(types.STORE_VIDEOS, data);
				return data;
			})
		},

		fetchById({ commit }, { id, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			return axios.get(`/albums/${id}`, configs).then(({ data }) => data);
		},

		create({ commit }, { title, content, inHomePage, video, url, acceptLanguage, progressHandler }) {
			const configs = {
				...headerAcceptLanguage(acceptLanguage),
				onUploadProgress: (progressEvent) => progressHandler(progressEvent),
			};

			const formData = new FormData();
			const requestData = { title, content, video, url };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			formData.append('inHomePage', inHomePage ? 1 : 0);

			return axios.post('/albums', formData, configs).then(({ data }) => data)
		},

		update({ commit }, { id, title, content, inHomePage, video, url, acceptLanguage, progressHandler }) {
			const configs = {
				...headerAcceptLanguage(acceptLanguage),
				onUploadProgress: (progressEvent) => progressHandler(progressEvent),
			};

			const formData = new FormData();
			const requestData = { title, content, video, url };
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			formData.append('inHomePage', inHomePage ? 1 : 0);
			
			formData.append('_method', 'PUT');

			return axios.post(`/albums/${id}`, formData, configs).then(({ data }) => data)
		},

		delete({ commit }, { id }) {
			return axios.post(`/albums/${id}`, { _method: 'DELETE' })
		},

		restore({ commit }, { id }) {
			return axios.post(`/albums/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_VIDEOS](state, videos) {
			state.videos = videos;
		}
	},

	getters: {
		getVideoById: state => id => state.videos.find(c => c.id === id),
	}
}