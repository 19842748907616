import Vue from 'vue'
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework'
import { mainColor, rtl } from '@/configs/brand.config';

import en from 'vuetify/lib/locale/en'
import ar from 'vuetify/lib/locale/ar'
import es from 'vuetify/lib/locale/es'
import fr from 'vuetify/lib/locale/fr'
import fa from 'vuetify/lib/locale/fa'
import de from 'vuetify/lib/locale/de'
import zh from 'vuetify/lib/locale/zh-Hans'
import ru from 'vuetify/lib/locale/ru'
import tr from 'vuetify/lib/locale/tr'
import i18n from '@/i18n/i18n';

Vue.use(Vuetify)

export const customColors = {
	primary: mainColor, // #015931 #083866
	bgColor: '#f3f7f8',
	secondary: '#a7a7a7', // #FFCDD2
	success: '#13a689',
	error: '#ff3334',
	errorLight: '#eeb4b3',
	main: colors.grey.darken3,
	darkGreen: colors.green.darken1
};

export default new Vuetify({
	rtl: rtl || i18n.locale === 'ar',
	lang: {
		locales: { en, ar, es, fr, fa, de, zh, ru, tr },
		current: i18n.locale,
	},
	theme: {
		themes: {
			light: customColors,
		},
	},
})
