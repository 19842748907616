<template>
	<v-app>
		<navbar
			v-if="!['login'].includes($route.name)"
			v-show="!templateBuilder.show"
			@change-drawer="drawer = !drawer"
		/>
		<navigation-drawer
			v-if="!['login'].includes($route.name)"
			v-show="!templateBuilder.show"
			v-model="drawer"
		/>
		<v-main v-show="!templateBuilder.show" class="bgColor">
			<v-container fluid class="py-3 px-4">
				<router-view/>
			</v-container>
		</v-main>

		<VFileManagerDialog
			v-model="fileManager.dialog"
			:tabs="fileManager.tabsOptions"
			:multipleSelect="fileManager.multipleSelect"
			@file-selected="useFileMangerCallBack"
			@close="() => $refs.templateBuilder && $refs.templateBuilder.closeFileManager()"
		/>
		
		<VHeadersControlDialog
			v-model="headersControl.dialog"
			:headers="headersControl.headers"
			:shownHeaders="headersControl.shownHeaders"
			@save="headersControl.callBack"
		/>

		<VTemplateBuilder
			v-if="templateBuilder.show && !['login'].includes($route.name)"
			ref="templateBuilder"
			:init-value="templateBuilder.initValue"
			@save="saveTemplate"
			@close="templateBuilder.show = false;"
		/>

        <v-snackbar
			id="app-snackbar"
            v-model="snackbar"
            :color="isError ? 'red lighten-1' : 'primary lighten-1'"
			content-class="rounded-medium"
			style="z-index: 1400"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
import { brandName } from './configs/brand.config'
export default {
	name: 'App',
	components: { Navbar, NavigationDrawer },

	data: () => ({
		drawer: false,
		
		fileManager: {
			dialog: false,
			tabsOptions: {},
			callBack: () => {},
			// selectedFile: { url: null, file: null },
			multipleSelect: false,
		},

		headersControl: {
			dialog: false,
			headers: [],
			shownHeaders: [],
			callBack: () => {},
		},

		templateBuilder: {
			show: false,
			initValue: '',
			callBack: () => {}
		},

		snackbar: false,
		isError: false,
		message: null
	}),

	watch: {
		'$i18n.locale'(val) {
			if (val === 'ar') {
				this.$vuetify.rtl = true;
			} else {
				this.$vuetify.rtl = false;
			}
			this.$vuetify.lang.current = val;
			window.axios.defaults.headers.common['accept-language'] = val;
		},
		'$route.name'() {
			this.fileManager.dialog = false;
			// this.fileManager.tabsOptions = { images: true, videos: true, files: true };
			this.fileManager.callBack = null;
		},
		'fileManager.dialog'(val) {
			if (!val) {
				this.fileManager.callBack = null;
			}
		}
	},

	methods: {
		useFileMangerCallBack(url, file) {
			this.fileManager.callBack(url, file);
		},

		saveTemplate(codes, htmlCss) {
			this.templateBuilder.callBack(codes, htmlCss);
			this.templateBuilder.show = false;
			this.templateBuilder.initValue = '';
		}
	},

	created() {
		this.$eventBus.$on('file-manager', (options, callBack) => {
			this.fileManager.dialog = true;
			this.fileManager.tabsOptions = options;
			this.fileManager.multipleSelect = options.multiple || false;
			this.fileManager.callBack = callBack;
		})
		this.$eventBus.$on('headers-control', (headers, shownHeaders, callBack) => {
			this.headersControl.dialog = true;
			this.headersControl.headers = headers;
			this.headersControl.shownHeaders = shownHeaders;
			this.headersControl.callBack = callBack || (() => {});
		})
		this.$eventBus.$on('template-builder', (initValue, callBack = () => {}) => {
			this.templateBuilder.show = true;
			this.templateBuilder.initValue = initValue || '';
			this.templateBuilder.callBack = callBack;
		})
		this.$eventBus.$on('show-snackbar', (isError, message) => {
			this.isError = isError;
			this.message = message;
			this.snackbar = true;
		})
	},

	metaInfo() {
		const title = this.$route.name;
		return {
			title: title.charAt(0).toUpperCase() + title.slice(1).replace(/-/g, ' '),
			titleTemplate: `%s | ${brandName}`
		}
	}
}
</script>

<style lang="scss">
@import './scss/main.scss';
*{
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #bbb;
		border-radius: 11px;
	}
}

.rounded {
	&-large {
		border-radius: 15px !important;
	}
	&-medium {
		border-radius: 11px !important;
	}
	&-small {
		border-radius: 9px !important;
	}
}
.number{
	&-large {
		font-size: 2.4rem !important;
		font-weight: 500;
	}
}
.sticky {
	position: sticky;
	top: 65px;
}

.ellipsis {
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 120px;
}

.tree-row {
	&:hover {
		background-color: #eee;
	}
	&__summary {
		white-space: pre-line;
	}
}

.text-editor-content {
	padding: 8px 11px;
	border: 1px solid #999;
	border-radius: 11px;
	transition: border-color .1s;
	cursor: text;

	&:hover {
		border-color: #222;
	}

	&__label {
		color: #000000c4;
		display: inline-block;
		&--active {
			position: absolute;
			transform: translate(-3px, -14px);
			line-height: 10px;
			padding: 0 3px;
			background: #f3f7f8;
		}
	}

	p:last-of-type {
		margin-bottom: 0;
	}
}

.v-snack__wrapper {
	border-radius: 11px !important;
}

body .tox {
	.tox-dialog {
		border-radius: 11px;
	}
	.tox-button:not(.tox-button--secondary, .tox-button--naked) {
		background-color: #084566;
		border-color: #084566;
		border-radius: 9px;
	}
	.tox-dialog-wrap__backdrop {
		background-color: transparent;
	}
	.tox-notifications-container {
		display: none;
	}
	.tox-selected-menu {
		max-height: 220px !important;
	}
	.tox-dialog {
		.tox-textfield, .tox-listbox, button {
			border-radius: 9px;
		}
	}
}
#app {
	.tox .tox-editor-header {
		z-index: 0;
	}
	.tox-tinymce {
		border-radius: 11px;
	}

	.apexcharts-toolbar {
		z-index: 4;
	}

	.mini-input {
		input {
			padding: 0;
		}
		.v-input__control {
			& > .v-input__slot {
				min-height: 26px;
			}
			.v-text-field--outlined .v-label, .v-text-field__slot .v-label {
				top: 2px;
			}
			.v-text-field__slot .v-label--active {
				transform: translateY(-9px) scale(0.75)
			}

			.v-input__append-inner {
				margin-top: 2px;
			}
		}
		
		&--centered{
			input {
				text-align: center;
			}
		};
	}

	.v-menu__content {
		border-radius: 9px;	
	}

	.v-counter {
		font-weight: 500;
		font-size: .9rem;
	}

	.v-slider__thumb {
		&::after, &::before {
			display: none;
		}
	}

	.drag {
		&-dots {
			cursor: grab;
			&:active {
				cursor: grabbing;
			}
		}
		&-item {
			outline: 1px dashed #084566;
			outline-offset: -1px;
			border-radius: 4px;
		}
	}

	.loop-progress svg {
		animation: loop 1.65s linear infinite;

		@keyframes loop {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}

	.powered-by {
		text-align: center;
		* {
			font-size: 11px;
		}
		a {
			color: inherit;
			text-decoration: none;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
	#navigation-drawer.theme--dark .powered-by {
		color: white
	}
}
</style>