<template>
	<div>
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="items"
			:loading="loading"
			:items-per-page="15"
			dense
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="35"
					:width="35"
					lazy-src="@/assets/blurred.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<tooltip :text="$t('tooltip.copy-url')">
					<v-btn
						color="blue darken-2 white--text"
						small
						class="px-1 flex-shrink-1 ms-1"
						min-width="20px"
						@click="copyToClipboard(`${previewUrl}/${item.slug}?mktr=${$route.params.id}`)"
					><v-icon>mdi-link-variant</v-icon></v-btn>
				</tooltip>
			</template>
		</v-data-table>

		<!-- footer -->
		<v-footer app inset color="white" class="mx-4 mb-2 py-2 rounded-medium" elevation="4">
			<v-row dense class="text-subtitle-2 text-center justify-space-around ma-0">
				<v-col sm="3" cols="12">
					<h2 class="mb-0">Nehad Hamed</h2>
				</v-col>
				<!-- points-balance -->
				<v-col sm="3" cols="12">
					{{$t('AffiliateMarketers.total-views')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{totalViews || '-'}}
					</span>
				</v-col>
				
				<!-- expire-in -->
				<v-col sm="3" cols="12">
					{{$t('AffiliateMarketers.total-purchases')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{totalPurchases || '-'}}
					</span>
				</v-col>
				
				<!-- total-earned-points -->
				<v-col sm="3" cols="12">
					{{$t('AffiliateMarketers.total-commission')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{totalCommission || '-'}}
					</span>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>

<script>
import { useOrderingItems } from '@/configs/global.config';
import { mapState } from 'vuex'
import { product as productPreview } from '@/configs/preview.config'
import { copyToClipboard } from '@/helpers/functions';

export default {
	name: 'AffiliateMarketer',

	data() {
		return {
			loading: false,
			previewUrl: productPreview,

			// statistics
			totalViews: 120,
			totalPurchases: 140,
			totalCommission: 1100,
		}
	},

	computed: {
		...mapState({
			products: state => state.products.products
		}),

		headers() {
			const headers = [
				{
					text: this.$t('headers.image'),
					value: 'image',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('headers.name'),
					value: 'name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
				},
				{
					text: this.$t('headers.views'),
					value: 'views',
					align: 'center',
					width: 80,
				},
				{
					text: this.$t('headers.purchases'),
					value: 'purchases',
					align: 'center',
					width: 120,
				},
				{
					text: this.$t('headers.total-commission'),
					value: 'totalCommission',
					align: 'center',
					width: 160,
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					sortable: false,
				},
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers;
		},

		items() {
			return this.products.map(c => ({
				...c,
				views: Math.round(Math.random() * 25),
				purchases: Math.round(Math.random() * 15),
				totalCommission: Math.round(Math.random() * 15) * 10
			}))
		}
	},

	methods: {
		copyToClipboard
	},

	created() {
		this.loading = true;
		Promise.all([
			!this.products.length ? this.$store.dispatch('products/fetchAll', { useOrder: useOrderingItems }) : null
		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style>

</style>