export function customStyles(
	editor,
	name = 'custom_style',
	options = { icon: 'brush-variant', tooltip: 'custom styles' }
) {
	editor.ui.registry.addIcon('brush-variant', '<i class="mdi mdi-brush-variant" style="font-size: 24px"></i>');
	editor.ui.registry.addToggleButton(name, {
		icon: options.icon,
		tooltip: options.tooltip,
		onSetup: (buttonApi) => {
			const editorEventCallback = (eventApi) => {
				const {
					borderWidth,
					borderTopWidth,
					borderRightWidth,
					borderBottomWidth,
					borderLeftWidth,
					borderStyle,
					borderTopStyle,
					borderRightStyle,
					borderBottomStyle,
					borderLeftStyle,
					borderColor,
					borderRadius,
					marginTop,
					marginRight,
					marginBottom,
					marginLeft,
					paddingTop,
					paddingRight,
					paddingBottom,
					paddingLeft,
					float,
				} = eventApi.element.style;
				buttonApi.setActive(Boolean(
					// border
					borderWidth || borderTopWidth || borderRightWidth || borderBottomWidth || borderLeftWidth ||
					borderTopStyle || borderRightStyle || borderBottomStyle || borderLeftStyle ||
					borderColor || borderStyle || borderRadius ||
					// margin
					marginTop || marginRight || marginBottom || marginLeft ||
					// padding
					paddingTop || paddingRight || paddingBottom || paddingLeft ||
					// float
					float
				))
			}
			editor.on('NodeChange', editorEventCallback);
	
			/* onSetup should always return the unbind handlers */
			return () => editor.off('NodeChange', editorEventCallback);
		},
		onAction: () => {
			const selectedEl = editor.selection.getNode();

			const {
				borderWidth,
				borderTopWidth,
				borderRightWidth,
				borderBottomWidth,
				borderLeftWidth,
				borderStyle,
				borderTopStyle,
				borderRightStyle,
				borderBottomStyle,
				borderLeftStyle,
				borderColor,
				borderRadius,
				marginTop,
				marginRight,
				marginBottom,
				marginLeft,
				paddingTop,
				paddingRight,
				paddingBottom,
				paddingLeft,
				float,
			} = selectedEl.style;
			
			// Open a Dialog
			editor.windowManager.open({
				title: 'Add/Edit custom style',
				initialData: {
					borderWidth:
						(borderWidth || borderTopWidth || borderRightWidth || borderBottomWidth || borderLeftWidth).slice(0, -2),
					borderSide:
						(borderWidth && 'all') ||
						// get permutations for matched value
						(() => {
							const arr = [
								{ textValue: 'top', value: borderTopWidth },
								{ textValue: 'right', value: borderRightWidth },
								{ textValue: 'bottom', value: borderBottomWidth },
								{ textValue: 'left', value: borderLeftWidth },
							];
							for (let i = 0; i < arr.length; i++) {
								const eli = arr[i];
								for (let j = 0; j < arr.length; j++) {
									const elj = arr[j];
									if (i !== j && eli.value && elj.value) {
										return eli.textValue + '-' + elj.textValue
									}
								}
							}
							return false
						})() ||
						// simple border styles
						(borderTopWidth && 'top') ||
						(borderRightWidth && 'right') ||
						(borderBottomWidth && 'bottom') ||
						(borderLeftWidth && 'left') ||
						'',
					borderStyle: borderStyle || borderTopStyle || borderRightStyle || borderBottomStyle || borderLeftStyle,
					borderColor: borderColor,
					borderRadius: borderRadius.slice(0, -2),
					marginTop: marginTop.slice(0, -2),
					marginRight: marginRight.slice(0, -2),
					marginBottom: marginBottom.slice(0, -2),
					marginLeft: marginLeft.slice(0, -2),
					paddingTop: paddingTop.slice(0, -2),
					paddingRight: paddingRight.slice(0, -2),
					paddingBottom: paddingBottom.slice(0, -2),
					paddingLeft: paddingLeft.slice(0, -2),
					float: float,
				},
				body: {
					type: 'tabpanel',
					tabs: [
						// border
						{
							name: 'border',
							title: 'Border',
							items: [
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'borderWidth',
											label: 'Width',
											placeholder: 'number',
											flex: true
										},
										{
											type: 'colorinput',
											inputMode: 'numeric',
											name: 'borderColor',
											label: 'Color',
											flex: true
										},
									]
								},
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'listbox',
											name: 'borderStyle',
											label: 'Style',
											items: [
												{ text: 'None', value: '' },
												{ text: 'Solid', value: 'solid' },
												{ text: 'Dashed', value: 'dashed' },
												{ text: 'Dotted', value: 'dotted' },
												{ text: 'Double', value: 'double' },
												{ text: 'Groove', value: 'groove' },
												{ text: 'Ridge', value: 'ridge' }
											],
											flex: true
										},
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'borderRadius',
											label: 'Radius',
											placeholder: 'number',
											flex: true
										},
									]
								},
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'listbox',
											name: 'borderSide',
											label: 'Border side',
											items: [
												{ text: 'None', value: '' },
												{ text: 'All', value: 'all' },
												{ text: 'Top', value: 'top' },
												{ text: 'Right', value: 'right' },
												{ text: 'Bottom', value: 'bottom' },
												{ text: 'Left', value: 'left' },

												// building permutations for border style
												...(() => {
													const options = [];
													[
														{ text: 'Top', value: 'top' },
														{ text: 'Right', value: 'right' },
														{ text: 'Bottom', value: 'bottom' },
														{ text: 'Left', value: 'left' },
													].forEach((c, i, arr) => {
														for (let j = 0; j < arr.length; j++) {
															const el = arr[j];
															if (i !== j) {
																options.push({
																	text: c.text + '-' + el.text,
																	value: c.value + '-' + el.value
																})
															}
														}
													});
													return options;
												})()
											],
											flex: true
										}
									]
								}
							]
						},

						// margins
						{
							name: 'margins',
							title: 'Margins',
							items: [
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'marginTop',
											label: 'Top',
											placeholder: 'number',
											flex: true
										},
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'marginRight',
											label: 'Right',
											placeholder: 'number',
											flex: true
										},
									]
								},
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'marginBottom',
											label: 'Bottom',
											placeholder: 'number',
											flex: true
										},
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'marginLeft',
											label: 'Left',
											placeholder: 'number',
											flex: true
										},
									]
								}
							]
						},

						// paddings
						{
							name: 'paddings',
							title: 'Paddings',
							items: [
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'paddingTop',
											label: 'Top',
											placeholder: 'number',
											flex: true
										},
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'paddingRight',
											label: 'Right',
											placeholder: 'number',
											flex: true
										},
									]
								},
								{
									type: 'grid',
									columns: 2,
									items: [
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'paddingBottom',
											label: 'Bottom',
											placeholder: 'number',
											flex: true
										},
										{
											type: 'input',
											inputMode: 'numeric',
											name: 'paddingLeft',
											label: 'Left',
											placeholder: 'number',
											flex: true
										},
									]
								}
							]
						},

						// float
						{
							name: 'float',
							title: 'Float',
							items: [
								{
									type: 'listbox',
									name: 'float',
									label: 'Float',
									items: [
										{ text: 'None', value: '' },
										{ text: 'Left', value: 'left' },
										{ text: 'Right', value: 'right' },
										{ text: 'Inline-start', value: 'inline-start' },
										{ text: 'Inline-end', value: 'inline-end' },
									],
									flex: true
								},
							]
						},
					]
				},
				buttons: [
					{
						text: 'Close',
						type: 'cancel',
						onclick: 'close'
					},
					{
						text: 'Reset',
						type: 'custom'
					},
					{
						text: 'Apply',
						type: 'submit',
						primary: true,
						enabled: false
					}
				],
				onAction: (api) => {
					Object.assign(selectedEl.style, {
						borderWidth: null,
						borderStyle: null,
						borderTopWidth: null,
						borderTopStyle: null,
						borderRightWidth: null,
						borderRightStyle: null,
						borderBottomWidth: null,
						borderBottomStyle: null,
						borderLeftWidth: null,
						borderLeftStyle: null,
						borderColor: null,
						borderRadius: null,
						marginTop: null,
						marginRight: null,
						marginBottom: null,
						marginLeft: null,
						paddingTop: null,
						paddingRight: null,
						paddingBottom: null,
						paddingLeft: null,
						float: null,
					})
					api.close();
				},
				onSubmit: function (api) {
					const selectedEl = window.tinyMCE.activeEditor.selection.getNode();
					const submittedData = api.getData();
					const getBorderStyles = () => {
						const getBorderOptions = (position) => {
							switch (position) {
							case 'all': return {
								borderWidth: submittedData.borderWidth + 'px',
								borderStyle: submittedData.borderStyle
							};
							case 'top': return {
								borderTopWidth: submittedData.borderWidth + 'px',
								borderTopStyle: submittedData.borderStyle
							};
							case 'right': return {
								borderRightWidth: submittedData.borderWidth + 'px',
								borderRightStyle: submittedData.borderStyle
							};
							case 'bottom': return {
								borderBottomWidth: submittedData.borderWidth + 'px',
								borderBottomStyle: submittedData.borderStyle
							};
							case 'left': return {
								borderLeftWidth: submittedData.borderWidth + 'px',
								borderLeftStyle: submittedData.borderStyle
							};
							}
						}

						let temp = {};
						submittedData.borderSide.split('-').forEach(position => {
							temp = { ...temp, ...getBorderOptions(position) }
						});

						return {
							borderWidth: null,
							borderStyle: null,
							borderTopWidth: null,
							borderTopStyle: null,
							borderRightWidth: null,
							borderRightStyle: null,
							borderBottomWidth: null,
							borderBottomStyle: null,
							borderLeftWidth: null,
							borderLeftStyle: null,
							...temp
						}
					}
					const borderStyles = getBorderStyles();
					const styles = {
						...(
							!submittedData.borderSide ||
							submittedData.borderSide !== 'all'
								? borderStyles
								: {
									borderWidth: borderStyles.borderWidth,
									borderStyle: borderStyles.borderStyle
								}
						),
						borderColor: submittedData.borderColor,
						borderRadius: submittedData.borderRadius + 'px',
						marginTop: submittedData.marginTop + 'px',
						marginRight: submittedData.marginRight + 'px',
						marginBottom: submittedData.marginBottom + 'px',
						marginLeft: submittedData.marginLeft + 'px',
						paddingTop: submittedData.paddingTop + 'px',
						paddingRight: submittedData.paddingRight + 'px',
						paddingBottom: submittedData.paddingBottom + 'px',
						paddingLeft: submittedData.paddingLeft + 'px',
						float: submittedData.float,
					}
					Object.assign(selectedEl.style, styles);
					api.close();
				},
			});
		}
	})
}