import { products, news, services, albums, aboutUs } from '@/configs/translates.config'

export default {
	'home-page': 'Home page',
	'products-page': `${products.en.plural} page`,
	'news-page': `${news.en.plural} page`,
	'services-page': `${services.en.plural} page`,
	'albums-page': `${albums.en.plural} page`,
	'about-us-page': `${aboutUs.en.plural} page`,
	'seo-fields': 'SEO fields',
}