<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="offers"
			:ids="deleteItemId"
			@on-delete="fetchOffers"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Offers.the-offer')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="offers"
			:ids="restoreItemId"
			@on-restore="fetchOffers"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Offers.the-offer')}}
		</bee-restore-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('are-you-sure-you-want-to') + ' ' + titleState + ' ' + $t('Offers.the-offer')}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- add/edit dialog -->
		<v-dialog v-model="dialog" width="600" persistent>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Offers.offer')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row dense align="center" class="mt-0">
							<!-- name -->
							<v-col sm="4" cols="12">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- from date -->
							<v-col sm="4" cols="6">
								<v-menu
									v-model="fromDateMenu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="fromDate"
											:label="$t('inputs.from-date')"
											prepend-inner-icon="mdi-calendar"
											outlined
											dense
											class="rounded-small"
											hide-details
											readonly
											clearable
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="fromDate"
										@input="fromDateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>

							<!-- to date -->
							<v-col sm="4" cols="6">
								<v-menu
									v-model="toDateMenu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="toDate"
											:label="$t('inputs.to-date')"
											prepend-inner-icon="mdi-calendar"
											outlined
											dense
											class="rounded-small"
											hide-details
											readonly
											clearable
											:rules="fromDate ? [() => moment(toDate).isSameOrAfter(fromDate)] : []"
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="toDate"
										@input="toDateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>

							<!-- offer products -->
							<v-col cols="12">
								<v-autocomplete
									v-model="offerProductsIds"
									:label="$t('inputs.offer-products')"
									:items="products"
									item-value="id"
									item-text="name"
									chips
									small-chips
									deletable-chips
									multiple
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.required"
								></v-autocomplete>
							</v-col>

							<!-- discount value -->
							<v-col cols="12" class="d-flex flex-wrap algin-center">
								<v-radio-group
									v-model="discountType"
									row
									dense
									hide-details
									class="ma-0 pt-2"
								>
									<template v-slot:label>
										<span class="text-body-1">{{$t('inputs.discount')}}</span>
									</template>
									<v-radio :label="$t('inputs.percent')" :value="0"></v-radio>
									<v-radio :label="$t('inputs.fixed-value')" :value="1"></v-radio>
								</v-radio-group>

								<v-text-field
									v-model.number="discount"
									:label="$t('inputs.discount')"
									type="number"
									hide-spin-buttons
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.requiredAsNumber"
									:append-icon="discountType === 0 ? 'mdi-percent' : null"
								></v-text-field>
							</v-col>

							<!-- apply on up-selling -->
							<v-col v-if="useUpSellingForm" cols="auto">
								<v-switch
									v-model="applyOnUpSelling"
									:label="$t('inputs.apply-on-up-selling')"
									hide-details
									class="mt-0 pt-0"
								/>
							</v-col>

							<!-- active -->
							<v-col cols="auto">
								<v-switch
									v-model="isActive"
									:label="$t('inputs.is-active')"
									hide-details
									class="mt-0 pt-0"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							:loading="dialogLoading"
							:disabled="dialogLoading"
							class="rounded-small"
						>{{$t('save')}}</v-btn>
						<v-btn
							:disabled="dialogLoading"
							class="rounded-small"
							@click="dialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Offers.add-new-offer')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredOffers"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.offersHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- products -->
			<template v-slot:item.products="{ item }">
				{{item.products.map(c => c.name).join(', ') | isAvailable}}
			</template>
			
			<!-- fromDate -->
			<template v-slot:item.fromDate="{ item }">
				{{(item.fromDate ? moment(item.fromDate).format('YYYY-MM-DD') : null) | isAvailable}}
			</template>
			
			<!-- toDate -->
			<template v-slot:item.toDate="{ item }">
				{{(item.toDate ? moment(item.toDate).format('YYYY-MM-DD') : null) | isAvailable}}
			</template>
			
			<!-- discount -->
			<template v-slot:item.discount="{ item }">
				{{item.percentValue ? item.percentValue + '%' : item.fixedValue }}
			</template>

			<!-- with up-selling -->
			<template v-slot:item.withUpSelling="{ item }">
				<v-icon v-if="item.withUpSelling">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<template v-if="!item.deletedAt">
						<tooltip :text="$t('tooltip.edit')">
							<v-btn
								color="blue darken-4"
								dark
								small
								class="px-1 flex-shrink-1"
								min-width="20px"
								@click="dialog = true; editItemId = item.id"
							><v-icon>mdi-pencil</v-icon></v-btn>
						</tooltip>
						
						<tooltip :text="$t('tooltip.delete')">
							<v-btn
								color="red darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="deleteDialog = true; deleteItemId = item.id"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</tooltip>
					
						<tooltip :text="$t('tooltip.active-state')">
							<v-switch
								v-model="item.isActive"
								:ripple="false"
								color="darkGreen"
								hide-details
								class="ms-2 mt-0 pt-0"
								readonly
								@click="activeConfirmDialog(item)"
							></v-switch>
						</tooltip>
					</template>
					
					<tooltip v-else :text="$t('tooltip.restore')">
						<v-btn
							color="orange darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="restoreItemId = item.id; restoreDialog = true"
						>
							<v-icon>mdi-restore</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs, offers } from '@/configs/translates.config';
import rules from '@/helpers/validation rules'
import moment from 'moment';
import langsDraft from '@/mixins/langs-draft';
import { useUpSellingForm } from '@/configs/global.config';

export default {
	name: 'Offers',
	mixins: [langsDraft],

	data: () => ({
		dialog: false,
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		confirmDialog: false,
		confirmId: null,
		changeStateLoading: false,
		titleState: null,
		
		fromDateMenu: null,
		toDateMenu: null,
		discountType: 0,

		// filter
		search: null,
		isFiltered: false,

		// configs
		multiLangInputs,
		useUpSellingForm,

		// helpers
		loading: null,
		dialogLoading: null,
		lang: defaultLang,
		rules,
		moment,

		// draft for languages 
		draft: {},
		draftNames: ['name'],

		// data
		name: null,
		offerProductsIds: [],
		fromDate: null,
		toDate: null,
		discount: null,
		applyOnUpSelling: false,
		isActive: true
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchOffer();
					} else {
						const item = this.getOfferById(this.editItemId);
						this.name = item.name;
						this.offerProductsIds = item.offerProductsIds;
						this.fromDate = item.fromDate ? moment(item.fromDate).format('YYYY-MM-DD') : null;
						this.toDate = item.toDate ? moment(item.toDate).format('YYYY-MM-DD') : null;
						this.discountType = item.fixedValue ? 1 : 0;
						this.discount = item.percentValue || item.fixedValue;
						this.applyOnUpSelling = item.withUpSelling;
						this.isActive = item.isActive;
					}
				} else {
					this.discountType = 0;
					this.isActive = true;
				}
			} else {
				this.$refs.form.reset();
				this.resetDraft();
				this.editItemId = null;
				this.lang = defaultLang;
			}
		}
	},

	computed: {
		...mapState({
			products: state => state.products.products,
			offers: state => state.offers.offers,
		}),

		...mapGetters({
			getOfferById: 'offers/getOfferById',
		}),

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.name'),
					value: 'name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true,
				},
				{
					text: this.$t('headers.products'),
					value: 'products',
					align: 'center',
					sortable: false,
				},
				{
					text: this.$t('headers.from-date'),
					value: 'fromDate',
					align: 'center',
				},
				{
					text: this.$t('headers.to-date'),
					value: 'toDate',
					align: 'center',
				},
				{
					text: this.$t('headers.discount'),
					value: 'discount',
					align: 'center',
					sortable: false,
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					sortable: false,
					noRemove: true,
					fixed: true,
					width: 140
				},
			];

			if (useUpSellingForm) {
				headers.splice(headers.length - 1, 0, {
					text: this.$t('headers.apply-on-up-selling'),
					value: 'withUpSelling',
					align: 'center',
					width: 170
				})
			}

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.offersHeaders.length) {
				headers = this.$eStorage.l.offersHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredOffers() {
			return this.filterByName(this.isFiltered, this.offers, this.search);
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.name[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			if (this.$refs.form.validate()) {
				this.dialogLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ name, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';

					return this.$store.dispatch(`offers/${actionName}`, {
						id: id,
						name,
						fromDate: this.fromDate,
						toDate: this.toDate,
						productIds: this.offerProductsIds,
						percentValue: this.discountType === 0 ? this.discount : null,
						fixedValue: this.discountType === 1 ? this.discount : null,
						withUpSelling: this.applyOnUpSelling,
						isActive: this.isActive,
						acceptLanguage: lang
					}).then((data) => data)
				}

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];

					const dataDueLang = { name, lang };

					if (name) {
						if (!itemData) {
							await submitRequest(!itemData, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				const successHandler = () => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Offers.offer') })
							: this.$t('$-is-created-successfully', { name: this.$t('Offers.offer') });
					this.$eventBus.$emit('show-snackbar', false, message);
					
					this.fetchOffers();
					this.dialog = false;
				};
				const errorHandler = () => {
					if (itemData) {
						this.editItemId = itemData.id;
					}
				}

				Promise.all(promises).then(successHandler).catch(errorHandler).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		fetchOffers(isFirstLoad) {
			this.loading = true;
			return this.$store.dispatch('offers/fetchAll').finally(() => {
				if (!isFirstLoad) this.loading = false;
			})
		},

		fetchOffer() {
			this.dialogLoading = true;
			const slug = this.getOfferById(this.editItemId).slug;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('offers/fetchBySlug', { slug, acceptLanguage: c.key })
						.then((data) => {
							if (index === 0) {
								this.offerProductsIds = data.products.map(c => c.id);
								this.fromDate = data.fromDate ? moment(data.fromDate).format('YYYY-MM-DD') : null;
								this.toDate = data.toDate ? moment(data.toDate).format('YYYY-MM-DD') : null;
								this.discountType = data.fixedValue ? 1 : 0;
								this.discount = data.percentValue || data.fixedValue;
								this.applyOnUpSelling = data.withUpSelling;
								this.isActive = data.isActive;
							}
							this.draft.name[c.key] = data.name;
						})
				);
			});
			
			Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang]
			}).finally(() => {
				this.dialogLoading = false;
			});
		},

		// dialogs
		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.isActive ? this.$t('deactivate') : this.$t('activate');
			this.confirmDialog = true;
		},
		changeState() {
			this.changeStateLoading = true;
			this.$store.dispatch('offers/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('$-is-updated-successfully', { name: offers.en.singular }));
				this.fetchOffers();
			}).finally(() => {
				this.changeStateLoading = false;
			})
		}
	},

	created() {
		this.loading = true;
		Promise.all([
			this.fetchOffers(),
			!this.products.length ? this.$store.dispatch('products/fetchAll', {}) : null,
		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style>

</style>