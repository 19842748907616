<template>
	<div>
		<!-- mode dialog -->
		<v-dialog v-model="modeDialog" content-class="rounded-large" max-width="300">
			<v-card>
				<v-card-title class="font-weight-bold text-uppercase py-2">
					<v-spacer/>
					{{$t('template-builder.builder-mode')}}
					<v-spacer/>

					<v-btn icon class="ms-n12" @click="$emit('close')">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="px-2 pb-2">
					<v-btn
						height="50"
						width="100%"
						color="success"
						class="rounded-large mb-2"
						@click="isSimple = true; modeDialog = false"
					>
						<v-icon class="me-2">mdi-drag-variant</v-icon>
						{{$t('template-builder.simple-mode')}}
					</v-btn>

					<v-btn
						height="50"
						width="100%"
						color="primary"
						class="rounded-large"
						@click="isSimple = false; modeDialog = false"
					>
						<v-icon class="me-2">mdi-auto-fix</v-icon>
						{{$t('template-builder.expert-mode')}}
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- template builder -->
		<template v-if="!modeDialog">
			<TemplateBuilder
				ref="templateBuilder"
				:simple="isSimple"
				use-custom-file-manager
				:init-value="initValue"
				:height="($vuetify.breakpoint.height - 45) + 'px'"
				panel-width="15%"
			/>
			
			<!-- file-manger dialog + save value + active simple and complex -->
			<v-row dense align="center" justify="end" class="ma-0">
				<v-col cols="12" class="d-flex justify-space-around text-center">
					<!-- close -->
					<tooltip :text="$t('cancel')">
						<v-btn
							color="red lighten-1 white--text"
							width="50"
							min-width="0"
							class="rounded-medium me-1"
							@click="$emit('close')"
						>
							<v-icon>mdi-close-circle-outline</v-icon>
						</v-btn>
					</tooltip>

					<!-- save -->
					<v-btn
						color="primary"
						class="rounded-medium flex-grow-1"
						@click="saveTemplate()"
					>
						{{$t('save')}}
					</v-btn>
				</v-col>
			</v-row>
		</template>
	</div>
</template>

<script>
import TemplateBuilder from './index.vue'
export default {
	name: 'VTemplateBuilder',
	props: {
		initValue: { type: String, default: '' },
		simple: { type: Boolean, default: false },
		useCustomFileManager: { type: Boolean, default: true },
		panelWidth: { type: String, default: '15%' },
	},

	components: {
		TemplateBuilder
	},

	data() {
		return {
			modeDialog: !this.simple,
			isSimple: this.simple || false,
		}
	},

	methods: {
		importCode(val) { this.$refs.templateBuilder.importCode(val) },
		getCodes() { return this.$refs.templateBuilder.getCodes() },
		getHtmlCss(code) { return this.$refs.templateBuilder.getHtmlCss(code) },
		closeFileManager() { this.$refs.templateBuilder.closeAssetManager() },

		saveTemplate() {
			const codes = this.getCodes();
			this.$emit('save', codes, this.getHtmlCss(codes));
		}
	}
}
</script>

<style>

</style>