import { langs, multiLangInputs } from '@/configs/translates.config';
import i18n from '@/i18n/i18n';
import { $eventBus } from '@/plugins/midad';

export function headerAcceptLanguage(acceptLanguage) {
	let configs;
	if (multiLangInputs) {
		configs = { headers: { 'accept-language': acceptLanguage } }
	}
	return configs;
}

export function setDataOnDrag(event, index) {
	event.dataTransfer.setData('oldIndex', index);
	const dragImage = new Image();
	event.dataTransfer.setDragImage(dragImage, 0, 0)
}

export function getDataOnDrop(event, newIndex, array) {
	const oldIndex = +event.dataTransfer.getData('oldIndex');
	if (newIndex !== oldIndex) {
		const oldItem = array[oldIndex];
		array.splice(oldIndex, 1);
		array.splice(newIndex, 0, oldItem);
	}
}

export function copyToClipboard(str) {
	navigator.clipboard.writeText(str);
	$eventBus.$emit('show-snackbar', false, i18n.t('messages.copied'));
}

export function createObjectFromLangs(inits = {}) {
	return langs.map(c => c.key).reduce((obj, key) => ({ ...obj, [key]: inits[key] || null }), {});
}

export function randomString(charLen, numLen, fromTimeStamp = false) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	const numbers = '0123456789';

	for (let index = 0; index < charLen; index++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	if (!fromTimeStamp) {
		for (let index = 0; index < numLen; index++) {
			result += numbers.charAt(Math.floor(Math.random() * numbers.length));
		}
	} else {
		result += Date.now().toString().slice(-numLen);
	}
	return result;
}

export function fixNumber(number, digits = 3) {
	return number ? +number.toFixed(digits) : 0;
}

export function getLoyaltyPointsValue(val) { return fixNumber(val * 1); }