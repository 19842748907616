import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		plans: []
	},

	actions: {
		fetchAll({ commit }, { useOrder }) {
			return axios.get('/plans', {
				params: { useOrder }
			}).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.plans ? !c.deletedAt : true);
				commit(types.STORE_PLANS, storeData);
				return storeData;
			})
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/plans/${slug}`, configs).then(({ data }) => {
				return data;
			})
		},

		create({ commit }, { name, description, duration, price, offerStart, offerEnd, offerPrice, isFeatured, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/plans', {
				name,
				description,
				duration,
				price,
				offerStart,
				offerEnd,
				offerPrice,
				isFeatured
			}, configs).then(({ data }) => data)
		},

		update({ commit }, { id, name, description, duration, price, offerStart, offerEnd, offerPrice, isFeatured, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post(`/plans/${id}`, {
				name,
				description,
				duration,
				price,
				offerStart,
				offerEnd,
				offerPrice,
				isFeatured,
				_method: 'PUT'
			}, configs).then(({ data }) => data)
		},

		changeState({ commit }, { id }) {
			return axios.post(`/plans/${id}/change-status`, { _method: 'PUT' });
		},

		changeOrder({ commit }, { id, order }) {
			return axios.post(`/plans/${id}/change-order`, { order, _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/plans/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/plans/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_PLANS](state, plans) {
			state.plans = plans;
		}
	},

	getters: {
		getPlanById: state => id => state.plans.find(c => id === c.id)
	}
}