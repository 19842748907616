<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="sets"
			:ids="deleteItemId"
			@on-delete="fetchSets"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Sets.the-set')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="sets"
			:ids="restoreItemId"
			@on-restore="fetchSets"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Sets.the-set')}}
		</bee-restore-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			:width="useFeatured.sets ? 460 : 400"
			:persistent="dialogLoading"
		>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Sets.set')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row class="mt-0">
							<!-- name -->
							<v-col :cols="useFeatured.sets ? 8 : 12" class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- is-featured -->
							<v-col v-if="useFeatured.sets" cols="auto" class="py-0">
								<v-switch
									v-model="isFeatured"
									:label="$t('inputs.is-featured')"
									hide-details
									class="mt-1"
								></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							:loading="dialogLoading"
							:disabled="dialogLoading"
							class="rounded-small"
						>{{$t('save')}}</v-btn>
						<v-btn
							:disabled="dialogLoading"
							class="rounded-small"
							@click="dialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Sets.add-new-set')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredSets"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="px-1 flex-shrink-1"
							min-width="20px"
							@click="dialog = true; editItemId = item.id"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>
					
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="deleteDialog = true; deleteItemId = item.id"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</template>
				<tooltip v-else :text="$t('tooltip.restore')">
					<v-btn
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreDialog = true; restoreItemId = item.id"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</tooltip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import BeeRestoreDialog from '@/components/BeeRestoreDialog.vue';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { useFeatured } from '@/configs/global.config';
import langsDraft from '@/mixins/langs-draft';

export default {
	name: 'Sets',
	mixins: [langsDraft],
	components: { BeeRestoreDialog },

	data: () => ({
		dialog: false,
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		// filter
		search: null,
		isFiltered: false,

		// configs
		multiLangInputs,
		useFeatured,

		// helpers
		loading: null,
		dialogLoading: null,
		lang: defaultLang,
		rules,

		// draft for languages 
		draft: {},
		draftNames: ['name'],

		// data
		name: null,
		isFeatured: false,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchSet();
					} else {
						const item = this.getSetById(this.editItemId);
						this.name = item.name;
						this.isFeatured = item.isFeatured;
					}
				}
			} else {
				this.$refs.form.reset();
				this.resetDraft();
				this.editItemId = null;
				this.lang = defaultLang;
			}
		}
	},

	computed: {
		...mapState({
			sets: state => state.sets.sets
		}),

		...mapGetters({
			getSetById: 'sets/getSetById'
		}),

		headers() {
			return [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filteredSets() {
			return this.filterByName(this.isFiltered, this.sets, this.search);
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.name[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			if (this.$refs.form.validate()) {
				this.dialogLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					{ name, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`sets/${actionName}`, {
						id: id,
						name: name,
						isFeatured: this.isFeatured,
						acceptLanguage: lang
					})
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;
					const name = this.draft.name[lang];
					const dataDueLang = { name, lang };

					if (name) {
						if (!itemData) {
							await submitRequest(dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises)
					.then(() => {
						const message =
							this.editItemId
								? this.$t('$-is-updated-successfully', { name: this.$t('Sets.set') })
								: this.$t('$-is-created-successfully', { name: this.$t('Sets.set') });
						this.$eventBus.$emit('show-snackbar', false, message);

						this.fetchSets();
						this.dialog = false;
					})
					.catch(() => {
						this.editItemId = itemData.id;
					})
					.finally(() => {
						this.dialogLoading = false;
					})
			}
		},

		fetchSets() {
			this.loading = true;
			this.$store.dispatch('sets/fetchAll').finally(() => {
				this.loading = false;
			})
		},

		fetchSet() {
			this.dialogLoading = true;

			const slug = this.getSetById(this.editItemId).slug;
			
			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('sets/fetchBySlug', { slug, acceptLanguage: c.key })
						.then((data) => {
							if (index === 0) this.isFeatured = data.isFeatured;
							this.draft.name[c.key] = data.name;
						})
				)
			});
			
			Promise.all(promises).finally(() => {
				this.name = this.draft.name[this.lang];
				this.dialogLoading = false;
			})
		}
	},

	created() {
		this.fetchSets();
	}
}
</script>

<style>

</style>