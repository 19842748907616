import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		files: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/file-manager').then(({ data }) => {
				commit(types.STORE_FILE_MANGER_FILES, data);
				return data;
			});
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/file-manager/${id}`).then(({ data }) => {
				return data;
			})
		},

		uploadImage({ commit }, { image, progressHandler }) {
			const configs = {
				onUploadProgress: (progressEvent) => progressHandler(progressEvent),
			}

			const formData = new FormData();
			formData.append('image', image);

			return axios.post('/file-manager', formData, configs)
		},

		uploadFile({ commit }, { file, progressHandler }) {
			const configs = {
				onUploadProgress: (progressEvent) => progressHandler(progressEvent),
			}

			const formData = new FormData();
			formData.append('file', file);

			return axios.post('/file-manager/file', formData, configs)
		},

		delete({ commit }, { id }) {
			return axios.post(`/file-manager/${id}`, { _method: 'DELETE' })
		}
	},

	mutations: {
		[types.STORE_FILE_MANGER_FILES](state, files) {
			state.files = files;
		}
	},

	getters: {
		getFileById: state => id => state.files.find(c => c.id === id)
	}
}