<template>
	<v-dialog
		v-model="localValue"
		content-class="headers-control-dialog rounded-medium"
		max-width="500"
	>
		<v-card class="rounded-medium">
			<v-card-title class="justify-center text-uppercase text-h6 pb-0">
				{{$t('components.headers-manager.title')}}
			</v-card-title>
			<v-card-text class="pb-0">
				<!-- chips -->
				<v-chip-group column>
					<v-chip
						v-for="(header, index) in localShownHeaders"
						:key="index"
						:close="!header.noRemove"
						:draggable="!header.fixed"
						:color="!header.fixed ? 'primary lighten-1' : ''"
						:class="[
							'chip-border',
							{ 'chip-border--active': dragItemIndex === index && !header.fixed }
						]"
						@dragstart="setDataOnDrag($event, index)"
						@dragenter="dragItemIndex = index"
						@dragend="dragItemIndex = null"
						@dragover.prevent
						@drop="!header.fixed ? getDataOnDrop($event, index, localShownHeaders) : null"
						@click:close="localShownHeaders.splice(index, 1)"
					>
						{{header.text}}
						<v-icon v-if="header.fixed" size="18" class="ms-1">mdi-lock</v-icon>
					</v-chip>
				</v-chip-group>

				<!-- headers search -->
				<v-row v-if="availableHeaders.length">
					<v-col sm="6" cols="12">
						<v-autocomplete
							v-model="headerSearch"
							:label="$t('components.headers-manager.placeholder')"
							:items="availableHeaders"
							item-text="text"
							hide-no-data
							return-object
							outlined
							dense
							hide-details
							class="mt-2 rounded-medium"
							@change="
								localShownHeaders.splice(localShownHeaders.length - 1, 0, headerSearch);
								headerSearch = null
							"
						></v-autocomplete>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn
					type="submit"
					color="primary"
					class="rounded-small"
					@click="save"
				>{{$t('save')}}</v-btn>

				<v-btn @click="localValue = false" class="rounded-small">
					{{$t('cancel')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { getDataOnDrop, setDataOnDrag } from '@/helpers/functions';
export default {
	name: 'VHeadersControlDialog',

	props: {
		headers: { type: Array, default: () => [] },
		shownHeaders: { type: Array, default: () => [] },
		value: { Type: Boolean, default: false }
	},

	data() {
		return {
			headerSearch: null,
			dragItemIndex: null,
			localShownHeaders: [],
		}
	},

	watch: {
		value(val) {
			if (val) {
				this.localShownHeaders = [...this.shownHeaders];
			} else {
				this.localShownHeaders = [];
				this.headerSearch = null;
			}
		}
	},

	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(newVal) {
				this.$emit('input', newVal);
			}
		},

		availableHeaders() {
			return this.headers.filter(c => this.localShownHeaders.findIndex(a => a.text === c.text) === -1);
		}
	},

	methods: {
		save() {
			this.$emit('save', this.localShownHeaders);
			this.localValue = false;
		},

		setDataOnDrag,
		getDataOnDrop
	}
}
</script>

<style lang="scss">
.headers-control-dialog {
	.chip-border {
		outline: 1px dashed transparent;
		outline-offset: -3px;
		&--active {
			outline-color: white;
		}
	}
}
</style>