<template>
	<div>
		<v-dialog
			v-model="localValue"
			transition="dialog-transition"
			width="400"
			content-class="rounded-medium"
			ref="dialog"
		>
			<template v-slot:default="dialog">
				<v-card class="rounded-medium">
					<v-toolbar
						color="orange"
						dark
						flat
						height="50"
						class="text-uppercase justify-center"
					>
						<v-spacer />
						Confirm restore
						<v-spacer />
					</v-toolbar>
					<v-card-text class="py-3 px-4">
						<!-- default slot -->
						<slot></slot>
					</v-card-text>
					<v-card-actions class="pt-0 justify-end">
						<v-btn
							:loading="loading"
							@click="restoreData"
							color="orange"
							dark
							class="rounded-small"
							elevation="1"
						>
							{{ $t("restore") }}
						</v-btn>
						<v-btn
							:disabled="loading"
							@click="dialog.value = false"
							elevation="1"
							class="rounded-small"
						>
							{{ $t("close") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'BeeRestoreDialog',
	props: {
		value: { type: Boolean, default: false },
		moduleType: { type: String, required: true },
		ids: { type: [Number, String, Object] },
	},

	data() {
		return {
			loading: false,
		};
	},

	methods: {
		restoreData() {
			this.loading = true;

			let actionData = {};

			if (typeof this.ids === 'number' || typeof this.ids === 'string') {
				actionData = { id: this.ids };
			} else if (this.ids && Object.keys(this.ids).length > 0) {
				actionData = this.ids;
			}

			this.$store
				.dispatch(`${this.moduleType}/restore`, actionData)
				.then(() => {
					this.$eventBus.$emit('show-snackbar', false, this.$t('messages.restored-successfully'));
					this.$emit('on-restore');
					this.localValue = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		localValue: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.$emit('input', newValue);
			},
		},
	},
};
</script>

<style>
</style>